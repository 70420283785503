import { FC, useRef, useState } from 'react';
import axios from '../../../../axios';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/useRedux';
import * as Order from '../../../../store/orders/orders.slice';
import { Badge, Button, Col, Drawer, Form, Input, message, Row, Select, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import * as Icon from '@ant-design/icons';
import { IOrderValueForm } from '../../../../types';
import { IsAccess } from '../../../../components/IsAccess/IsAccess';

const key = 'creatable';

export const OrderCreate: FC = () => {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const inputRef = useRef(null);
    const [form] = Form.useForm();
    const OrderSettings = useAppSelector((state) => state.ordersSettings);
    const isAccess = IsAccess();

    const fetchCreate = async (values: IOrderValueForm) => {
        setLoading(true);
        message.loading({ content: 'Завантаження...', key });
        try {
            const { data } = await axios.post('/api/order', values);

            if (data.success) {
                dispatch(Order.create(data.data));
                message.success({ content: 'Збережено!', key, duration: 2 });
                setLoading(false);
                form.resetFields();
                onClose();
            } else {
                setLoading(false);
                message.error({ content: 'Сталась помилка!', key, duration: 2 });
            }
        } catch (e) {
            setLoading(false);
            message.error({ content: 'Сталась помилка!', key, duration: 2 });
            return;
        }
    };

    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button type="primary" onClick={showDrawer} icon={<Icon.PlusOutlined />} disabled={!isAccess?.orders.create} />
            <Drawer
                width={600}
                title="Нова заявка"
                open={open}
                placement="right"
                onClose={onClose}
                extra={
                    <Button
                        icon={<Icon.SaveOutlined />}
                        loading={isLoading}
                        type="primary"
                        disabled={!isAccess?.orders.create}
                        // @ts-ignore
                        onClick={() => inputRef?.current?.submit()}
                    />
                }>
                <Form
                    initialValues={{ remember: true }}
                    ref={inputRef}
                    form={form}
                    onFinish={fetchCreate}
                    size="middle"
                    layout="vertical"
                    autoComplete="off">
                    <Row gutter={[10, 10]}>
                        {/* FullName */}
                        <Col className="gutter-row" span={24} sm={12}>
                            <Form.Item
                                name="fullName"
                                label="Ім'я"
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message: '',
                                    },
                                    {
                                        type: 'string',
                                        min: 2,
                                        message: '',
                                    },
                                ]}>
                                <Input placeholder="Введіть ім'я" allowClear />
                            </Form.Item>
                        </Col>

                        {/* Phone */}
                        <Col className="gutter-row" span={24} sm={12}>
                            <Form.Item
                                name="phone"
                                label="Телефон"
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message: '',
                                    },
                                    {
                                        pattern: /^(\+38)?\d{10}$/,
                                        message: '',
                                    },
                                ]}>
                                <Input placeholder="Введіть телефон" allowClear />
                            </Form.Item>
                        </Col>

                        {/* service */}
                        <Col className="gutter-row" span={24} sm={12}>
                            <Form.Item name="service" label="Послуга">
                                <Input
                                    placeholder="Введіть текст"
                                    allowClear
                                    suffix={
                                        <Tooltip title='Наприклад: "Запис на замір"'>
                                            <Icon.InfoCircleOutlined style={{ color: 'grey' }} />
                                        </Tooltip>
                                    }
                                />
                            </Form.Item>
                        </Col>

                        {/* address */}
                        <Col className="gutter-row" span={24} sm={12}>
                            <Form.Item name="address" label="Адреса">
                                <Input placeholder="Введіть адресу" allowClear />
                            </Form.Item>
                        </Col>

                        {/* whence */}
                        <Col className="gutter-row" span={24} sm={12}>
                            <Form.Item
                                name="whence"
                                label="Звідки надійшла заявка"
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message: '',
                                    },
                                ]}>
                                <Select placeholder="Звідки надійшла заявка">
                                    <Select.Option value="landing">
                                        <Icon.LaptopOutlined style={{ color: '#FF9800' }} /> Лендінг
                                    </Select.Option>
                                    <Select.Option value="telegram">
                                        <Icon.SendOutlined style={{ color: '#2196F3' }} /> Телеграм
                                    </Select.Option>
                                    <Select.Option value="viber">
                                        <Icon.WhatsAppOutlined style={{ color: '#a470ff' }} /> Вайбер
                                    </Select.Option>
                                    <Select.Option value="admin">
                                        <Icon.SafetyCertificateOutlined style={{ color: '#4CAF50' }} /> Адмін панель
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* Status */}
                        <Col className="gutter-row" span={24} sm={12}>
                            <Form.Item
                                name="status"
                                label="Статус"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}>
                                <Select placeholder="Виберіть статус замовлення" loading={OrderSettings.isLoading} disabled={OrderSettings.isLoading}>
                                    {OrderSettings.orderSettingsData[0]?.status.map((status) => (
                                        <Select.Option key={status._id} value={status._id}>
                                            <Badge color={status.color} text={status.title} />
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={24}>
                            <Form.Item name="note" label="Опис">
                                <TextArea allowClear autoSize={{ minRows: 5 }} placeholder="Введіть текст" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};
