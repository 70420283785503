import { Col, Modal, Row, Typography, Upload } from 'antd';
import { UploadFile } from 'antd/lib';
import { FC, useState } from 'react';
import { SERVER_URL } from '../../../../../config';
import { PlusOutlined } from '@ant-design/icons';

interface IProps {
    fileGallery: any;
    onChangeGallery: any;
    onChangeUpdateName: any;
}

export const ProductGallery: FC<IProps> = ({
    fileGallery,
    onChangeGallery,
    onChangeUpdateName,
}) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [previewColor, setPreviewColor] = useState('');

    const handlePreview = async (file: UploadFile) => {
        setPreviewImage(file.url || SERVER_URL + file.response?.url);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
        // setPreviewColor(imageInfo?.color);
    };

    const fetchUpdateName = (art: string, color: string) => {
        setPreviewTitle(art);
        setPreviewColor(color);
        onChangeUpdateName(previewTitle, art, color);
    };

    const modalClose = () => {
        setPreviewOpen(false);
        setPreviewImage('');
        setPreviewTitle('');
        setPreviewColor('');
    };

    return (
        <Row>
            <Col>
                <Upload
                    accept="jpeg jpg png"
                    action={`${SERVER_URL}/upload`}
                    listType="picture-card"
                    multiple
                    fileList={fileGallery}
                    onChange={onChangeGallery}
                    onPreview={handlePreview}>
                    <PlusOutlined />
                </Upload>
                <Modal open={previewOpen} footer={null} onCancel={modalClose}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    {/* <Typography.Paragraph
                        editable={{ triggerType: ['text', 'icon'], onChange: fetchUpdateName }}>
                        {previewTitle}
                    </Typography.Paragraph> */}
                    <Row gutter={[10, 10]}>
                        <Col span={12}>
                            Артикул:
                            <Typography.Paragraph
                                editable={{
                                    triggerType: ['text', 'icon'],
                                    onChange: (art) => fetchUpdateName(art, previewColor),
                                }}>
                                {previewTitle}
                            </Typography.Paragraph>
                        </Col>
                        <Col span={12}>
                            Колір: 🇺🇦/🇷🇺
                            <Typography.Paragraph
                                editable={{
                                    triggerType: ['text', 'icon'],
                                    onChange: (color) => fetchUpdateName(previewTitle, color),
                                }}>
                                {previewColor}
                            </Typography.Paragraph>
                        </Col>
                    </Row>
                </Modal>
            </Col>
        </Row>
    );
};
