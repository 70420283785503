import { FC, useRef, useState } from 'react';
import { Button, Col, ColorPicker, Drawer, Form, Input, Row, message } from 'antd';
import axios from '../../../../axios';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/useRedux';
import * as Icon from '@ant-design/icons';
import * as OrdersSettings from '../../../../store/orders/orders.settings.slice';

const key = 'creatable';

interface IValueForm {
    status: IStatusForm[];
}

interface IStatusForm {
    title: string;
    color: string | any;
}

export const OrderSettings: FC = () => {
    const [open, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const inputRef = useRef(null);
    const dispatch = useAppDispatch();

    const { orderSettingsData } = useAppSelector((state) => state.ordersSettings);
    const OrdersStatusData = orderSettingsData[0]?.status;

    console.log('orderSettingsData', OrdersStatusData);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onFinish = async (values: IValueForm) => {
        setLoading(true);
        const convertedColors = values.status.map((status) => {
            if (typeof status.color !== 'string') {
                return { ...status, color: status.color.toHexString() };
            } else {
                return status;
            }
        });

        message.loading({ content: 'Завантаження...', key });
        try {
            const { data } = await axios.post('/api/order/settings/update', {
                status: convertedColors,
            });
            if (data.success) {
                dispatch(OrdersSettings.updateOrderStatus(data.data));
                message.success({ content: 'Збережено!', key, duration: 2 });
                setLoading(false);
            } else {
                setLoading(false);
                message.error({ content: 'Сталась помилка!', key, duration: 2 });
            }
        } catch (e) {
            setLoading(false);
            message.error({ content: 'Сталась помилка!', key, duration: 2 });
            return;
        }
    };

    return (
        <>
            <Button type="default" onClick={showDrawer} icon={<Icon.SettingOutlined />} />
            <Drawer
                title="Налаштування"
                open={open}
                placement="right"
                onClose={onClose}
                extra={
                    <Button
                        icon={<Icon.SaveOutlined />}
                        // loading={isLoading}
                        type="primary"
                        // @ts-ignore
                        onClick={() => inputRef?.current?.submit()}
                    />
                }>
                <Row>
                    <Col span={24} style={{ marginBottom: 10 }}>
                        Статуси
                    </Col>
                    <Col span={24}>
                        <Form
                            name="dynamic_form_nest_item"
                            ref={inputRef}
                            onFinish={onFinish}
                            autoComplete="off">
                            <Form.List name="status" initialValue={OrdersStatusData}>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <div key={key} style={{ display: 'flex' }}>
                                                <div style={{ marginRight: 10 }}>
                                                    <Form.Item
                                                        name={[name, 'color']}
                                                        style={{ marginBottom: 10 }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '',
                                                            },
                                                        ]}>
                                                        <ColorPicker format="hex" defaultValue="" />
                                                    </Form.Item>
                                                </div>
                                                <div style={{ width: '100%' }}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'title']}
                                                        style={{ marginBottom: 10 }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '',
                                                            },
                                                        ]}>
                                                        <Input placeholder="Введіть статус" />
                                                    </Form.Item>
                                                </div>
                                                <div>
                                                    <Icon.MinusCircleOutlined
                                                        onClick={() => remove(name)}
                                                        style={{
                                                            marginLeft: 10,
                                                            position: 'relative',
                                                            top: 5,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                block
                                                icon={<Icon.PlusOutlined />}>
                                                Додати статус
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Form>
                    </Col>
                </Row>
            </Drawer>
        </>
    );
};
