import { FC } from 'react';
import axios from '../../../../../axios';
import { deleteUser } from '../../../../../store/users/users.slice';
import { useAppDispatch } from '../../../../../store/hooks/useRedux';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { message, Button, Modal } from 'antd';
import { IsAccess } from '../../../../../components/IsAccess/IsAccess';

interface IProps {
    userId: string;
}

const ADMINISTRATOR = '633401c1df1dec1b16e37a0c';
const key = 'deleted';

export const UserDelete: FC<IProps> = ({ userId }) => {
    const dispatch = useAppDispatch();
    const isAccess = IsAccess();

    const fetchUserDelete = async () => {
        if (!isAccess?.users.delete) {
            return message.error('Видалення заборонено!');
        }
        if (ADMINISTRATOR === userId) {
            message.error('Цього користувача забороенно видаляти!');
            return null;
        }
        message.loading({ content: 'Завантаження...', key });
        try {
            const { data } = await axios.delete(`/api/user/${userId}`);
            if (data.success) {
                dispatch(deleteUser(userId));
                message.success({ content: 'Користувач видалений!', key, duration: 2 });
            }
            return;
        } catch (err: any) {
            message.error({ content: `Помилка! ${err?.message}`, key, duration: 2 });
        }
    };

    const showConfirm = () => {
        Modal.confirm({
            title: 'Увага!',
            content: 'Ви дійсно хочете видалити користувача?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Так',
            okType: 'danger',
            cancelText: 'Ні',
            onOk() {
                fetchUserDelete();
            },
        });
    };

    return (
        <Button size="small" onClick={showConfirm} disabled={!isAccess?.users.delete}>
            <DeleteOutlined key="delete" />
        </Button>
    );
};
