import { FC } from 'react';
import { Button, Col, Row, Typography } from 'antd';

import './AppError.scss';

export const AppError: FC<any> = ({ resetErrorBoundary }) => (
    <div className="app-error">
        <Row gutter={[10, 10]}>
            <Col span={24}>
                <Typography.Title
                    type="danger"
                    className="app-error__content"
                    style={{ margin: 0 }}>
                    Ooops...
                </Typography.Title>
            </Col>
            <Col span={24} className="app-error__content">
                <Typography.Text>Щось пішло не так, спробуйте оновити сторінку.</Typography.Text>
            </Col>
            <Col span={24} className="app-error__content">
                <Button onClick={resetErrorBoundary}>Оновити</Button>
            </Col>
        </Row>
    </div>
);
