import { useState } from 'react';
import axios from '../../../../axios';
import { useAppDispatch } from '../../../../store/hooks/useRedux';
import { message, Modal, Typography } from 'antd';
import * as Template from '../../../../store/templates/templates.slice';
import type { TableRowSelection } from 'antd/es/table/interface';
import { ITemplatesPage } from '../../../../types';
import * as Icon from '@ant-design/icons';
import { IsAccess } from '../../../../components/IsAccess/IsAccess';

interface IGroupUpdateProps {
    action: string;
    templatesId: React.Key[];
}

const errorMessage = (msg: string) => message.error(msg);

const key = 'updatable';

export const TemplatesGroupUpdate = () => {
    const dispatch = useAppDispatch();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const isAccess = IsAccess();
    const selectedQuantity = !!selectedRowKeys.length && selectedRowKeys.length;

    const groupUpdate = async (props: IGroupUpdateProps) => {
        message.loading({ content: 'Завантаження...', key });

        try {
            const { data } = await axios.patch('/api/templates/group/update', props);

            if (data.success) {
                switch (props.action) {
                    case 'Duplicate':
                        dispatch(Template.duplicateGroup(data.templates));
                        message.success({
                            content: `Дублювати ${props.templatesId.length} шаблон(и)!`,
                            key,
                            duration: 2,
                        });
                        break;
                    case 'Delete':
                        dispatch(Template.removeGroup(props.templatesId));
                        message.success({
                            content: `Видалити ${props.templatesId.length} шаблон(и)!`,
                            key,
                            duration: 2,
                        });
                        break;

                    default:
                        break;
                }
            } else {
                message.error({ content: 'Сталась помилка!', key, duration: 2 });
            }
        } catch (e) {
            message.error({ content: 'Сталась помилка!', key, duration: 2 });
        }
    };

    const showStatusConfirm = (props: IGroupUpdateProps) => {
        Modal.confirm({
            title: 'Ви впевнені?',
            content: 'Що хочете дублювати шаблон(и)?',
            icon: <Icon.ExclamationCircleOutlined />,
            okText: 'Так',
            cancelText: 'Ні',
            onOk() {
                groupUpdate(props);
            },
        });
    };

    const showDeleteConfirm = (props: IGroupUpdateProps) => {
        Modal.confirm({
            title: 'Ви впевнені?',
            content: (
                <>
                    <div>Що хочете видалити шаблон(и)? </div>
                    <div>
                        <Typography.Text type="danger">Ви не зможете їх відновити!</Typography.Text>
                    </div>
                </>
            ),
            icon: <Icon.ExclamationCircleOutlined />,
            okText: 'Так',
            cancelText: 'Ні',
            okType: 'danger',
            onOk() {
                groupUpdate(props);
            },
        });
    };

    const rowSelection: TableRowSelection<ITemplatesPage> = {
        selectedRowKeys,
        onChange: (selected) => setSelectedRowKeys(selected),
        selections: [
            {
                key: 'duplicate',
                text: (
                    <>
                        <Icon.CopyOutlined style={{ marginRight: 5, color: '#F59337' }} />
                        Дублювати {selectedQuantity}
                    </>
                ),
                onSelect: () => {
                    if (isAccess?.templates.duplicate) {
                        selectedRowKeys.length
                            ? showStatusConfirm({
                                  action: 'Duplicate',
                                  templatesId: selectedRowKeys,
                              })
                            : errorMessage('Сталась помилка. Виберіть шаблон(и)');
                    } else {
                        errorMessage('Заборонено дублювання!');
                    }
                },
            },
            {
                key: 'delete',
                text: (
                    <>
                        <Icon.DeleteOutlined style={{ marginRight: 5, color: '#fa541c' }} />
                        Видалити {selectedQuantity}
                    </>
                ),
                onSelect: () => {
                    if (isAccess?.templates.delete) {
                        selectedRowKeys.length
                            ? showDeleteConfirm({
                                  action: 'Delete',
                                  templatesId: selectedRowKeys,
                              })
                            : errorMessage('Сталась помилка. Виберіть шаблон(и)');
                    } else {
                        errorMessage('Заборонено видалення!');
                    }
                },
            },
        ],
    };

    return rowSelection;
};
