import { FC, useMemo, useState } from 'react';
import { FieldCodeCreate } from './Field.code/Field.code.create';
import { FieldTestimonialCreate } from './Field.testimonial/Field.testimonial.create';
import { FieldFaqCreate } from './Field.faq/Field.faq.create';
import { FieldImageCreate } from './Field.image/Field.image.create';
import { FieldLinksCreate } from './Field.links/Field.links.create';
import { FieldButtonCreate } from './Field.button/Field.button.create';
import { FieldListCreate } from './Field.list/Field.list.create';
import { FieldFeaturesCreate } from './Field.features/Field.features.create';
import { FieldRichTextCreate } from './Field.rich_text/Field.rich_text.create';
import { FieldTextCreate } from './Field.text/Field.text.create';
import { FieldVideoCreate } from './Field.video/Field.video.create';
import { FieldStepCreate } from './Field.step/Field.step.create';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Row } from 'antd';
import { FieldSliderCreate } from './Field.slider/Field.slider.create';

interface IProps {
    mainId: string;
    sectionId: string;
    request_url: string;
    fieldCreate: any;
    fieldCreateLanding?: any;
    isTemplate?: boolean;
    size?: 'small' | 'middle' | 'large';
    title?: string;
}

export const FieldCreate: FC<IProps> = ({ mainId, sectionId, size, title = 'Додати поле', ...other }) => {
    const [isModal, setIsModal] = useState(false);

    const params = useMemo(
        () => ({
            main_id: mainId,
            section_id: sectionId,
            handleModal: setIsModal,
            ...other,
        }),
        [mainId, sectionId, other],
    );

    return (
        <>
            <Button size={size || 'middle'} type="primary" onClick={() => setIsModal(true)} icon={<PlusOutlined />}>
                {title}
            </Button>

            <Modal okText="Зберегти" title="Нове поле" open={isModal} footer={null} onCancel={() => setIsModal(false)}>
                <Row gutter={[10, 10]}>
                    <FieldTextCreate {...params} />
                    <FieldLinksCreate {...params} />
                    <FieldButtonCreate {...params} />
                    <FieldFeaturesCreate {...params} />
                    <FieldImageCreate {...params} />
                    <FieldRichTextCreate {...params} />
                    <FieldListCreate {...params} />
                    <FieldStepCreate {...params} />
                    <FieldTestimonialCreate {...params} />
                    <FieldFaqCreate {...params} />
                    <FieldVideoCreate {...params} />
                    <FieldCodeCreate {...params} />
                    <FieldSliderCreate {...params} />
                </Row>
            </Modal>
        </>
    );
};
