import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../axios';
import { IOrdersSetings } from '../../types';

interface IinitialState {
    isLoading: boolean;
    orderSettingsData: IOrdersSetings[];
}

export const getOrdersSettings = createAsyncThunk(
    'ordersSettings/getSettings',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await axios.get('/api/order/settings/get');
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

const initialState: IinitialState = {
    isLoading: false,
    orderSettingsData: [],
};

const OrdersSettings = createSlice({
    name: 'ordersSettings',
    initialState,
    reducers: {
        updateOrderStatus: (state, action) => {
            state.orderSettingsData[0] = action.payload;
        },
    },
    extraReducers: (build) => {
        build
            .addCase(getOrdersSettings.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getOrdersSettings.fulfilled, (state, action) => {
                state.orderSettingsData = action.payload;
                state.isLoading = false;
            })
            .addCase(getOrdersSettings.rejected, (state, action) => {
                state.isLoading = false;
            });
    },
});

export default OrdersSettings.reducer;

export const { updateOrderStatus } = OrdersSettings.actions;
