import { FC, Key } from 'react';
import { Reorder, useDragControls } from 'framer-motion';
import { Button, Col, Collapse, Row, Space, Tooltip } from 'antd';
import { FieldIcon } from '../../../../../components/Fields/FieldIcon';
import { FieldDescription } from '../../../../../components/FieldDescription/FieldDescription';
import { FieldDelete } from '../../../../../components/Fields/Field.delete';
import { FieldUpdate } from '../../../../../components/Fields/Fields.update';
import { EditOutlined, HolderOutlined } from '@ant-design/icons';
import { IFields } from '../../../../../types';
import { IsAccess } from '../../../../../components/IsAccess/IsAccess';

interface IProps {
    fieldItem: IFields;
    spolerKey: React.Key[];
    spollerHandleClicks: (id: Key) => any;
    TEMPLATE_ID: string;
    SECTION_ID: string;
    TemplateSlice: any;
    LandingSlice: any;
}

export const TemplateFieldItem: FC<IProps> = ({
    fieldItem,
    spolerKey,
    spollerHandleClicks,
    TEMPLATE_ID,
    SECTION_ID,
    TemplateSlice,
    LandingSlice,
}) => {
    const controls = useDragControls();
    const isAccess = IsAccess();

    return (
        <Reorder.Item
            dragListener={false}
            dragControls={controls}
            value={fieldItem}
            whileDrag={{
                scale: 1.01,
                zIndex: 1,
                position: 'relative',
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}>
            <Collapse activeKey={spolerKey} style={{ marginBottom: 5 }}>
                <Collapse.Panel
                    key={fieldItem._id}
                    showArrow={false}
                    header={
                        <Row
                            justify="space-between"
                            align="middle"
                            style={{
                                cursor: 'default',
                            }}>
                            <Col
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        minWidth: 150,
                                        maxWidth: 300,
                                    }}>
                                    <HolderOutlined
                                        className="reorder-handle"
                                        style={{
                                            cursor: 'move',
                                            marginRight: 10,
                                        }}
                                        onPointerDown={(e) => controls.start(e)}
                                    />
                                    <FieldIcon fieldType={fieldItem.field_type} />
                                    <span
                                        style={{
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            marginRight: 10,
                                        }}>
                                        <Tooltip title={fieldItem.field_name}>{fieldItem.field_name}</Tooltip>
                                    </span>
                                </div>
                                <div
                                    style={{
                                        textAlign: 'center',
                                        minWidth: 70,
                                        borderLeft: '1px solid #e0e0e0',
                                        borderRight: '1px solid #e0e0e0',
                                        marginRight: 10,
                                        fontSize: 10,
                                        color: 'grey',
                                    }}>
                                    {fieldItem.field_type}
                                </div>
                                <div>
                                    <FieldDescription field={fieldItem} />
                                </div>
                            </Col>

                            <Col>
                                <Space size="small">
                                    <Button
                                        size="small"
                                        type={spolerKey.includes(fieldItem._id) ? 'primary' : 'default'}
                                        onClick={() => spollerHandleClicks(fieldItem._id)}
                                        disabled={!isAccess?.templates.edit}>
                                        <EditOutlined />
                                    </Button>

                                    <FieldDelete
                                        mainId={TEMPLATE_ID}
                                        sectionId={SECTION_ID}
                                        fieldId={fieldItem._id}
                                        fieldDelete={TemplateSlice.fieldDelete}
                                        request_url="/api/template/field/delete/"
                                        fieldDeleteLanding={LandingSlice.fieldDeleteFromTemplate}
                                        isTemplate={true}
                                    />
                                </Space>
                            </Col>
                        </Row>
                    }>
                    <FieldUpdate
                        mainId={TEMPLATE_ID}
                        sectionId={SECTION_ID}
                        field={fieldItem}
                        fieldUpdate={TemplateSlice.fieldUpdate}
                        request_url="/api/template/field/update"
                        fieldUpdateLanding={LandingSlice.fieldUpdateFromTemplate}
                        isTemplate={true}
                    />
                </Collapse.Panel>
            </Collapse>
        </Reorder.Item>
    );
};
