import { FC, useState } from 'react';
import axios from '../../../axios';
import { useAppDispatch } from '../../../store/hooks/useRedux';
import { Button, Col, Form, Input, message, Row, Tabs } from 'antd';
import { IFieldUpdateProps } from '../../../types/index';
import { MinusCircleOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { ILink } from '../../../types/';

interface IValue {
    name: string;
    links: ILink[];
}

const key = 'updatable';

export const FieldLinksUpdate: FC<IFieldUpdateProps> = ({
    field,
    main_id,
    section_id,
    request_url,
    fieldUpdate,
}) => {
    const [isLoadingForm, setIsLoadingForm] = useState(false);

    const dispatch = useAppDispatch();

    const [form] = Form.useForm();

    const handleUpdateField = async ({ name, ...other }: IValue) => {
        setIsLoadingForm(true);
        message.loading({ content: 'Завантаження...', key });
        try {
            const updateField = {
                main_id,
                section_id,
                fieldId: field._id,
                information: {
                    field_type: 'Links',
                    field_name: name,
                },
                content: { ...field.content, ...other },
            };

            await axios.patch(request_url, updateField);

            dispatch(fieldUpdate(updateField));

            setIsLoadingForm(false);
            message.success({ content: 'Збережено!', key, duration: 2 });
        } catch (e) {
            setIsLoadingForm(false);
            message.error({ content: 'Помилка!', key, duration: 2 });
        }
    };

    return (
        <Form
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            onFinish={handleUpdateField}
            size="middle"
            layout="vertical"
            autoComplete="off">
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item
                        name="name"
                        label="Назва поля"
                        initialValue={field.field_name}
                        rules={[{ required: true, message: '' }]}>
                        <Input placeholder="Введіть назву поля" size="middle" />
                    </Form.Item>
                </Col>
            </Row>
            <hr style={{ border: '0.1px solid #00000014' }} />

            <div style={{ marginBottom: 5, marginTop: 10 }}>Контент</div>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Tabs
                        className="tab-card-content-admin"
                        size="small"
                        tabPosition="top"
                        defaultActiveKey="UA"
                        type="card">
                        <Tabs.TabPane tab={<span>🇺🇦 UA</span>} key="UA">
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Form.List
                                        name={['UA', 'links']}
                                        initialValue={field.content.UA.links}>
                                        {(ua_links, { add, remove }) => (
                                            <>
                                                {ua_links.map(({ key, name, ...restField }) => (
                                                    <Row
                                                        key={key}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 5,
                                                            marginBottom: 10,
                                                        }}>
                                                        <Col span={11}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'title']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: '',
                                                                    },
                                                                ]}>
                                                                <Input placeholder="Текст" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={11}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'link']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: '',
                                                                    },
                                                                ]}>
                                                                <Input
                                                                    addonBefore="URL"
                                                                    placeholder="Link"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col
                                                            span={1}
                                                            style={{ textAlign: 'center' }}>
                                                            <MinusCircleOutlined
                                                                onClick={() => remove(name)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => add()}
                                                        block
                                                        icon={<PlusOutlined />}>
                                                        Додати посилання
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Col>
                            </Row>
                        </Tabs.TabPane>

                        <Tabs.TabPane tab={<span>🇷🇺 RU</span>} key="RU">
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Form.List
                                        name={['RU', 'links']}
                                        initialValue={field.content.RU.links}>
                                        {(ru_links, { add, remove }) => (
                                            <>
                                                {ru_links.map(({ key, name, ...restField }) => (
                                                    <Row
                                                        key={key}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 5,
                                                            marginBottom: 10,
                                                        }}>
                                                        <Col span={11}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'title']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: '',
                                                                    },
                                                                ]}>
                                                                <Input placeholder="Текст" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={11}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'link']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: '',
                                                                    },
                                                                ]}>
                                                                <Input
                                                                    addonBefore="URL"
                                                                    placeholder="Link"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col
                                                            span={1}
                                                            style={{ textAlign: 'center' }}>
                                                            <MinusCircleOutlined
                                                                onClick={() => remove(name)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => add()}
                                                        block
                                                        icon={<PlusOutlined />}>
                                                        Добавить ссылку
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                    </Tabs>
                </Col>

                <Col span={24}>
                    <Button loading={isLoadingForm} type="primary" onClick={() => form.submit()}>
                        <SaveOutlined /> Зберегти
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};
