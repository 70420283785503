import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/rootReducer';

import { App } from './App';

import './assets/styles/index.scss';
import { ConfigProvider, theme } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        <ConfigProvider
            theme={{
                algorithm: theme.defaultAlgorithm,
                token: {
                    // colorPrimary: '#F59337',
                    // colorBgContainer: 'red',
                    // colorBgBase: 'red',
                    // colorBgContainer: 'red',',
                },
                components: {
                    //     Button: {
                    //         defaultBg: 'transparent',
                    //         defaultShadow: 'none',

                    //         colorPrimary: '#F59337',
                    //         colorPrimaryHover: '#c97d36',
                    //         colorPrimaryActive: '#c97d36',
                    //     },
                    Layout: {
                        headerBg: '#fff',
                        // siderBg: '#323541',
                        // triggerBg: '#323541',
                        // bodyBg: '#383B46',
                    },
                    //     Menu: {
                    //         darkItemBg: '#323541',
                    //         collapsedIconSize: 16,
                    //         collapsedWidth: 40,
                    //     },
                    //     Table: {
                    //         borderColor: '#323541',
                    //         headerBg: '#3f414e',
                    //         headerSplitColor: '#323541',
                    //         rowHoverBg: '#2e313c',
                    //         rowExpandedBg: '#2e313c',
                    //         rowSelectedHoverBg: '#ff9800b0',
                    //         controlItemBgActive: '#ff9800b0',
                    //         colorBgContainer: '#3f414e',
                    //         expandIconBg: 'transparent',
                    //     },
                },
            }}>
            <App />
        </ConfigProvider>
    </Provider>,
);
