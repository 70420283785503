import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../../../../store/hooks/useRedux';
import { Badge, Button, Col, Form, Input, message, Modal, Row, Select, Upload } from 'antd';
import { editUser } from '../../../../../../store/users/users.slice';
import axios from '../../../../../../axios';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import ImgCrop from 'antd-img-crop';
import * as Icon from '@ant-design/icons';
import { SERVER_URL } from '../../../../../../config';

interface IValue {
    email: string;
    firstName: string;
    lastName: string;
    role: React.Key;
}

const key = 'updatable';

export const UsersPersonalInformation: FC = () => {
    const [isLoading, setLoading] = useState(false);

    const dispatch = useAppDispatch();
    const { userId } = useParams();
    const { roles } = useAppSelector((state) => state.usersRole);
    const user = useAppSelector((state) => state.users.users.find((user) => user._id === userId));

    // AVATAR
    const [previewOpen, setPreviewOpen] = useState(false);
    const [fileAvatar, setAvatarList] = useState<UploadFile[]>(
        user?.avatarUrl
            ? [
                  {
                      uid: '1',
                      name: '',
                      status: 'done',
                      url: user?.avatarUrl,
                  },
              ]
            : [],
    );
    const avatarUrl = fileAvatar
        ? fileAvatar[0]?.url
            ? user?.avatarUrl
            : fileAvatar[0]?.response?.url
            ? SERVER_URL + fileAvatar[0]?.response?.url
            : ''
        : '';

    const onChangeAvatar: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
        try {
            setAvatarList(newFileList);
        } catch (e) {
            console.log('Помилка при завантажені зображення!', e);
        }
    };
    // ./AVATAR

    const fetchUserUpdate = async (values: IValue) => {
        setLoading(true);
        message.loading({ content: 'Завантаження...', key });
        try {
            const { data } = await axios.patch(`/api/user/${userId}`, {
                avatarUrl,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                roleId: values.role,
                social: {},
            });
            message.success({ content: 'Збережено!', key, duration: 2 });
            setLoading(false);
            dispatch(
                editUser({
                    _id: userId,
                    avatarUrl,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    roleId: values.role,
                }),
            );
            return data;
        } catch (e) {
            message.error({ content: 'Помилка!', key, duration: 2 });
            setLoading(false);
            return;
        }
    };

    return (
        <Form name="basic" initialValues={{ remember: true }} onFinish={fetchUserUpdate} size="middle" autoComplete="off">
            <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={4} lg={3} xl={2} className="gutter-row">
                    {/* Image */}
                    <Row gutter={[16, 24]}>
                        <Col className="gutter-row">
                            <ImgCrop quality={1} modalTitle="Avatar" modalOk="Зберегти">
                                <Upload
                                    accept="jpeg jpg png"
                                    action={`${SERVER_URL}/upload`}
                                    listType="picture-card"
                                    fileList={fileAvatar}
                                    onChange={onChangeAvatar}
                                    onPreview={() => setPreviewOpen(true)}>
                                    {fileAvatar.length < 1 && <Icon.CloudUploadOutlined style={{ fontSize: 30 }} />}
                                </Upload>
                            </ImgCrop>
                            <Modal
                                open={previewOpen}
                                title={user?.firstName + ' ' + user?.lastName}
                                footer={null}
                                onCancel={() => setPreviewOpen(false)}>
                                <img alt="example" style={{ width: '100%' }} src={user?.avatarUrl} />
                            </Modal>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={20} lg={21} xl={22}>
                    {/* First Name */}
                    <Row gutter={[10, 10]}>
                        <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="firstName"
                                hasFeedback
                                initialValue={user?.firstName}
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message: '',
                                    },
                                    {
                                        type: 'string',
                                        pattern: /^[^\s][a-zA-Zа-яА-Я\s]*$/,
                                        message: '',
                                    },
                                    {
                                        type: 'string',
                                        min: 2,
                                        message: '',
                                    },
                                ]}>
                                <Input placeholder="Ім'я" prefix={<Icon.UserOutlined />} allowClear />
                            </Form.Item>
                        </Col>

                        {/* Last Name */}
                        <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="lastName"
                                hasFeedback
                                initialValue={user?.lastName}
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message: '',
                                    },
                                    {
                                        type: 'string',
                                        pattern: /^[^\s][a-zA-Zа-яА-Я\s]*$/,
                                        message: '',
                                    },
                                    {
                                        type: 'string',
                                        min: 2,
                                        message: '',
                                    },
                                ]}>
                                <Input placeholder="Фамілія" prefix={<Icon.UserOutlined />} allowClear />
                            </Form.Item>
                        </Col>

                        {/* E-Mail */}
                        <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="email"
                                initialValue={user?.email}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                    {
                                        type: 'email',
                                        message: '',
                                    },
                                ]}>
                                <Input placeholder="E-Mail" prefix={<Icon.MailOutlined />} allowClear />
                            </Form.Item>
                        </Col>

                        {/* Role */}
                        <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item name="role" initialValue={user?.roleId} hasFeedback rules={[{ required: true, message: '' }]}>
                                <Select
                                    showSearch
                                    placeholder="Вибрати посаду..."
                                    optionFilterProp="children"
                                    optionLabelProp="label"
                                    loading={!roles}
                                    filterOption={(input, option: any) => option.children.props.text.toLowerCase().includes(input.toLowerCase())}>
                                    {roles.map((role) => (
                                        <Select.Option key={role._id} value={role._id} label={<Badge color={role.color} text={role.title} />}>
                                            <Badge color={role.color} text={role.title} />
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* New Password */}
                        <Col className="gutter-row" span={12}>
                            <Form.Item
                                name="new_password"
                                rules={[
                                    { required: true, message: '' },
                                    {
                                        type: 'string',
                                        min: 5,
                                        message: 'Пароль повинен бути не меньше 5 символів',
                                    },
                                ]}>
                                <Input.Password
                                    placeholder="Введить новий пароль"
                                    allowClear
                                    prefix={<Icon.UnlockOutlined />}
                                    iconRender={(visible) => (visible ? <Icon.EyeTwoTone /> : <Icon.EyeInvisibleOutlined />)}
                                />
                            </Form.Item>
                        </Col>

                        {/* Confirm Password */}
                        <Col className="gutter-row" span={12}>
                            <Form.Item
                                name="confirm_new_password"
                                dependencies={['new_password']}
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('new_password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Паролі не співпадають'));
                                        },
                                    }),
                                ]}>
                                <Input.Password
                                    placeholder="Повторіть пароль"
                                    prefix={<Icon.UnlockOutlined />}
                                    allowClear
                                    iconRender={(visible) => (visible ? <Icon.EyeTwoTone /> : <Icon.EyeInvisibleOutlined />)}
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24}>
                            <Button htmlType="submit" loading={isLoading} icon={<Icon.SaveOutlined />} type="primary">
                                Зберегти зміни
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};
