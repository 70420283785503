import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, Button, Layout, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { TemplatesSettings } from './Templates.settings';
import { TemplatesSections } from './Templates.sections/Templates.sections';
import { useAppSelector } from '../../../../store/hooks/useRedux';
import { SectionCreate } from './Templates.sections/Section.create/Section.create';
import { TemplatesJson } from './Templates.json';

import { CodeOutlined, DatabaseOutlined, LeftOutlined, SettingOutlined } from '@ant-design/icons';

export const TemplatesContent: FC = () => {
    const navigation = useNavigate();
    const { id: TEMPLATE_PAGE_ID } = useParams();
    const template = useAppSelector((state) => state.templates.TemplatesData.find((template) => template._id === TEMPLATE_PAGE_ID));

    return (
        <Layout>
            <Breadcrumb
                items={[
                    {
                        title: (
                            <>
                                <Button
                                    icon={<LeftOutlined />}
                                    type={'primary'}
                                    style={{ marginRight: 10 }}
                                    onClick={() => navigation('/templates')}
                                />
                                Шаблони
                            </>
                        ),
                    },
                    { title: template?.name },
                ]}
            />

            <Content>
                <Tabs
                    size="small"
                    tabPosition="top"
                    type="card"
                    tabBarExtraContent={<SectionCreate templateId={TEMPLATE_PAGE_ID} />}
                    items={[
                        {
                            key: '1',
                            label: (
                                <>
                                    <DatabaseOutlined /> Блоки
                                </>
                            ),
                            children: <TemplatesSections />,
                        },
                        {
                            key: '2',
                            label: (
                                <>
                                    <CodeOutlined /> JSON
                                </>
                            ),
                            children: <TemplatesJson />,
                        },
                        {
                            key: '3',
                            label: (
                                <>
                                    <SettingOutlined /> Налаштування
                                </>
                            ),
                            children: <TemplatesSettings />,
                        },
                    ]}
                />
            </Content>
        </Layout>
    );
};
