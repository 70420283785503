import { CSSProperties, FC } from 'react';
import { ConfigProvider, Empty } from 'antd';

import './EmptyCustome.scss';

interface IProps {
    children: JSX.Element;
    className?: string;
    style?: CSSProperties;
}

export const EmptyCustome: FC<IProps> = ({ children, className = '', style }) => {
    const customizeRenderEmpty = () => (
        <Empty className={`empty-custom ${className}`} style={style} imageStyle={{ height: 70 }} description={'Нічого не знайдено!'} />
    );

    return <ConfigProvider renderEmpty={customizeRenderEmpty}>{children}</ConfigProvider>;
};
