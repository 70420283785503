import { EditOutlined, FolderFilled, HolderOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Row, Space } from 'antd';
import { Reorder, useDragControls } from 'framer-motion';
import { FC, Key } from 'react';
import { ITemplateSections } from '../../../../../types';
import { SectionEdit } from './Section.edit/Section.edit';
import { SectionDelete } from './Section.delete/Section.delete';
import { TemplatesFields } from '../Template.fields/Templates.fields';
import { IsAccess } from '../../../../../components/IsAccess/IsAccess';

interface IProps {
    section: ITemplateSections;
    sectionSpollerKey: React.Key[];
    TEMPLATE_PAGE_ID?: string;
    sectionSpollerHandle: (id: Key) => any;
    isNestedDraggingRef: any;
}

export const TemplateSectionsItem: FC<IProps> = ({ section, sectionSpollerKey, sectionSpollerHandle, TEMPLATE_PAGE_ID, isNestedDraggingRef }) => {
    const controls = useDragControls();
    const isAccess = IsAccess();

    return (
        <Reorder.Item
            dragListener={false}
            dragControls={controls}
            value={section}
            whileDrag={{
                scale: 1.01,
                zIndex: 1,
                position: 'relative',
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}>
            <Collapse activeKey={sectionSpollerKey} style={{ marginBottom: 5 }}>
                <Collapse.Panel
                    key={section._id}
                    showArrow={false}
                    header={
                        <Col key={section._id} span={24}>
                            <Row justify="space-between" style={{ cursor: 'default' }}>
                                <Row align="middle">
                                    <HolderOutlined
                                        style={{
                                            cursor: 'move',
                                            marginRight: 10,
                                        }}
                                        onPointerDown={(e) => controls.start(e)}
                                    />
                                    <FolderFilled
                                        style={{
                                            marginRight: 10,
                                            fontSize: 20,
                                        }}
                                    />
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        {section.title}
                                        {isAccess?.templates.edit && <SectionEdit sectionId={section._id} />}
                                    </div>
                                    <span
                                        style={{
                                            marginLeft: 10,
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            fontSize: 9,
                                            color: 'grey',
                                            textTransform: 'uppercase',
                                            borderLeft: '1px solid #80808024',
                                        }}>
                                        {section.fields.length === 0 && `${section.fields.length} полів`}
                                        {section.fields.length === 1 && `${section.fields.length} поле`}
                                        {section.fields.length > 1 && section.fields.length < 5 && `${section.fields.length} поля`}
                                        {section.fields.length >= 5 && `${section.fields.length} полів`}
                                    </span>
                                </Row>
                                <Row gutter={3}>
                                    <Space size="small">
                                        <Button
                                            size="small"
                                            type={sectionSpollerKey.includes(section._id) ? 'primary' : 'default'}
                                            onClick={() => sectionSpollerHandle(section._id)}
                                            disabled={!isAccess?.templates.edit}>
                                            <EditOutlined />
                                        </Button>
                                        <SectionDelete sectionId={section._id} />
                                    </Space>
                                </Row>
                            </Row>
                        </Col>
                    }>
                    <TemplatesFields
                        sectionId={section._id}
                        fullButton={true}
                        createFieldButtonName=""
                        createFieldButtonSize="small"
                        style={{ height: 'auto' }}
                        isNestedDraggingRef={isNestedDraggingRef}
                    />
                </Collapse.Panel>
            </Collapse>
        </Reorder.Item>
    );
};
