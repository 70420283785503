import axios from 'axios';
import { SERVER_URL } from './config';

const instance = axios.create({
    baseURL: SERVER_URL,
});

instance.interceptors.request.use((config: any) => {
    config.headers.Authorization = window.localStorage.getItem('token');
    return config;
});

export default instance;
