import { FC, useRef, useState } from 'react';
import axios from '../../../../../../axios';
import { Button, Col, ColorPicker, Drawer, Form, Input, message, Row, Switch, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks/useRedux';
import { updateRole } from '../../../../../../store/settings/usersRole.slice';
import * as Icon from '@ant-design/icons';
import { IRolesForm } from '../../../../../../types';
import './Role.edit.scss';

interface IProps {
    roleId: string;
}

const key = 'updatable';

export const RoleEdit: FC<IProps> = ({ roleId }) => {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useAppDispatch();
    const inputRef = useRef(null);
    const [form] = Form.useForm();
    const { roles } = useAppSelector((state) => state.usersRole);
    const role = roles.find((role) => role._id === roleId);

    const showDrawer = () => setOpen(true);
    const onClose = () => setOpen(false);

    const handlePatchRole = async (props: IRolesForm) => {
        setIsLoading(true);
        message.loading({ content: 'Завантаження...', key });
        // @ts-ignore
        const colorToHex = typeof props.color === 'string' ? props.color : props.color.toHexString();
        try {
            const { data } = await axios.patch(`/api/roles/${roleId}`, { _id: roleId, ...props, color: colorToHex });
            if (data.success) {
                setIsLoading(false);
                dispatch(updateRole({ _id: roleId, ...props, color: colorToHex }));
                setOpen(false);
                message.success({ content: 'Збережено!', key, duration: 2 });
                return;
            } else {
                setIsLoading(false);
                message.error({ content: 'Помилка!', key, duration: 2 });
            }
        } catch (e) {
            setIsLoading(false);
            message.error({ content: 'Помилка!', key, duration: 2 });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Button size="small" loading={isLoading} onClick={showDrawer}>
                <Icon.EditOutlined />
            </Button>

            <Drawer
                title="Редагування посади"
                open={open}
                placement="right"
                onClose={onClose}
                extra={
                    <Button
                        icon={<Icon.SaveOutlined />}
                        loading={isLoading}
                        // @ts-ignore
                        onClick={() => inputRef?.current?.submit()}
                        type="primary"
                    />
                }>
                <Form
                    name="basic"
                    form={form}
                    ref={inputRef}
                    initialValues={{ remember: true }}
                    onFinish={handlePatchRole}
                    size="middle"
                    autoComplete="off">
                    <Row gutter={[16, 16]}>
                        <Col span={24} style={{ display: 'flex' }}>
                            {/* Color */}
                            <Form.Item name="color" initialValue={role?.color} rules={[{ required: true, message: '' }]}>
                                <ColorPicker format="hex" />
                            </Form.Item>

                            {/* Title */}
                            <Form.Item
                                name="title"
                                initialValue={role?.title}
                                rules={[
                                    { required: true, message: '' },
                                    { min: 2, message: '' },
                                ]}
                                style={{ width: '100%', marginLeft: 5 }}>
                                <Input placeholder="Назва" size="middle" />
                            </Form.Item>
                        </Col>

                        {/* Orders */}
                        <Col span={24} className="role_container">
                            <Row>
                                <Col span={24}>
                                    <Typography.Text strong>Заявки</Typography.Text>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item
                                        label="Створювати"
                                        name={['orders', 'create']}
                                        initialValue={role?.orders.create}
                                        valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item label="Редагувати" name={['orders', 'edit']} initialValue={role?.orders.edit} valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item
                                        label="Видаляти"
                                        name={['orders', 'delete']}
                                        initialValue={role?.orders.delete}
                                        valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        {/* Products */}
                        <Col span={24} className="role_container">
                            <Row>
                                <Col span={24}>
                                    <Typography.Text strong>Тканини</Typography.Text>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item
                                        label="Створювати"
                                        name={['products', 'create']}
                                        initialValue={role?.products.create}
                                        valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item
                                        label="Редагувати"
                                        name={['products', 'edit']}
                                        initialValue={role?.products.edit}
                                        valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item
                                        label="Видаляти"
                                        name={['products', 'delete']}
                                        initialValue={role?.products.delete}
                                        valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item
                                        label="Дублювати"
                                        name={['products', 'duplicate']}
                                        initialValue={role?.products.duplicate}
                                        valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        {/* Templates */}
                        <Col span={24} className="role_container">
                            <Row>
                                <Col span={24}>
                                    <Typography.Text strong>Шаблон</Typography.Text>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item
                                        label="Створювати"
                                        name={['templates', 'create']}
                                        initialValue={role?.templates.create}
                                        valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item
                                        label="Редагувати"
                                        name={['templates', 'edit']}
                                        initialValue={role?.templates.edit}
                                        valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item
                                        label="Видаляти"
                                        name={['templates', 'delete']}
                                        initialValue={role?.templates.delete}
                                        valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item
                                        label="Дублювати"
                                        name={['templates', 'duplicate']}
                                        initialValue={role?.templates.duplicate}
                                        valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        {/* Users */}
                        <Col span={24} className="role_container">
                            <Row>
                                <Col span={24}>
                                    <Typography.Text strong>Користувачі</Typography.Text>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item
                                        label="Створювати"
                                        name={['users', 'create']}
                                        initialValue={role?.users.create}
                                        valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item label="Редагувати" name={['users', 'edit']} initialValue={role?.users.edit} valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item label="Видаляти" name={['users', 'delete']} initialValue={role?.users.delete} valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        {/* Settings */}
                        <Col span={24} className="role_container">
                            <Row>
                                <Col span={24}>
                                    <Typography.Text strong>Налаштування</Typography.Text>
                                </Col>
                                <Col span={24} className="settings_users_role_create_modal__swich">
                                    <Form.Item
                                        label="Доступ до налаштувань"
                                        name="is_setting"
                                        initialValue={role?.is_setting}
                                        valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        {/* Admin-CP */}
                        <Col span={24} className="role_container">
                            <Row>
                                <Col span={24}>
                                    <Typography.Text strong>Адмін-панель</Typography.Text>
                                </Col>
                                <Col span={24} className="settings_users_role_create_modal__swich">
                                    <Form.Item
                                        label="Доступ до Адмін-панелі"
                                        name="is_admin_panel"
                                        initialValue={role?.is_admin_panel}
                                        valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};
