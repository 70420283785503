import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/useRedux';
import { Badge, Breadcrumb, Button, Col, Form, Input, Layout, message, Modal, Row, Select, Upload } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { createUser } from '../../../../../store/users/users.slice';
import axios from '../../../../../axios';
import ImgCrop from 'antd-img-crop';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import * as Icon from '@ant-design/icons';
import { SERVER_URL } from '../../../../../config';

interface IValues {
    avatar: string;
    firstName: string;
    lastName: string;
    email: string;
    roleId: string;
    facebook: string;
    twitter: string;
    telegram: string;
    linkedin: string;
    password: string;
}

const key = 'updatable';

export const UserCreate: FC = () => {
    const dispatch = useAppDispatch();
    const navigation = useNavigate();
    const { roles } = useAppSelector((state) => state.usersRole);
    const { users } = useAppSelector((state) => state.users);
    const [isLoading, setLoading] = useState(false);

    // AVATAR
    const [previewOpen, setPreviewOpen] = useState(false);
    const [fileAvatar, setAvatarList] = useState<UploadFile[]>([]);
    const isAvarat = fileAvatar[0]?.response?.url ? SERVER_URL + fileAvatar[0]?.response?.url : '';

    const onChangeAvatar: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
        try {
            setAvatarList(newFileList);
        } catch (e) {
            console.log('Error upload image', e);
        }
    };
    // ./AVATAR

    const userValid = (userEmail: string) => {
        const isUser = users.find((user) => user.email === userEmail);
        return isUser;
    };

    const fetchCreateUser = async (values: IValues) => {
        if (userValid(values.email)) {
            return message.error({ content: 'Такий користувач вже зареєстрований!', key });
        }
        setLoading(true);
        message.loading({ content: 'Завантаження...', key });
        try {
            const { data } = await axios.post('/api/user', { avatarUrl: isAvarat, ...values });

            if (data.token) {
                dispatch(createUser(data));
                setLoading(false);
                navigation('/users');
                message.success({ content: 'Користувач створений!', key, duration: 2 });
                return data;
            } else {
                message.error({ content: `Помилка! ${data?.message}`, key, duration: 2 });
                setLoading(false);
                return;
            }
        } catch (e) {
            console.log('error =>', e);
            message.error({ content: 'Помилка!', key, duration: 2 });
            setLoading(false);
            return;
        }
    };

    return (
        <Layout className="layout">
            <Breadcrumb className="Breadcrumb-custome">
                <Breadcrumb.Item>
                    <Button icon={<Icon.LeftOutlined />} type={'primary'} style={{ marginRight: 10 }} onClick={() => navigation('/users')} />
                    Новий користувач
                </Breadcrumb.Item>
            </Breadcrumb>
            <Content className="site-layout-background user_page__content user_page__main main_content" style={{ border: 'none' }}>
                <Form name="basic" initialValues={{ remember: true }} onFinish={fetchCreateUser} size="middle" autoComplete="off">
                    <Row gutter={[16, 24]}>
                        {/* Avarat */}
                        <Col className="gutter-row" span={24}>
                            <Row gutter={[16, 24]}>
                                <Col className="gutter-row">
                                    <ImgCrop quality={1} modalTitle="Avatar" modalOk="Save">
                                        <Upload
                                            accept="jpeg jpg png"
                                            action={`${SERVER_URL}/upload`}
                                            listType="picture-card"
                                            fileList={fileAvatar}
                                            onChange={onChangeAvatar}
                                            onPreview={() => setPreviewOpen(true)}>
                                            {fileAvatar.length < 1 && <Icon.CloudUploadOutlined style={{ fontSize: 30 }} />}
                                        </Upload>
                                    </ImgCrop>
                                    <Modal open={previewOpen} title="avatar" footer={null} onCancel={() => setPreviewOpen(false)}>
                                        <img alt="example" style={{ width: '100%' }} src={SERVER_URL + fileAvatar[0]?.response?.url} />
                                    </Modal>
                                </Col>
                                <Col>Доволені JPG і PNG. Максимальний розмір 10мб</Col>
                            </Row>
                        </Col>

                        <Col className="gutter-row" span={24}>
                            <div style={{ marginBottom: 10 }}>Основна інформація</div>
                            <Row gutter={[16, 16]}>
                                {/* First Name */}
                                <Col className="gutter-row" span={24} md={{ span: 12 }}>
                                    <Form.Item
                                        name="firstName"
                                        hasFeedback
                                        rules={[
                                            {
                                                type: 'string',
                                                required: true,
                                                message: "Будь ласка, введіть ім'я користувача!",
                                            },
                                            {
                                                type: 'string',
                                                pattern: /^[^\s][a-zA-Zа-яА-Я\s]*$/,
                                                message: "Будь ласка, вкажіть коректне ім'я!",
                                            },
                                            {
                                                type: 'string',
                                                min: 2,
                                                message: 'Мінімальна довжина 2 символи!',
                                            },
                                        ]}>
                                        <Input placeholder="Ім'я" prefix={<Icon.UserOutlined style={{ color: '#71727b' }} />} allowClear />
                                    </Form.Item>
                                </Col>

                                {/* Last Name */}
                                <Col className="gutter-row" span={24} md={{ span: 12 }}>
                                    <Form.Item
                                        name="lastName"
                                        hasFeedback
                                        rules={[
                                            {
                                                type: 'string',
                                                required: true,
                                                message: 'Будь ласка, введіть фамілію користувача!',
                                            },
                                            {
                                                type: 'string',
                                                pattern: /^[^\s][a-zA-Zа-яА-Я\s]*$/,
                                                message: 'Будь ласка, вкажіть коректну фамілію!',
                                            },
                                            {
                                                type: 'string',
                                                min: 2,
                                                message: 'Мінімальна довжина 2 символи!',
                                            },
                                        ]}>
                                        <Input placeholder="Фамілія" prefix={<Icon.UserOutlined style={{ color: '#71727b' }} />} allowClear />
                                    </Form.Item>
                                </Col>

                                {/* E-Mail */}
                                <Col className="gutter-row" span={24} md={{ span: 12 }}>
                                    <Form.Item
                                        name="email"
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Будь ласка, введіть E-Mail користувача!',
                                            },
                                            {
                                                type: 'email',
                                                message: 'Будь ласка, введіть вірний E-mail',
                                            },
                                        ]}>
                                        <Input placeholder="E-Mail" prefix={<Icon.MailOutlined style={{ color: '#71727b' }} />} allowClear />
                                    </Form.Item>
                                </Col>

                                {/* Role */}
                                <Col className="gutter-row" span={24} md={{ span: 12 }}>
                                    <Form.Item
                                        name="roleId"
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Будь ласка, виберіть посаду для користувача!',
                                            },
                                        ]}>
                                        <Select
                                            showSearch
                                            placeholder="Вибрати посаду..."
                                            optionFilterProp="children"
                                            optionLabelProp="label"
                                            loading={!roles}
                                            filterOption={(input, option: any) =>
                                                option.children.props.text.toLowerCase().includes(input.toLowerCase())
                                            }>
                                            {roles.map((role) => (
                                                <Select.Option key={role._id} value={role._id} label={<Badge color={role.color} text={role.title} />}>
                                                    <Badge color={role.color} text={role.title} />
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div style={{ marginBottom: 10, marginTop: 20 }}>Паролі</div>
                            <Row gutter={[16, 16]}>
                                {/* Password */}
                                <Col className="gutter-row" span={24} md={{ span: 12 }}>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Будь ласка, введіть пароль для користувача!',
                                            },
                                            {
                                                type: 'string',
                                                min: 5,
                                                message: 'Пароль повинен бути не менше ніж 5 символів!',
                                            },
                                        ]}>
                                        <Input.Password
                                            placeholder="Пароль"
                                            prefix={<Icon.UnlockOutlined style={{ color: '#71727b' }} />}
                                            allowClear
                                            iconRender={(visible) => (visible ? <Icon.EyeTwoTone /> : <Icon.EyeInvisibleOutlined />)}
                                        />
                                    </Form.Item>
                                </Col>

                                {/* Confirm Password */}
                                <Col className="gutter-row" span={24} md={{ span: 12 }}>
                                    <Form.Item
                                        name="confirm_password"
                                        dependencies={['password']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Будь ласка, повторіть пароль користувача!',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Паролі не співпадають!'));
                                                },
                                            }),
                                        ]}>
                                        <Input.Password
                                            placeholder="Підтвердити пароль"
                                            prefix={<Icon.UnlockOutlined style={{ color: '#71727b' }} />}
                                            allowClear
                                            iconRender={(visible) => (visible ? <Icon.EyeTwoTone /> : <Icon.EyeInvisibleOutlined />)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="gutter-row" span={24} md={{ span: 12 }}>
                            <Button htmlType="submit" icon={<Icon.PlusOutlined />} loading={isLoading} type="primary">
                                Створити
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Content>
        </Layout>
    );
};
