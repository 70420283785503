import { HomeOutlined, PictureOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, Layout, Tabs, UploadFile, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { FC, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductContent } from './Product.content';
import { ProductGallery } from './Product.gallery';
import { IProductsValueForm } from '../../../../../types';
import axios from '../../../../../axios';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/useRedux';
import * as Product from '../../../../../store/products/products.slice';
import { SERVER_URL } from '../../../../../config';
import { UploadProps } from 'antd/lib';

const key = 'creatable';

export const ProductCreate: FC = () => {
    const navigation = useNavigate();
    const [form] = Form.useForm();
    const inputRef = useRef(null);
    const dispatch = useAppDispatch();
    const { id: PRODUCT_PAGE_ID } = useParams();
    const { ProductsData, isLoading } = useAppSelector((state) => state.products);
    const PRODUCT = ProductsData.find((product) => product._id === PRODUCT_PAGE_ID);
    const [isLoadingForm, setLoadingForm] = useState(false);

    // ====== Image =======
    const [fileImage, setImageList] = useState<UploadFile[]>([]);

    const IMAGES = fileImage.map((el) => ({
        filename: el?.response?.data.filename,
        originalname: el?.response?.data.originalname,
        path: SERVER_URL + el?.response?.url,
        size: el?.response?.data.size,
        // @ts-ignore
        color: el?.color,
    }));

    const onChangeImage: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
        try {
            setImageList(newFileList);
        } catch (e) {
            console.log('Помилка в завантажені зображення!', e);
        }
    };
    // ====== ./Image ======

    // ====== Gallery =======

    // @ts-ignore
    const [fileGallery, setGalleryList] = useState<UploadFile[]>([]);
    const GALLERY = fileGallery?.map((el) => {
        if (el.response) {
            return {
                filename: el.response.data.filename,
                originalname: el.response.data.originalname,
                path: SERVER_URL + el.response.url,
                size: el.response.data.size,
                color: el.response.data.color,
            };
        } else {
            return {
                filename: el.uid,
                originalname: el.name,
                path: el.url,
                size: el.size,
                // @ts-ignore
                color: el.color,
            };
        }
    });

    const onChangeUpdateName = (name: string, new_name: string, color: string) => {
        const arr = GALLERY.map((el) => (el.originalname === name ? { ...el, originalname: new_name, color } : el));

        const galleryUpdate = arr.map((el: any) => {
            if (el.response) {
                return {
                    uid: el.response.data.filename,
                    name: el.response.data.originalname,
                    status: 'done',
                    url: SERVER_URL + el.response.url,
                    size: el.response.data.size,
                    color: el.response.data.color,
                };
            } else {
                return {
                    uid: el.filename,
                    name: el.originalname,
                    status: 'done',
                    url: el.path,
                    size: el.size,
                    color: el.color,
                };
            }
        });
        // @ts-ignore
        setGalleryList(galleryUpdate);
    };

    const onChangeGallery: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
        try {
            setGalleryList(newFileList);
        } catch (e) {
            console.log('Помилка в завантажені зображення!', e);
        }
    };
    // ====== ./Gallery ======

    const fetchCreate = async (values: IProductsValueForm) => {
        setLoadingForm(true);
        message.loading({ content: 'Завантаження...', key });

        const hexColors = values.colors.map((item) => {
            if (typeof item.color !== 'string') {
                return { ...item, color: item.color.toHexString() };
            } else {
                return item;
            }
        });

        console.log('object', values);

        try {
            const { data } = await axios.post(`/api/product/`, {
                ...values,
                colors: hexColors,
                images: IMAGES || [],
                gallery: GALLERY || [],
            });
            if (data.success) {
                dispatch(Product.create(data.data));
                message.success({ content: 'Збережено!', key, duration: 2 });
                setLoadingForm(false);
                form.resetFields();
                navigation('/products');
            } else {
                setLoadingForm(false);
                message.error({ content: 'Сталась помилка!', key, duration: 2 });
            }
        } catch (e) {
            setLoadingForm(false);
            message.error({ content: 'Сталась помилка!', key, duration: 2 });
            return;
        }
    };

    if (isLoading) return <span>Loading...</span>;

    return (
        <Layout>
            <Content>
                <Form
                    initialValues={{ remember: true }}
                    ref={inputRef}
                    form={form}
                    onFinish={fetchCreate}
                    onFinishFailed={(error) => {
                        !!error && message.error({ content: "Деякі поля не заповнені. Перевірте, будь ласка, всі обов'язкові поля", duration: 4 });
                    }}
                    size="middle"
                    layout="vertical"
                    autoComplete="off">
                    <Tabs
                        size="small"
                        tabPosition="top"
                        tabBarExtraContent={
                            <Button type="primary" onClick={() => form.submit()} loading={isLoadingForm} icon={<SaveOutlined />}>
                                Зберегти
                            </Button>
                        }
                        type="card"
                        items={[
                            {
                                key: '1',
                                label: (
                                    <>
                                        <HomeOutlined /> Нова тканина
                                    </>
                                ),
                                children: <ProductContent fileImage={fileImage} onChangeImage={onChangeImage} form={form} />,
                            },
                            {
                                key: '2',
                                label: (
                                    <>
                                        <PictureOutlined /> Галерея
                                    </>
                                ),
                                children: (
                                    <ProductGallery
                                        onChangeGallery={onChangeGallery}
                                        fileGallery={fileGallery}
                                        onChangeUpdateName={onChangeUpdateName}
                                    />
                                ),
                            },
                        ]}></Tabs>
                </Form>
            </Content>
        </Layout>
    );
};
