import { FC, useState } from 'react';
import axios from '../../../axios';
import {
    Button,
    Col,
    Form,
    Input,
    message,
    Row,
    Tabs,
    Typography,
    Upload,
    UploadProps,
} from 'antd';
import { useAppDispatch } from '../../../store/hooks/useRedux';
import { IFieldUpdateProps } from '../../../types/index';
import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib';
import { SERVER_URL } from '../../../config';

interface IValue {
    name: string;
    title: string;
    video_url: string;
}

const key = 'update';

export const FieldVideoUpdate: FC<IFieldUpdateProps> = ({
    field,
    main_id,
    section_id,
    request_url,
    fieldUpdate,
}) => {
    const [isLoadingForm, setIsLoadingForm] = useState(false);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    // ====== Video =======
    // @ts-ignore
    const converterVideo = field.content.video.map((el) => ({
        uid: el.filename, // унікальний ідентифікатор
        name: el.originalname, // назва файлу
        status: 'done', // статус завантаження ("done" або інший)
        url: el.path, // URL для відображення зображення
        size: el.size, // розмір файлу
    }));

    const [fileVideo, setVideoList] = useState<UploadFile[]>(converterVideo);

    const VIDEO = fileVideo.map((el) => {
        if (el.response) {
            return {
                filename: el.response.data.filename,
                originalname: el.response.data.originalname,
                path: SERVER_URL + el.response.url,
                size: el.response.data.size,
            };
        } else {
            return {
                filename: el.uid,
                originalname: el.name,
                path: el.url,
                size: el.size,
            };
        }
    });

    const onChangeVideo: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
        try {
            setVideoList(newFileList);
        } catch (e) {
            console.log('Помилка в завантажені зображення!', e);
        }
    };
    // ====== ./Video ======

    const handleUpdateField = async ({ name, ...other }: IValue) => {
        setIsLoadingForm(true);
        message.loading({ content: 'Завантаження...', key });
        try {
            const updateField = {
                main_id,
                section_id,
                fieldId: field._id,
                information: {
                    field_type: 'Video',
                    field_name: name,
                },
                content: { ...field.content, video: VIDEO, ...other },
            };

            await axios.patch(request_url, updateField);

            dispatch(fieldUpdate(updateField));

            setIsLoadingForm(false);
            message.success({ content: 'Збережено!', key, duration: 2 });
        } catch (e) {
            setIsLoadingForm(false);
            message.error({ content: 'Помилка!', key, duration: 2 });
        }
    };

    return (
        <Form
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            onFinish={handleUpdateField}
            size="middle"
            layout="vertical"
            autoComplete="off">
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item
                        name="name"
                        label="Назва поля"
                        initialValue={field.field_name}
                        rules={[{ required: true, message: '' }]}>
                        <Input placeholder="Введіть назву поля" size="middle" />
                    </Form.Item>
                </Col>
            </Row>

            <hr style={{ border: '0.1px solid #00000014' }} />

            <div style={{ marginBottom: 5, marginTop: 10 }}>Контент</div>
            <Tabs
                className="tab-card-content-admin"
                size="small"
                tabPosition="top"
                defaultActiveKey="UA"
                type="card">
                <Tabs.TabPane tab={<span>🇺🇦 UA</span>} key="UA">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item name={['UA', 'title']} initialValue={field.content.UA.title}>
                                <Input placeholder="Текст" size="middle" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span>🇷🇺 RU</span>} key="RU">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item name={['RU', 'title']} initialValue={field.content.RU.title}>
                                <Input placeholder="Текст" size="middle" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Tabs.TabPane>
            </Tabs>
            <Row gutter={[10, 10]} style={{ marginBottom: 20 }}>
                <Col span={24}>
                    <div>
                        <Typography.Text>Відео</Typography.Text>
                    </div>
                    <Upload
                        accept="mp4"
                        action={`${SERVER_URL}/upload`}
                        listType="picture"
                        maxCount={1}
                        multiple
                        fileList={fileVideo}
                        onChange={onChangeVideo}>
                        {!fileVideo.length && (
                            <Button icon={<UploadOutlined />}>Завантажити Відео</Button>
                        )}
                    </Upload>
                </Col>
            </Row>
            <Button loading={isLoadingForm} type="primary" onClick={() => form.submit()}>
                <SaveOutlined /> Зберегти
            </Button>
        </Form>
    );
};
