import { FC, ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';
import { LoginPage, NotFoundPage, ResetPasswordPage } from '../views/NotAuthorized';

interface IRoutes {
    id: string;
    path: string;
    element: ReactElement;
}

const ROUTES: IRoutes[] = [
    {
        id: '1',
        path: '/',
        element: <LoginPage />,
    },
    {
        id: '2',
        path: '/reset-password',
        element: <ResetPasswordPage />,
    },

    {
        id: '3',
        path: '*',
        element: <NotFoundPage />,
    },
];

export const NoAuthorized: FC = () => (
    <Routes>
        {ROUTES.map((route) => (
            <Route key={route.id} {...route} />
        ))}
    </Routes>
);
