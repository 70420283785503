import { FC, useState } from 'react';
import axios from '../../../axios';
import {
    Button,
    Col,
    Form,
    Input,
    message,
    Modal,
    Row,
    Select,
    Typography,
    Upload,
    UploadFile,
} from 'antd';
import { useAppDispatch } from '../../../store/hooks/useRedux';
import { IFieldUpdateProps } from '../../../types/index';
import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { SERVER_URL } from '../../../config';
import { UploadProps } from 'antd/lib';

interface IValue {
    name: string;
}

const key = 'updatable';

export const FieldSliderUpdate: FC<IFieldUpdateProps> = ({
    field,
    main_id,
    section_id,
    request_url,
    fieldUpdate,
}) => {
    const [isLoadingForm, setIsLoadingForm] = useState(false);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    // ====== Gallery =======
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    // @ts-ignore
    const converterImageDesktop = field.content.image_desktop.map((el) => ({
        uid: el?.filename, // унікальний ідентифікатор
        name: el?.originalname, // назва файлу
        status: 'done', // статус завантаження ("done" або інший)
        url: el?.path, // URL для відображення зображення
        size: el?.size, // розмір файлу
    }));
    // @ts-ignore
    const converterImageMobile = field.content.image_mobile.map((el) => ({
        uid: el?.filename, // унікальний ідентифікатор
        name: el?.originalname, // назва файлу
        status: 'done', // статус завантаження ("done" або інший)
        url: el?.path, // URL для відображення зображення
        size: el?.size, // розмір файлу
    }));

    const [fileDesktop, setDesktopList] = useState<UploadFile[]>(converterImageDesktop);
    const [fileMobile, setMobileList] = useState<UploadFile[]>(converterImageMobile);

    const IMAGES_DESKTOP = fileDesktop.map((el) => {
        if (el.response) {
            return {
                filename: el.response.data.filename,
                originalname: el.response.data.originalname,
                path: SERVER_URL + el.response.url,
                size: el.response.data.size,
            };
        } else {
            return {
                filename: el.uid,
                originalname: el.name,
                path: el.url,
                size: el.size,
            };
        }
    });
    const IMAGES_MOBILE = fileMobile.map((el) => {
        if (el.response) {
            return {
                filename: el.response.data.filename,
                originalname: el.response.data.originalname,
                path: SERVER_URL + el.response.url,
                size: el.response.data.size,
            };
        } else {
            return {
                filename: el.uid,
                originalname: el.name,
                path: el.url,
                size: el.size,
            };
        }
    });

    const onChangeDesktop: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
        try {
            setDesktopList(newFileList);
        } catch (e) {
            console.log('Помилка в завантажені зображення!', e);
        }
    };
    const onChangeMobile: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
        try {
            setMobileList(newFileList);
        } catch (e) {
            console.log('Помилка в завантажені зображення!', e);
        }
    };

    const handlePreview = async ({ url }: { url: string }) => {
        setPreviewImage(url);
        setPreviewOpen(true);
    };
    // ====== ./Gallery ======

    const handleUpdateField = async ({ name, ...other }: IValue) => {
        setIsLoadingForm(true);
        message.loading({ content: 'Завантаження...', key });
        try {
            const updateField = {
                main_id,
                section_id,
                fieldId: field._id,
                information: {
                    field_type: 'Slider',
                    field_name: name,
                },
                content: {
                    ...field.content,
                    ...other,
                    image_desktop: IMAGES_DESKTOP,
                    image_mobile: IMAGES_MOBILE,
                },
            };
            await axios.patch(request_url, updateField);

            dispatch(fieldUpdate(updateField));

            setIsLoadingForm(false);
            message.success({ content: 'Збережено!', key, duration: 2 });
        } catch (e) {
            setIsLoadingForm(false);
            message.error({ content: 'Помилка!', key, duration: 2 });
        }
    };

    return (
        <Form
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            onFinish={handleUpdateField}
            size="middle"
            layout="vertical"
            autoComplete="off">
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item
                        name="name"
                        label="Назва поля"
                        initialValue={field?.field_name}
                        rules={[{ required: true, message: '' }]}>
                        <Input placeholder="Введіть назву поля" size="middle" />
                    </Form.Item>
                </Col>
            </Row>
            <hr style={{ border: '0.1px solid #00000014' }} />

            <div style={{ marginBottom: 5, marginTop: 10 }}>Контент</div>
            <Row gutter={[16, 16]}>
                <Col span={11}>
                    <Form.Item name="link" initialValue={field.content?.link}>
                        <Input addonBefore="URL" placeholder="Введіть посилання" size="middle" />
                    </Form.Item>
                </Col>
                <Col
                    span={2}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    АБО
                </Col>
                <Col span={11}>
                    <Form.Item name="modal" initialValue={field.content?.modal}>
                        <Select
                            allowClear
                            placeholder="Виберіть модальне вікно"
                            style={{ width: '100%' }}
                            options={[
                                {
                                    value: 'ZamerModal',
                                    label: 'Замовити замір',
                                },
                                {
                                    value: 'DiznatusVartistModal',
                                    label: 'Дізнатись вартість',
                                },
                            ]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Row gutter={[10, 10]}>
                        <Col span={24}>
                            <div>
                                <Typography.Text>Зображення для компютерів</Typography.Text>
                            </div>
                            <Upload
                                accept="jpeg jpg png"
                                action={`${SERVER_URL}/upload`}
                                listType="picture"
                                maxCount={1}
                                multiple
                                fileList={fileDesktop}
                                onChange={onChangeDesktop}
                                // @ts-ignore
                                onPreview={handlePreview}>
                                {!fileDesktop.length && (
                                    <Button icon={<UploadOutlined />}>
                                        Завантажити зображення
                                    </Button>
                                )}
                            </Upload>
                        </Col>
                        <Col span={24}>
                            <div>
                                <Typography.Text>Зображення для мобільних</Typography.Text>
                            </div>
                            <Upload
                                accept="jpeg jpg png"
                                action={`${SERVER_URL}/upload`}
                                listType="picture"
                                maxCount={1}
                                multiple
                                fileList={fileMobile}
                                onChange={onChangeMobile}
                                // @ts-ignore
                                onPreview={handlePreview}>
                                {!fileMobile.length && (
                                    <Button icon={<UploadOutlined />}>
                                        Завантажити зображення
                                    </Button>
                                )}
                            </Upload>
                        </Col>
                        <Modal
                            open={previewOpen}
                            footer={null}
                            onCancel={() => setPreviewOpen(false)}>
                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                    </Row>
                </Col>
                <Col span={24}>
                    <Button loading={isLoadingForm} type="primary" onClick={() => form.submit()}>
                        <SaveOutlined /> Зберегти
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};
