import { FC, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Layout, Menu, MenuProps } from 'antd';
import { ROUT_PATH } from '../../router/Authorized.route';

import * as Icon from '@ant-design/icons';

import './Sider.scss';
import { IsAccess } from '../../components/IsAccess/IsAccess';

export const Sider: FC = () => {
    const { Sider } = Layout;
    const navigation = useNavigate();
    const { pathname } = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const isAccess = IsAccess();

    const MENU_ITEMS: MenuProps['items'] = [
        {
            key: ROUT_PATH.HOME,
            icon: <Icon.DesktopOutlined />,
            label: 'Головна',
            onClick: () => navigation(ROUT_PATH.HOME),
        },
        {
            key: ROUT_PATH.ORDERS,
            icon: <Icon.AuditOutlined />,
            label: 'Заявки',
            onClick: () => navigation(ROUT_PATH.ORDERS),
        },
        {
            key: ROUT_PATH.PRODUCTS,
            icon: <Icon.ScanOutlined />,
            label: 'Тканини',
            onClick: () => navigation(ROUT_PATH.PRODUCTS),
        },
        {
            key: ROUT_PATH.TEMPLATES,
            icon: <Icon.FolderOutlined />,
            label: 'Шаблони',
            onClick: () => navigation(ROUT_PATH.TEMPLATES),
        },
        // {
        //     key: ROUT_PATH.TELEGRAM_BOT,
        //     icon: <Icon.SendOutlined />,
        //     label: 'Telegram Bot',
        //     onClick: () => navigation(ROUT_PATH.TELEGRAM_BOT),
        // },
        // {
        //     key: ROUT_PATH.VIBER_BOT,
        //     icon: <Icon.WhatsAppOutlined />,
        //     label: 'Viber Bot',
        //     onClick: () => navigation(ROUT_PATH.VIBER_BOT),
        // },
        {
            key: ROUT_PATH.USERS,
            icon: <Icon.TeamOutlined />,
            label: 'Користувачі',
            onClick: () => navigation(ROUT_PATH.USERS),
        },

        isAccess?.is_setting
            ? {
                  type: 'divider',
              }
            : null,
        isAccess?.is_setting
            ? {
                  key: ROUT_PATH.SETTINGS,
                  icon: <Icon.SettingOutlined />,
                  label: 'Налаштування',
                  onClick: () => navigation(ROUT_PATH.SETTINGS),
              }
            : null,
    ];

    const ActivePage = () => {
        const path_name_split = pathname.split('/');

        const selectedPage = path_name_split.length > 1 ? (path_name_split[1] === 'website' ? '/' : path_name_split[1]) : '/';

        const activePage = MENU_ITEMS.find((page) => page?.key?.toString().includes(selectedPage))?.key;

        return [String(activePage)];
    };

    return (
        <Sider
            theme="light"
            breakpoint={'xl'}
            collapsedWidth={50}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            className="site-layout-background">
            <Menu mode="inline" selectedKeys={ActivePage()} items={MENU_ITEMS} />
        </Sider>
    );
};
