import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../../axios';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/useRedux';
import { Badge, Col, Descriptions, message, Row, Select, Typography } from 'antd';
import * as Order from '../../../../store/orders/orders.slice';
import { OrderDelete } from './Order.delete';
import { ConverterDate } from '../../../../helpers/ConverterDate';
import * as Icon from '@ant-design/icons';
import { IsAccess } from '../../../../components/IsAccess/IsAccess';

const key = 'updatable';

export const OrderEdit: FC = () => {
    const dispatch = useAppDispatch();
    const { id: ORDER_PAGE_ID } = useParams();
    const { OrdersData } = useAppSelector((state) => state.orders);
    const ORDER = OrdersData.find((order) => order._id === ORDER_PAGE_ID);
    const OrderSettings = useAppSelector((state) => state.ordersSettings);
    const isAccess = IsAccess();

    const [editablefullName, setEditablefullName] = useState(ORDER?.fullName);
    const [editablePhone, setEditablePhone] = useState(ORDER?.phone);
    const [editableService, setEditableService] = useState(ORDER?.service);
    const [editableNote, setEditableNote] = useState(ORDER?.note);
    const [editableAddress, setEditableAddress] = useState(ORDER?.address);
    const [editableStatus, setEditableStatus] = useState(ORDER?.status);
    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        if (firstRender) setFirstRender(false);
        else fetchUpdate();
    }, [editablefullName, editablePhone, editableService, editableNote, editableAddress, editableStatus]);

    const fetchUpdate = async () => {
        message.loading({ content: 'Завантаження...', key });
        try {
            const { data } = await axios.patch(`/api/order/${ORDER_PAGE_ID}`, {
                fullName: editablefullName,
                phone: editablePhone,
                service: editableService,
                address: editableAddress,
                status: editableStatus,
                note: editableNote,
            });

            dispatch(Order.update(data.data));
            message.success({ content: 'Збережено!', key, duration: 2 });

            return;
        } catch (e) {
            message.error({ content: 'Сталась помилка!', key, duration: 2 });
            return;
        }
    };

    const isWhence = () => {
        const TITLES = [
            {
                key: 'landing',
                name: 'Лендінг',
                icon: <Icon.LaptopOutlined style={{ color: '#FF9800' }} />,
            },
            {
                key: 'telegram',
                name: 'Телеграм',
                icon: <Icon.SendOutlined style={{ color: '#2196F3' }} />,
            },
            {
                key: 'viber',
                name: 'Вайбер',
                icon: <Icon.WhatsAppOutlined style={{ color: '#a470ff' }} />,
            },
            {
                key: 'admin',
                name: 'Адмін панель',
                icon: <Icon.SafetyCertificateOutlined style={{ color: '#4CAF50' }} />,
            },
        ];

        const result = TITLES.find((el) => el.key === ORDER?.whence);
        return (
            <span>
                {result?.icon} {result?.name}
            </span>
        );
    };

    isWhence();

    if (!ORDER) {
        return <h1>Помилка. Ми не знайшли замовлення, можливо воно було видаленно.</h1>;
    }

    return (
        <Row gutter={[16, 24]}>
            <Col span={24} style={{ height: 'calc(100vh - 240px)', overflow: 'scroll' }}>
                <Descriptions
                    title={
                        <Row align="middle" justify="space-between">
                            <Col>{ConverterDate(ORDER?.createdAt || '')}</Col>
                            <Col>
                                <OrderDelete OrderId={ORDER?._id} buttonText="Видалити" danger={true} type="default" />
                            </Col>
                        </Row>
                    }
                    layout="vertical"
                    bordered>
                    <Descriptions.Item label="Ім'я">
                        <Typography.Paragraph
                            editable={
                                isAccess?.orders.edit && {
                                    onChange: setEditablefullName,
                                }
                            }>
                            {editablefullName}
                        </Typography.Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="Телефон">
                        <Typography.Paragraph copyable editable={isAccess?.orders.edit && { onChange: setEditablePhone }}>
                            {editablePhone}
                        </Typography.Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="Адреса">
                        <Typography.Paragraph copyable editable={isAccess?.orders.edit && { onChange: setEditableAddress }}>
                            {editableAddress}
                        </Typography.Paragraph>
                    </Descriptions.Item>

                    <Descriptions.Item label="Послуга" span={2}>
                        <Descriptions.Item label="Звідкри замовлення">
                            <Typography.Paragraph
                                editable={
                                    isAccess?.orders.edit && {
                                        onChange: setEditableService,
                                    }
                                }>
                                {editableService}
                            </Typography.Paragraph>
                        </Descriptions.Item>
                    </Descriptions.Item>
                    <Descriptions.Item label="Звідки замовлення">{isWhence()}</Descriptions.Item>

                    <Descriptions.Item label="Статус замовлення">
                        <Select
                            disabled={!isAccess?.orders.edit}
                            defaultValue={editableStatus}
                            style={{ width: '100%' }}
                            onChange={setEditableStatus}>
                            {OrderSettings.orderSettingsData[0]?.status.map((status) => (
                                <Select.Option value={status._id}>
                                    <Badge color={status.color} text={status.title} />
                                </Select.Option>
                            ))}
                        </Select>
                    </Descriptions.Item>

                    <Descriptions.Item label="Додаткова інформація">
                        <Typography.Paragraph editable={isAccess?.orders.edit && { onChange: setEditableNote }}>{editableNote}</Typography.Paragraph>
                    </Descriptions.Item>
                </Descriptions>
            </Col>
        </Row>
    );
};
