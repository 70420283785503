import { FC, useMemo } from 'react';
import { IFields } from '../../types';

import { FieldCodeUpdate } from './Field.code/Field.code.update';
import { FieldTestimonialUpdate } from './Field.testimonial/Field.testimonial.update';
import { FieldFaqUpdate } from './Field.faq/Field.faq.update';
import { FieldImageUpdate } from './Field.image/Field.image.update';
import { FieldLinksUpdate } from './Field.links/Field.links.update';
import { FieldListUpdate } from './Field.list/Field.list.update';
import { FieldButtonUpdate } from './Field.button/Field.button.update';
import { FieldFeaturesUpdate } from './Field.features/Field.features.update';
import { FieldRichTextUpdate } from './Field.rich_text/Field.rich_text.update';
import { FieldTextUpdate } from './Field.text/Field.text.update';
import { FieldVideoUpdate } from './Field.video/Field.video.update';
import { FieldStepUpdate } from './Field.step/Field.step.update';
import { FieldSliderUpdate } from './Field.slider/Field.slider.update';

interface IProps {
    mainId: string;
    sectionId: string;
    field: IFields;
    request_url: string;
    fieldUpdate: any;
    fieldUpdateLanding?: any;
    isTemplate?: boolean;
}

export const FieldUpdate: FC<IProps> = ({ mainId, sectionId, field, ...other }) => {
    const params = useMemo(
        () => ({
            main_id: mainId,
            section_id: sectionId,
            field,
            ...other,
        }),
        [mainId, sectionId, field, other],
    );

    switch (field.field_type) {
        case 'Text':
            return <FieldTextUpdate {...params} />;

        case 'RichText':
            return <FieldRichTextUpdate {...params} />;

        case 'Image':
            return <FieldImageUpdate {...params} />;

        case 'Links':
            return <FieldLinksUpdate {...params} />;

        case 'Button':
            return <FieldButtonUpdate {...params} />;

        case 'Features':
            return <FieldFeaturesUpdate {...params} />;

        case 'List':
            return <FieldListUpdate {...params} />;

        case 'Step':
            return <FieldStepUpdate {...params} />;

        case 'Testimonial':
            return <FieldTestimonialUpdate {...params} />;

        case 'Question':
            return <FieldFaqUpdate {...params} />;

        case 'Video':
            return <FieldVideoUpdate {...params} />;

        case 'Code':
            return <FieldCodeUpdate {...params} />;

        case 'Slider':
            return <FieldSliderUpdate {...params} />;

        default:
            return null;
    }
};
