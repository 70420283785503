import { FC, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../../../axios';
import { Col, message, Row, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/useRedux';
import { Reorder } from 'framer-motion';
import { dragAndDropSection } from '../../../../../store/templates/templates.slice';
import { EmptyCustome } from '../../../../../components/EmptyCustome/EmptyCustome';
import { TemplateSectionsItem } from './Template.sections.item';

export const TemplatesSections: FC = () => {
    const { id: TEMPLATE_PAGE_ID } = useParams();
    const { TemplatesData, isLoading } = useAppSelector((state) => state.templates);
    const TEMPLATE = TemplatesData?.find((template) => template._id === TEMPLATE_PAGE_ID);
    const [useDATA, setDATA] = useState(TEMPLATE?.sections);
    const [activeKey, setActiveKey] = useState<React.Key[]>([]);
    const isNestedDraggingRef = useRef(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setDATA(TEMPLATE?.sections);
    }, [TEMPLATE?.sections]);

    const savePosition = async () => {
        if (!isNestedDraggingRef.current) {
            try {
                const { data } = await axios.patch('/api/template/section/position', {
                    template_id: TEMPLATE_PAGE_ID,
                    sections: useDATA,
                });

                if (data.success) {
                    dispatch(
                        dragAndDropSection({
                            template_id: TEMPLATE_PAGE_ID,
                            sections: useDATA,
                        }),
                    );
                }
            } catch (e) {
                message.error({ content: 'Позиція не збереглась!', duration: 2 });
            }
        }
        isNestedDraggingRef.current = false;
    };

    const handleClick = (key: React.Key) => {
        if (activeKey.includes(key)) {
            setActiveKey(activeKey.filter((k) => k !== key));
        } else {
            setActiveKey([...activeKey, key]);
        }
    };

    if (!TEMPLATE) {
        return <h1>Сторінка не знайдена!</h1>;
    }

    return (
        <Row gutter={[10, 10]}>
            <Col span={24} style={{ height: 'calc(100vh - 210px)' }}>
                <Row style={{ overflow: 'scroll', height: ' 100%' }}>
                    <EmptyCustome>
                        <Reorder.Group
                            axis="y"
                            as="div"
                            style={{ width: '100%' }}
                            values={TEMPLATE?.sections}
                            onPanEnd={savePosition}
                            onReorder={(sections) => setDATA(sections)}>
                            {!!useDATA?.length ? (
                                useDATA.map((section) => (
                                    <TemplateSectionsItem
                                        key={section._id}
                                        section={section}
                                        sectionSpollerKey={activeKey}
                                        sectionSpollerHandle={handleClick}
                                        TEMPLATE_PAGE_ID={TEMPLATE_PAGE_ID}
                                        isNestedDraggingRef={isNestedDraggingRef}
                                    />
                                ))
                            ) : (
                                <Typography.Text type="secondary" style={{ fontSize: 12, width: '100%' }}>
                                    У вас ще немає блоків.
                                </Typography.Text>
                            )}
                        </Reorder.Group>
                    </EmptyCustome>
                </Row>
            </Col>
        </Row>
    );
};
