import { FC } from 'react';
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';

// STYLE
// import './OfferOwnerPage.scss';

export const ViberBotPage: FC = () => {
    return (
        <Layout className="content-layout">
            <div className="content-header">
                <span>Viber Bot</span>
            </div>
            <Content className="site-layout-background main_content main_section">Hello</Content>
        </Layout>
    );
};
