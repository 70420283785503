import { FC, useEffect, useState } from 'react';
import { Col, Empty, Input, Row, Space } from 'antd';
import axios from '../../../../../axios';
import { useAppSelector } from '../../../../../store/hooks/useRedux';
import { AnimatePresence, Reorder } from 'framer-motion';
import { RoleCreate } from './Role.create/Role.create';
import { RoleEdit } from './Role.edit/Role.edit';
import { RoleDelete } from './Role.delete/Role.delete';
import { SearchOutlined } from '@ant-design/icons';
import './Roles.scss';

export const Roles: FC = () => {
    const { roles } = useAppSelector((state) => state.usersRole);

    const [useRoles, setRoles] = useState(roles);

    useEffect(() => {
        setRoles(roles);
    }, [roles]);

    //TODO: Зробити збереження після перетягування!
    const savePosition = async () => {
        console.log('Save position ROLE');
        try {
            // await axios.patch('/api/roles/new/positions', useRoles);
            // dispatch(
            //     dragAndDrop({
            //         landing_id: LANDING_ID,
            //         section_id: SECTION_ID,
            //         fields: useFields,
            //     }),
            // );
        } catch (e) {
            console.log('Error position fields', e);
        }
    };

    const handleSearch = (value: string) => {
        const filter = roles.filter((role) => role.title.toLowerCase().includes(value));
        setRoles(!!value.length ? filter : roles);
    };

    return (
        <Row gutter={[16, 15]}>
            <Col span={24}>
                <Row gutter={[16, 24]} justify="space-between" align="middle">
                    <Col span={24} style={{ display: 'flex' }}>
                        <Input
                            style={{ marginRight: 10 }}
                            placeholder="Пошук"
                            suffix={<SearchOutlined style={{ color: '#71727b' }} />}
                            allowClear
                            onChange={(input) => handleSearch(input.target.value)}
                        />
                        <RoleCreate />
                    </Col>
                </Row>
            </Col>

            {!useRoles.length && (
                <Empty
                    className="empty-custom"
                    style={{ width: '100%' }}
                    imageStyle={{
                        height: 70,
                    }}
                    description={false}
                />
            )}

            <Col span={24} style={{ height: 'calc(100vh - 240px)', overflow: 'scroll' }}>
                <Row gutter={[16, 5]}>
                    <Reorder.Group
                        axis="y"
                        as="div"
                        style={{ width: '100%' }}
                        values={useRoles}
                        onPanEnd={() => savePosition()}
                        onReorder={(roles) => setRoles(roles)}>
                        <AnimatePresence>
                            {useRoles.map((el) => (
                                <Reorder.Item
                                    key={el._id}
                                    value={el}
                                    whileDrag={{
                                        scale: 1.01,
                                        zIndex: 1,
                                        position: 'relative',
                                    }}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{
                                        opacity: 0,
                                    }}>
                                    <Col span={24} key={el._id} style={{ marginBottom: 5, cursor: 'move' }}>
                                        <Row className="settings_users_form__row" justify="space-between">
                                            <Row align="middle">
                                                <Col>
                                                    <span
                                                        className={'settings_users_form__dot'}
                                                        style={{
                                                            backgroundColor: el.color,
                                                        }}></span>
                                                </Col>
                                                <Col>{el.title}</Col>
                                            </Row>
                                            <Row gutter={3}>
                                                <Space size="small">
                                                    <RoleEdit roleId={el._id} />
                                                    <RoleDelete roleId={el._id} />
                                                </Space>
                                            </Row>
                                        </Row>
                                    </Col>
                                </Reorder.Item>
                            ))}
                        </AnimatePresence>
                    </Reorder.Group>
                </Row>
            </Col>
        </Row>
    );
};
