import { FC, useState } from 'react';
import { Button, Input, Modal, Tooltip } from 'antd';
import { SERVER_URL } from '../../config';
import { CheckOutlined, ClusterOutlined, CopyOutlined } from '@ant-design/icons';

interface IProps {
    url: string;
    type?: 'text' | 'primary';
    size?: 'small' | 'middle' | 'large';
    title?: string;
}

export const ModalAPI: FC<IProps> = ({ url, type, size = 'small', title }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCoppy, setCoppy] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const coppy = async () => {
        try {
            await navigator.clipboard.writeText(SERVER_URL + url);
            setCoppy(true);
            setTimeout(() => setCoppy(false), 5000);
        } catch (e) {
            console.log('Помилка копіювання!', e);
        }
    };

    return (
        <>
            <Button onClick={showModal} type={type} size={size}>
                <ClusterOutlined />
            </Button>

            <Modal title="API JSON" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Input.Group compact style={{ display: 'flex' }}>
                    <Input defaultValue={SERVER_URL + url} />
                    <Tooltip title="Копіювати">
                        <Button
                            icon={isCoppy ? <CheckOutlined /> : <CopyOutlined />}
                            onClick={coppy}
                        />
                    </Tooltip>
                </Input.Group>
            </Modal>
        </>
    );
};
