import { Typography } from 'antd';

export const ConverterDate = (inputDate: string) => {
    function formatDate() {
        const date = new Date(inputDate);
        const currentDate = new Date();

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        if (
            date.getDate() === currentDate.getDate() &&
            date.getMonth() === currentDate.getMonth() &&
            date.getFullYear() === currentDate.getFullYear()
        ) {
            return (
                <Typography.Text style={{ fontSize: 12 }}>
                    Сьогодні {hours}:{minutes}
                </Typography.Text>
            );
        } else if (
            date.getDate() === currentDate.getDate() - 1 &&
            date.getMonth() === currentDate.getMonth() &&
            date.getFullYear() === currentDate.getFullYear()
        ) {
            return (
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                    Вчора {hours}:{minutes}
                </Typography.Text>
            );
        } else {
            return (
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                    {day}.{month}.{year} {hours}:{minutes}
                </Typography.Text>
            );
        }
    }

    return formatDate();
};
