import { CSSProperties, FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../../../../../axios';
import * as TemplateSlice from '../../../../../store/templates/templates.slice';
import * as LandingSlice from '../../../../../store/orders/orders.slice';
import { Reorder } from 'framer-motion';
import { Button, Row, Space, Typography } from 'antd';
import { EmptyCustome } from '../../../../../components/EmptyCustome/EmptyCustome';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/useRedux';
import { FieldCreate } from '../../../../../components/Fields/Fields.create';
import { FullscreenOutlined } from '@ant-design/icons';
import { TemplateFieldItem } from './Template.field.item';

interface IProps {
    sectionId?: string;
    fullButton?: boolean;
    style?: CSSProperties;
    createFieldButtonName?: string;
    createFieldButtonSize?: 'small' | 'middle' | 'large';
    isNestedDraggingRef?: any;
}

export const TemplatesFields: FC<IProps> = ({ sectionId, fullButton, createFieldButtonName, createFieldButtonSize, isNestedDraggingRef }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [, , TEMPLATE_ID, PATH_SECTION_ID] = pathname.split('/');
    const SECTION_ID = sectionId || PATH_SECTION_ID;
    const Template = useAppSelector((state) => state.templates.TemplatesData).find((template) => template._id === TEMPLATE_ID);
    const Section = Template?.sections.find((field) => field._id === SECTION_ID);
    const [useFields, setFields] = useState(Section?.fields);
    const [activeKey, setActiveKey] = useState<React.Key[]>([]);

    useEffect(() => {
        setFields(Section?.fields);
    }, [Section?.fields]);

    const savePositionsField = async () => {
        if (isNestedDraggingRef) isNestedDraggingRef.current = true;
        try {
            const { data } = await axios.patch('/api/template/field/position', {
                template_id: TEMPLATE_ID,
                section_id: SECTION_ID,
                fields: useFields,
            });
            if (data.success) {
                dispatch(
                    TemplateSlice.dragAndDrop({
                        template_id: TEMPLATE_ID,
                        section_id: SECTION_ID,
                        fields: useFields,
                    }),
                );
            }
        } catch (e) {
            console.log('Помилка: позиція не збережена!', e);
        }
    };

    const handleClickField = (key: React.Key) => {
        if (activeKey.includes(key)) {
            setActiveKey(activeKey.filter((k) => k !== key));
        } else {
            setActiveKey([...activeKey, key]);
        }
    };

    return (
        <>
            <Row
                justify="space-between"
                align="middle"
                style={{
                    marginBottom: 10,
                    borderBottom: '1px solid #e0e0e0',
                    paddingBottom: 10,
                }}>
                <Typography.Text>
                    ПОЛІВ <strong>{Section?.fields.length}</strong>
                </Typography.Text>
                <Space>
                    <FieldCreate
                        mainId={TEMPLATE_ID}
                        sectionId={SECTION_ID}
                        request_url="/api/template/field/action"
                        fieldCreate={TemplateSlice.fieldCreate}
                        isTemplate={true}
                        size={createFieldButtonSize}
                        title={createFieldButtonName}
                    />
                    {fullButton && (
                        <Button size="small" onClick={() => navigate(`/template/${TEMPLATE_ID}/${SECTION_ID}`)} icon={<FullscreenOutlined />} />
                    )}
                </Space>
            </Row>
            <Row style={{ height: 'calc(100vh - 210px)' }}>
                <EmptyCustome>
                    <Reorder.Group
                        axis="y"
                        as="ul"
                        style={{
                            width: '100%',
                            listStyle: 'none',
                            padding: 0,
                            margin: 0,
                            overflow: 'scroll',
                            height: ' 100%',
                        }}
                        // @ts-ignore
                        values={useFields}
                        onPanEnd={savePositionsField}
                        onReorder={(fields) => setFields(fields)}>
                        {useFields?.length ? (
                            useFields?.map((field) => (
                                <TemplateFieldItem
                                    key={field._id}
                                    fieldItem={field}
                                    spolerKey={activeKey}
                                    spollerHandleClicks={handleClickField}
                                    TEMPLATE_ID={TEMPLATE_ID}
                                    SECTION_ID={SECTION_ID}
                                    TemplateSlice={TemplateSlice}
                                    LandingSlice={LandingSlice}
                                />
                            ))
                        ) : (
                            <Typography.Text type="secondary" style={{ fontSize: 12, width: '100%' }}>
                                У вас ще немає полів.
                            </Typography.Text>
                        )}
                    </Reorder.Group>
                </EmptyCustome>
            </Row>
        </>
    );
};
