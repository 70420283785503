import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Layout, Space, Button, Tag, Tooltip, Typography, Row, Col, Select, Badge, message } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/useRedux';
import { Content } from 'antd/lib/layout/layout';
import { OrderGroupUpdate } from '../OrdersFrom/Order.group.update';
import { EmptyCustome } from '../../../../components/EmptyCustome/EmptyCustome';
import { OrderDelete } from '../OrdersFrom/Order.delete';
import { ConverterDate } from '../../../../helpers/ConverterDate';
import { getOrders } from '../../../../store/orders/orders.slice';
import { OrderSettings } from '../OrdersFrom/Order.settings';
import type { ColumnsType } from 'antd/es/table';
import { IOrdersPage } from '../../../../types';
import { OrderCreate } from '../OrdersFrom/Order.create';
import * as Order from '../../../../store/orders/orders.slice';
import axios from '../../../../axios';
import * as Icon from '@ant-design/icons';
import { IsAccess } from '../../../../components/IsAccess/IsAccess';

const key = 'update';

export const OrdersPage: FC = () => {
    const [usePageSize, setPageSize] = useState(15);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isAccess = IsAccess();
    const { OrdersData, isLoading } = useAppSelector((state) => state.orders);
    const OrderSettingsStatus = useAppSelector((state) => state.ordersSettings);

    useEffect(() => {
        document.querySelectorAll('.ant-table-row').forEach((expandableRow) => {
            const templateKey = expandableRow.getAttribute('data-row-key');
            const order = OrdersData?.find((order) => order._id === templateKey);
            if (order && !order.note) {
                const expandButton = expandableRow.querySelector('.ant-table-row-expand-icon');
                if (expandButton instanceof HTMLButtonElement) {
                    expandButton.disabled = true;
                    expandButton.classList.add('button_disabled');
                }
            }
        });
    }, [OrdersData]);

    const fetchUpdateStatus = async (orderId: string, status: string) => {
        message.loading({ content: 'Завантаження...', key });
        try {
            await axios.patch(`/api/order/status/${orderId}`, { status });
            dispatch(Order.updateStatus({ orderId, status }));
            message.success({ content: 'Збережено!', key, duration: 2 });
            return;
        } catch (e) {
            message.error({ content: 'Сталась помилка!', key, duration: 2 });
            return;
        }
    };

    const whenceGet = (title: string) => {
        const style = {
            fontSize: 17,
        };
        if (title === 'landing') {
            return (
                <Tooltip placement="top" title="Заявка з лендінга">
                    <Icon.LaptopOutlined style={{ ...style, color: '#FF9800' }} />
                </Tooltip>
            );
        } else if (title === 'telegram') {
            return (
                <Tooltip placement="top" title="Заявка з телеграма">
                    <Icon.SendOutlined style={{ ...style, color: '#2196F3' }} />
                </Tooltip>
            );
        } else if (title === 'viber') {
            return (
                <Tooltip placement="top" title="Заявка з вайбера">
                    <Icon.WhatsAppOutlined style={{ ...style, color: '#a470ff' }} />
                </Tooltip>
            );
        } else if (title === 'admin') {
            return (
                <Tooltip placement="top" title="Заявка з адмін панелі">
                    <Icon.SafetyCertificateOutlined style={{ ...style, color: '#4CAF50' }} />
                </Tooltip>
            );
        }
    };

    const convertStatusIdToTitle = (orderId: any, idStatus: string) => {
        const status = OrderSettingsStatus.orderSettingsData[0]?.status.find((status) => status._id === idStatus);
        return status ? (
            <Select
                defaultValue={status?._id}
                style={{ width: '100%' }}
                onChange={(orderSelectedId) => fetchUpdateStatus(orderId, orderSelectedId)}
                disabled={!isAccess?.orders.edit}>
                {OrderSettingsStatus.orderSettingsData[0]?.status.map((status) => (
                    <Select.Option key={status._id} value={status?._id}>
                        <Badge color={status?.color} text={status?.title} />
                    </Select.Option>
                ))}
            </Select>
        ) : (
            <Tag color="grey" style={{ display: 'flex' }}></Tag>
        );
    };

    const Data = OrdersData.map((order) => ({
        key: order._id,
        fullName: (
            <Row>
                <Col span={24}>
                    <Link to={`/order/${order._id}`}>{order?.fullName}</Link>
                </Col>
                <Col>{ConverterDate(order.createdAt || '')}</Col>
            </Row>
        ),
        phone: order.phone,
        service: order.service,
        address: order.address,
        whence: whenceGet(order.whence),
        status: convertStatusIdToTitle(order._id, order.status),
        notes: order.note,
    }));

    const columns: ColumnsType<IOrdersPage> = [
        {
            // title: 'Звідки',
            dataIndex: 'whence',
            width: '3%',
            align: 'center',
        },
        {
            title: "Ім'я",
            dataIndex: 'fullName',
        },

        {
            title: 'Телефон',
            dataIndex: 'phone',
            filters: OrdersData.map((order) => ({
                text: order.phone,
                value: order._id,
            })),
            filterSearch: true,
            ellipsis: true,
            onFilter: (phone: string | any, orders: any) => orders.key.startsWith(phone),
        },
        {
            title: 'Послуга',
            dataIndex: 'service',
        },
        {
            title: 'Адреса',
            dataIndex: 'address',
            ellipsis: true,
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            width: '13rem',
            filters: OrderSettingsStatus.orderSettingsData[0]?.status.map((status) => ({
                text: status.title,
                value: status.title,
            })),
            filterSearch: true,
            onFilter: (status: string | any, dataStatus: any) => dataStatus.status.props.children.startsWith(status),
        },

        {
            title: 'Дія',
            key: 'action',
            width: '100px',
            fixed: 'right',
            className: 'offer__action',
            render: (_, order) => {
                return (
                    <Space size="small">
                        <Button
                            type="text"
                            icon={<Icon.EditOutlined />}
                            onClick={() => navigate(`/order/${order.key}`)}
                            disabled={!isAccess?.orders.edit}
                        />
                        <OrderDelete OrderId={order.key} />
                    </Space>
                );
            },
        },
    ];

    return (
        <Layout>
            <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
                <Col>
                    <Space>
                        <Typography.Title level={4} style={{ margin: 0 }}>
                            {' '}
                            Заявки
                        </Typography.Title>
                    </Space>
                </Col>
                <Col>
                    <Space>
                        <Button type="default" icon={<Icon.SyncOutlined />} onClick={() => dispatch(getOrders())} loading={isLoading} />
                        <OrderCreate />

                        <OrderSettings />
                    </Space>
                </Col>
            </Row>
            <Content className="site-layout-background main_content main_section">
                <EmptyCustome style={{ height: 'calc(100vh - 330px)' }}>
                    <Table
                        scroll={{ y: 'calc(100vh - 16rem)', x: 1200 }}
                        rowSelection={OrderGroupUpdate()}
                        loading={isLoading}
                        columns={columns}
                        dataSource={Data}
                        pagination={{
                            defaultPageSize: 15,
                            showSizeChanger: true,
                            className: 'table_pagination',
                            pageSize: usePageSize,
                            pageSizeOptions: [15, 20, 30, 40, 50, 100],
                            onShowSizeChange: (_, size) => setPageSize(size),
                        }}
                    />
                </EmptyCustome>
            </Content>
        </Layout>
    );
};
