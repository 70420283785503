import { FC, useState } from 'react';
import axios from '../../../axios';
import { Button, Col, Form, Input, message, Modal, Row, Upload } from 'antd';
import { useAppDispatch } from '../../../store/hooks/useRedux';
import type { UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { IFieldUpdateProps } from '../../../types';
import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { SERVER_URL } from '../../../config';

interface IValue {
    name: string;
    title: string;
    link: string;
}

const key = 'updatable';

export const FieldImageUpdate: FC<IFieldUpdateProps> = ({
    field,
    main_id,
    section_id,
    request_url,
    fieldUpdate,
}) => {
    const [isLoadingForm, setIsLoadingForm] = useState(false);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    // ====== Gallery =======
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    // @ts-ignore
    const converterImage = field.content.images.map((el) => ({
        uid: el.filename, // унікальний ідентифікатор
        name: el.originalname, // назва файлу
        status: 'done', // статус завантаження ("done" або інший)
        url: el.path, // URL для відображення зображення
        size: el.size, // розмір файлу
    }));

    const [fileAvatar, setAvatarList] = useState<UploadFile[]>(converterImage);

    const IMAGES = fileAvatar.map((el) => {
        if (el.response) {
            return {
                filename: el.response.data.filename,
                originalname: el.response.data.originalname,
                path: SERVER_URL + el.response.url,
                size: el.response.data.size,
            };
        } else {
            return {
                filename: el.uid,
                originalname: el.name,
                path: el.url,
                size: el.size,
            };
        }
    });

    const onChangeAvatar: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
        try {
            setAvatarList(newFileList);
        } catch (e) {
            console.log('Помилка в завантажені зображення!', e);
        }
    };

    const handlePreview = async ({ url }: { url: string }) => {
        setPreviewImage(url);
        setPreviewOpen(true);
    };
    // ====== ./Gallery ======

    const handleUpdateField = async (value: IValue) => {
        setIsLoadingForm(true);
        message.loading({ content: 'Завантаження...', key });
        try {
            const updateField = {
                main_id,
                section_id,
                fieldId: field._id,
                information: {
                    field_type: 'Image',
                    field_name: value.name,
                },
                content: { images: [...IMAGES] },
            };

            await axios.patch(request_url, updateField);

            dispatch(fieldUpdate(updateField));

            setIsLoadingForm(false);
            message.success({ content: 'Збережено!', key, duration: 2 });
        } catch (e) {
            setIsLoadingForm(false);
            message.error({ content: 'Помилка!', key, duration: 2 });
        }
    };

    return (
        <Form
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            onFinish={handleUpdateField}
            size="middle"
            layout="vertical"
            autoComplete="off">
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item
                        label="Назва поля"
                        name="name"
                        initialValue={field.field_name}
                        rules={[{ required: true, message: '' }]}>
                        <Input placeholder="Введіть назву поля" />
                    </Form.Item>
                </Col>
            </Row>
            <hr style={{ border: '0.1px solid #00000014' }} />

            <div style={{ marginBottom: 5, marginTop: 10 }}>Контент</div>
            <Upload
                accept="jpeg jpg png"
                action={`${SERVER_URL}/upload`}
                listType="picture-card"
                // maxCount={1}
                multiple
                fileList={fileAvatar}
                onChange={onChangeAvatar}
                // @ts-ignore
                onPreview={handlePreview}>
                <UploadOutlined />
            </Upload>
            <Modal open={previewOpen} footer={null} onCancel={() => setPreviewOpen(false)}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
            <Button loading={isLoadingForm} type="primary" onClick={() => form.submit()}>
                <SaveOutlined /> Зберегти
            </Button>
        </Form>
    );
};
