import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Col, Form, Input, message, Row, Typography } from 'antd';
import { IconLogin } from '../../../assets/images/svg/svg';
import { useAppDispatch } from '../../../store/hooks/useRedux';
import { fetchAuth } from '../../../store/auth/auth.slice';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

export const LoginPage: FC = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setLoading] = useState(false);

    const onFinishSuccess = async (values: any) => {
        setLoading(true);
        const data = await dispatch(fetchAuth(values));

        if (!data.payload) {
            setLoading(false);
            return message.error('Не вдалось авторизуватися!');
        }

        if ('token' in data.payload) {
            setLoading(false);
            return window.localStorage.setItem('token', data.payload.token);
        }

        if (data.meta.requestStatus === 'rejected') {
            setLoading(false);
            if (data.payload.message === 'Network Error') {
                return message.error('Помилка. Сервер не відповідає!');
            }
            return message.error(data.payload.response.data.message);
        }
        setLoading(false);
    };

    return (
        <section>
            <div className="auth">
                <div className="auth__wrapper">
                    <div className="auth__logo">
                        <IconLogin />
                    </div>
                    <div className="auth__form">
                        <Typography.Title level={4} className="auth__title">
                            Вхід
                        </Typography.Title>
                        <Form
                            name="basic"
                            wrapperCol={{ span: 24 }}
                            initialValues={{ remember: true }}
                            onFinish={onFinishSuccess}
                            size="middle"
                            autoComplete="off">
                            <Form.Item
                                className="auth__form-item"
                                name="email"
                                rules={[
                                    { required: true, message: 'Будь ласка, введіть ваш E-mail!' },
                                    {
                                        type: 'email',
                                        message: 'Будь ласка, введіть коректний E-mail!',
                                    },
                                ]}>
                                <Input prefix={<UserOutlined />} placeholder="Email" />
                            </Form.Item>

                            <Form.Item
                                className="auth__form-item"
                                name="password"
                                rules={[{ required: true, message: 'Будь ласка, введіть свій пароль!' }]}>
                                <Input.Password prefix={<LockOutlined />} placeholder="Пароль" />
                            </Form.Item>
                            <Row justify="space-between">
                                <Col>
                                    <Form.Item name="remember" valuePropName="checked">
                                        <Checkbox>Запамятати мене</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item>
                                        <Link to="/reset-password">Забули пароль?</Link>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item className="auth__button">
                                <Button loading={isLoading} type="primary" htmlType="submit" size="middle" style={{ width: '100%' }}>
                                    Увійти
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </section>
    );
};
