import { FC } from 'react';
import { Col, Layout, Row, Space, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { MyProfilePersonalInfo } from '../MyProfileForm/MyProfile.PersonalInfo/MyProfile.PersonalInfo';

export const MyProfilePage: FC = () => {
    return (
        <Layout className="content-layout">
            <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
                <Col>
                    <Space>
                        <Typography.Title level={4} style={{ margin: 0 }}>
                            Мій профіль
                        </Typography.Title>
                    </Space>
                </Col>
            </Row>
            <Content className="site-layout-background" style={{ border: 'none' }}>
                <MyProfilePersonalInfo />
            </Content>
        </Layout>
    );
};
