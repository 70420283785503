import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Layout, Modal, Dropdown, Menu, Space } from 'antd';
import { useAppDispatch, useAppSelector } from '../../store/hooks/useRedux';
import { Logo } from '../../components/Logo/Logo';
import { logout } from '../../store/auth/auth.slice';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { ExclamationCircleOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';

import './Header.scss';
import 'react-loading-skeleton/dist/skeleton.css';

export const Header: FC = () => {
    const { confirm } = Modal;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const myProfileId = useAppSelector((state) => state.auth.data?._id);
    const users = useAppSelector((state) => state.users.users);
    const myProfile = users.find((user) => user._id === myProfileId);

    const menu = (
        <Menu
            theme="light"
            items={[
                {
                    icon: <UserOutlined />,
                    label: <Link to="/my-profile">Мій профіль</Link>,
                    key: '0',
                },
                {
                    type: 'divider',
                },
                {
                    icon: <LogoutOutlined />,
                    label: 'Вийти',
                    onClick: () => showDeleteConfirm(),
                    key: '1',
                },
            ]}
        />
    );

    const showDeleteConfirm = () => {
        confirm({
            title: 'Ви дійсно хочете вийти?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Так',
            okType: 'danger',
            cancelText: 'Ні',
            onOk() {
                dispatch(logout());
                navigate('/');
                window.localStorage.removeItem('token');
            },
            onCancel() {
                console.log('Cancel клік по кнопкі вийти з аккаунту');
            },
        });
    };

    return (
        <Layout.Header className="header">
            <Logo iconClass={['logo__authorized-icon']} textClass={['logo__authorized-text']} />
            <div className="header__wrapper">
                <div className="header__user">
                    <Dropdown overlay={menu} trigger={['click']}>
                        <Space>
                            <span
                                className="header__fullname"
                                style={{ display: 'flex', alignItems: 'center' }}>
                                {!!myProfile ? (
                                    myProfile?.firstName + ' ' + myProfile?.lastName
                                ) : (
                                    <SkeletonTheme baseColor="#535663" highlightColor="#797979">
                                        <Skeleton width={70} />
                                    </SkeletonTheme>
                                )}
                            </span>
                            {!!myProfile ? (
                                <Avatar
                                    size={{ xs: 24, sm: 28 }}
                                    src={myProfile.avatarUrl}
                                    icon={<UserOutlined />}
                                />
                            ) : (
                                <SkeletonTheme baseColor="#535663" highlightColor="#797979">
                                    <Skeleton
                                        width={31}
                                        height={31}
                                        circle
                                        style={{ top: '-8px' }}
                                    />
                                </SkeletonTheme>
                            )}
                        </Space>
                    </Dropdown>
                </div>
            </div>
        </Layout.Header>
    );
};
