import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../../axios';
import { useAppSelector } from '../../../../store/hooks/useRedux';
import { Col, Row } from 'antd';
import JSONView from 'react-json-view';

export const TemplatesJson: FC = () => {
    const { id: TEMPLATE_PAGE_ID } = useParams();
    const TEMPLATE_PAGE = useAppSelector((state) => state.templates.TemplatesData).find(
        (template) => template._id === TEMPLATE_PAGE_ID,
    );
    const [jsonData, setJsonData] = useState({});

    useEffect(() => {
        fetchCreate();
    }, []);

    const fetchCreate = async () => {
        try {
            const { data } = await axios.get(`api/json/template/${TEMPLATE_PAGE_ID}`);
            setJsonData(data.data);
            return;
        } catch (e) {
            return;
        }
    };

    if (!TEMPLATE_PAGE) {
        return <h1>Loading...</h1>;
    }

    return (
        <Row gutter={[16, 24]}>
            <Col span={24} style={{ height: 'calc(100vh - 220px)', overflow: 'scroll' }}>
                <JSONView
                    style={{ padding: 10 }}
                    // theme={'ocean'}
                    displayDataTypes={false}
                    enableClipboard={false}
                    iconStyle="square"
                    collapsed={1}
                    src={jsonData}
                />
            </Col>
        </Row>
    );
};
