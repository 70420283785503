import { FC } from 'react';
import { Col, Layout, Row, Space, Tabs, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Roles } from '../SettingsForm/Roles/Roles';
import { TeamOutlined } from '@ant-design/icons';
import { IsAccess } from '../../../../components/IsAccess/IsAccess';

export const SettingsPage: FC = () => {
    const isAccess = IsAccess();

    if (!isAccess?.is_setting) return <span>Доступ заборонено!</span>;

    return (
        <Layout className="content-layout">
            <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
                <Col>
                    <Space>
                        <Typography.Title level={4} style={{ margin: 0 }}>
                            Налаштування
                        </Typography.Title>
                    </Space>
                </Col>
            </Row>
            <Content className="site-layout-background">
                <Tabs
                    tabPosition="top"
                    size="small"
                    type="card"
                    items={[
                        {
                            key: '1',
                            label: (
                                <>
                                    <TeamOutlined /> Посади
                                </>
                            ),
                            children: <Roles />,
                        },
                    ]}
                />
            </Content>
        </Layout>
    );
};
