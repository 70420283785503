import { FC, useMemo } from 'react';
import { IFields } from '../../types';
import './FieldDescription.scss';

interface IProps {
    field: IFields;
}

export const FieldDescription: FC<IProps> = ({ field }) => {
    const removeHtmlTags = useMemo(() => (str: string) => str.replace(/<[^>]*>|&\w+;/gm, ' '), []);
    const content =
        {
            Text: '', //field.content.UA.text
            RichText: '', //removeHtmlTags(field.content.UA.rich_text || '')
            Image: '', //field.content?.title
            Links: '', //field.content.UA.links?.map((link) => link.title).join(', ')
            Button: '', //field.content.UA.title
            Features: ``, // ${field.content.UA.title}: ${removeHtmlTags(field.content.UA.description || '',)}
            List: '', //field.content.UA.list?.map((item: any) => item?.item).join(', ')
            Step: '', //field.content.UA.text
            Testimonial: ``, // (${field.content.UA.fullname}), ${field.content.UA.title}: ${removeHtmlTags(field.content.UA.comment || '')}
            Question: ``, // ${field.content.UA.question}: ${removeHtmlTags(field.content.UA.reply || '',)}
            Video: '', //field.content.UA.title
            Code: '', //field.content.UA.code
        }[field.field_type] ?? null;

    return content ? <span className="fieldDescription">{content}</span> : null;
};
