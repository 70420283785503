import { FC, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './store/hooks/useRedux';
import { fetchLogin } from './store/auth/auth.slice';
import { NoAuthorized } from './router/NoAuthorized.route';
import { Layout } from './containers/Layout/Layout';
import { getTemplates } from './store/templates/templates.slice';
import { getOffers } from './store/offers/offers.slice';
import { getRoles } from './store/settings/usersRole.slice';
import { getCountry } from './store/settings/language.slice';
import { getUsers } from './store/users/users.slice';
import { Preloader } from './components/Preloader/Preloader';
import { ErrorBoundary } from 'react-error-boundary';
import { AppError } from './components/AppError/AppError';
import { getOrders } from './store/orders/orders.slice';
import { getVisits } from './store/visits/visits.slice';
import { getOrdersSettings } from './store/orders/orders.settings.slice';
import { getProducts } from './store/products/products.slice';

export const App: FC = () => {
    const dispatch = useAppDispatch();
    const { isAuth, isLoading: auth_loading } = useAppSelector((state) => state.auth);

    useEffect(() => {
        dispatch(fetchLogin());

        if (isAuth) {
            dispatch(getProducts());
            dispatch(getOrders());
            dispatch(getTemplates());
            dispatch(getOffers());
            dispatch(getRoles());
            dispatch(getCountry());
            dispatch(getUsers());
            dispatch(getVisits());
            dispatch(getOrdersSettings());
        }
    }, [dispatch, isAuth]);

    return (
        <ErrorBoundary FallbackComponent={AppError}>
            <BrowserRouter>
                {isAuth ? <Layout /> : auth_loading ? <Preloader /> : <NoAuthorized />}
            </BrowserRouter>
        </ErrorBoundary>
    );
};
