import React, { FC, useCallback } from 'react';
import axios from '../../axios';
import { Button, message, Modal, Typography } from 'antd';
import { useAppDispatch } from '../../store/hooks/useRedux';

import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { IsAccess } from '../IsAccess/IsAccess';

interface IProps {
    mainId: string;
    sectionId: string;
    fieldId: string;
    request_url: string;
    fieldDelete: any;
    fieldDeleteLanding?: any;
    isTemplate?: boolean;
}

export const FieldDelete: FC<IProps> = React.memo(({ fieldDelete, fieldDeleteLanding, request_url, ...other }) => {
    const dispatch = useAppDispatch();
    const isAccess = IsAccess();

    const handleDelete = useCallback(async () => {
        const key = 'delete';
        if (!isAccess?.templates.delete) {
            return message.error({ content: 'Заборонено видалення!', key, duration: 2 });
        }
        message.loading({ content: 'Завантаження...', key });
        try {
            await axios.patch(request_url, {
                ...other,
            });

            dispatch(fieldDelete({ ...other }));

            message.success({
                content: 'Видалено!',
                key,
                duration: 2,
            });
        } catch (e) {
            message.error({ content: 'Помилка!', key, duration: 2 });
        }
    }, [dispatch, fieldDelete, other, request_url]);

    const DeleteConfirm = useCallback(() => {
        Modal.confirm({
            title: 'Увага!',
            content: (
                <>
                    <div>Ви впевнені, що хочете видалити поле? </div>
                    <div>
                        <Typography.Text type="danger">Ви більше не зможете його відновити</Typography.Text>
                    </div>
                </>
            ),
            icon: <ExclamationCircleOutlined />,
            okText: 'Так',
            okType: 'danger',
            cancelText: 'Ні',

            onOk() {
                handleDelete();
            },
        });
    }, [handleDelete]);

    return (
        <Button size="small" onClick={DeleteConfirm} disabled={!isAccess?.templates.delete}>
            <DeleteOutlined />
        </Button>
    );
});
