import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../axios';
import { IOrders } from '../../types';

interface IInitialState {
    OrdersData: IOrders[];
    isLoading: boolean;
    isLoadingSection: boolean;
}

export const getOrders = createAsyncThunk('orders/getOrders', async (_, { rejectWithValue }) => {
    try {
        const { data } = await axios.get('/api/orders');
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

const initialState: IInitialState = {
    isLoading: false,
    isLoadingSection: false,
    OrdersData: [],
};

const Orders = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        create: (state, action) => {
            state.OrdersData.unshift(action.payload);
        },

        update: (state, action) => {
            state.OrdersData = state.OrdersData.map((order) =>
                order._id === action.payload._id ? { ...order, ...action.payload } : order,
            );
        },

        updateStatus: (state, action) => {
            state.OrdersData = state.OrdersData.map((order) =>
                order._id === action.payload.orderId
                    ? { ...order, status: action.payload.status }
                    : order,
            );
        },

        remove: (state, action) => {
            state.OrdersData = state.OrdersData.filter((order) => order._id !== action.payload);
        },

        duplicateGroup: (state, action) => {
            state.OrdersData = action.payload;
        },

        removeGroup: (state, action) => {
            action.payload.map(
                (id: string) =>
                    (state.OrdersData = state.OrdersData.filter((order) => order._id !== id)),
            );
        },
    },
    extraReducers: (build) => {
        build
            .addCase(getOrders.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getOrders.fulfilled, (state, action) => {
                state.OrdersData = action.payload;
                state.isLoading = false;
            })
            .addCase(getOrders.rejected, (state, action) => {
                state.isLoading = false;
            });
    },
});

export default Orders.reducer;

export const {
    create,
    update,
    updateStatus,
    remove,

    duplicateGroup,
    removeGroup,
} = Orders.actions;
