import { FC } from 'react';
import { Button, message, Modal, Typography } from 'antd';
import axios from '../../../../axios';
import { remove } from '../../../../store/products/products.slice';
import { useAppDispatch } from '../../../../store/hooks/useRedux';

import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ButtonType } from 'antd/lib/button';
import { IsAccess } from '../../../../components/IsAccess/IsAccess';

interface IProps {
    ProductId: React.Key;
    buttonText?: string;
    danger?: boolean;
    type?: ButtonType;
}

const key = 'deletable';

export const ProductDelete: FC<IProps> = ({ ProductId, buttonText, danger, type }) => {
    const dispatch = useAppDispatch();
    const isAccess = IsAccess();

    const handleDelete = async () => {
        message.loading({ content: 'Завантаження...', key });
        try {
            const { data } = await axios.delete(`/api/product/${ProductId}`);
            if (data.success) {
                dispatch(remove(ProductId));
                message.success({
                    content: 'Успішно видалено!',
                    key,
                    duration: 2,
                });
            } else {
                message.error({ content: 'Сталась помилка при видалені!', key, duration: 2 });
            }
        } catch (e) {
            message.error({ content: 'Сталась помилка при видалені!', key, duration: 2 });
        }
    };

    const showModalDelete = () => {
        Modal.confirm({
            title: 'Увага!',
            content: (
                <>
                    <div>Ви впевнені, що хочете видалити товар? </div>
                    <div>
                        <Typography.Text type="danger">Ви більше не зможете його відновити!</Typography.Text>
                    </div>
                </>
            ),
            icon: <ExclamationCircleOutlined />,
            okText: 'Так',
            okType: 'danger',
            cancelText: 'Ні',
            onOk() {
                handleDelete();
            },
        });
    };

    return (
        <Button danger={!!danger} type={type} icon={<DeleteOutlined />} onClick={showModalDelete} disabled={!isAccess?.products.delete}>
            {buttonText}
        </Button>
    );
};
