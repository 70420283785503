import { FC, useState, useRef } from 'react';
import { Button, Col, ColorPicker, Drawer, Form, Input, message, Row, Switch, Typography } from 'antd';
import axios from '../../../../../../axios';
import { useAppDispatch } from '../../../../../../store/hooks/useRedux';
import { createRole } from '../../../../../../store/settings/usersRole.slice';
import * as Icon from '@ant-design/icons';
import { IRolesForm } from '../../../../../../types';
import './Role.create.scss';

const key = 'updatable';

export const RoleCreate: FC = () => {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const inputRef = useRef(null);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    const showDrawer = () => setOpen(true);
    const onClose = () => setOpen(false);

    const handleCreateRole = async (props: IRolesForm) => {
        setIsLoading(true);
        message.loading({ content: 'Завантаження...', key });
        // @ts-ignore
        const colorToHex = typeof props.color === 'string' ? props.color : props.color.toHexString();
        try {
            const { data } = await axios.post('/api/roles', { ...props, color: colorToHex });

            if (data.success) {
                setIsLoading(false);
                dispatch(createRole(data.role));
                setOpen(false);
                form.resetFields();

                message.success({ content: 'Успішно створено!', key, duration: 2 });
                return;
            } else {
                setIsLoading(false);
                message.error({ content: 'Помилка!', key, duration: 2 });
            }
        } catch (e) {
            setIsLoading(false);
            message.error({ content: 'Помилка!', key, duration: 2 });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Button type="primary" icon={<Icon.PlusOutlined />} onClick={showDrawer} />
            <Drawer
                title="Нова посада"
                open={open}
                placement="right"
                onClose={onClose}
                extra={
                    <Button
                        icon={<Icon.SaveOutlined />}
                        loading={isLoading}
                        // @ts-ignore
                        onClick={() => inputRef?.current?.submit()}
                        type="primary"
                    />
                }>
                <Form
                    name="basic"
                    form={form}
                    ref={inputRef}
                    initialValues={{ remember: true }}
                    onFinish={handleCreateRole}
                    size="middle"
                    autoComplete="off">
                    <Row gutter={[16, 16]}>
                        <Col span={24} style={{ display: 'flex' }}>
                            {/* Color */}
                            <Form.Item name="color" rules={[{ required: true, message: '' }]}>
                                <ColorPicker format="hex" />
                            </Form.Item>

                            {/* Title */}
                            <Form.Item
                                name="title"
                                rules={[
                                    { required: true, message: '' },
                                    { min: 2, message: '' },
                                ]}
                                style={{ width: '100%', marginLeft: 5 }}>
                                <Input placeholder="Назва" size="middle" />
                            </Form.Item>
                        </Col>

                        {/* Color
                        <Col span={24}>
                            <Form.Item name="color" rules={[{ required: true, message: 'Виберіть колір!' }]}>
                                <Row justify="space-between">
                                    <Radio.Group
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}>
                                        {COLOR_BUTTON.map((color, index) => (
                                            <Radio.Button
                                                key={index}
                                                value={color}
                                                className="settings_users_role_create_modal__button"
                                                style={{
                                                    backgroundColor: color,
                                                    height: 25,
                                                    width: 25,
                                                    padding: '0 10px ',
                                                }}></Radio.Button>
                                        ))}
                                    </Radio.Group>
                                </Row>
                            </Form.Item>
                        </Col> */}

                        {/* Orders */}
                        <Col span={24} className="role_container">
                            <Row>
                                <Col span={24}>
                                    <Typography.Text strong>Заявки</Typography.Text>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item label="Створювати" name={['orders', 'create']} initialValue={false} valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item label="Редагувати" name={['orders', 'edit']} initialValue={false} valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item label="Видаляти" name={['orders', 'delete']} initialValue={false} valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        {/* Products */}
                        <Col span={24} className="role_container">
                            <Row>
                                <Col span={24}>
                                    <Typography.Text strong>Тканини</Typography.Text>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item label="Створювати" name={['products', 'create']} initialValue={false} valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item label="Редагувати" name={['products', 'edit']} initialValue={false} valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item label="Видаляти" name={['products', 'delete']} initialValue={false} valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item label="Дублювати" name={['products', 'duplicate']} initialValue={false} valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        {/* Templates */}
                        <Col span={24} className="role_container">
                            <Row>
                                <Col span={24}>
                                    <Typography.Text strong>Шаблон</Typography.Text>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item label="Створювати" name={['templates', 'create']} initialValue={false} valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item label="Редагувати" name={['templates', 'edit']} initialValue={false} valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item label="Видаляти" name={['templates', 'delete']} initialValue={false} valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item label="Дублювати" name={['templates', 'duplicate']} initialValue={false} valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        {/* Users */}
                        <Col span={24} className="role_container">
                            <Row>
                                <Col span={24}>
                                    <Typography.Text strong>Користувачі</Typography.Text>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item label="Створювати" name={['users', 'create']} initialValue={false} valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item label="Редагувати" name={['users', 'edit']} initialValue={false} valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="settings_users_role_create_modal__swich">
                                    <Form.Item label="Видаляти" name={['users', 'delete']} initialValue={false} valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        {/* Settings */}
                        <Col span={24} className="role_container">
                            <Row>
                                <Col span={24}>
                                    <Typography.Text strong>Налаштування</Typography.Text>
                                </Col>
                                <Col span={24} className="settings_users_role_create_modal__swich">
                                    <Form.Item label="Доступ до налаштувань" name="is_setting" initialValue={false} valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        {/* Admin-CP */}
                        <Col span={24} className="role_container">
                            <Row>
                                <Col span={24}>
                                    <Typography.Text strong>Адмін-панель</Typography.Text>
                                </Col>
                                <Col span={24} className="settings_users_role_create_modal__swich">
                                    <Form.Item label="Доступ до Адмін-панелі" name="is_admin_panel" initialValue={false} valuePropName="checked">
                                        <Switch size="small" checkedChildren={<Icon.CheckOutlined />} unCheckedChildren={<Icon.CloseOutlined />} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};
