import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../../../../axios';
import { Col, Form, Input, message, Modal, Row } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks/useRedux';
import * as Template from '../../../../../../store/templates/templates.slice';

import { EditOutlined } from '@ant-design/icons';

interface ISectionId {
    sectionId?: string;
}

interface IUpdatedSectionProps {
    title: string;
}

const key = 'updatable';

export const SectionEdit: FC<ISectionId> = ({ sectionId }) => {
    const { id: templateId } = useParams();
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    const [isModal, setIsModal] = useState(false);
    const [isLoadingForm, setIsLoadingForm] = useState(false);

    const { TemplatesData } = useAppSelector((state) => state.templates);
    const template = TemplatesData.find((template) => template._id === templateId);
    const section = template?.sections.find((section) => section._id === sectionId);

    const handleUpdateSection = async (props: IUpdatedSectionProps) => {
        setIsLoadingForm(true);
        message.loading({ content: 'Завантаження...', key });
        try {
            const { data } = await axios.patch('/api/template/section/action', {
                templateId,
                sectionId,
                ...props,
            });

            if (data.success) {
                setIsLoadingForm(false);
                dispatch(
                    Template.sectionsUpdate({
                        templateId,
                        sectionId: data.section.id,
                        title: data.section.title,
                    }),
                );

                setIsModal(false);
                message.success({ content: 'Збережено!', key, duration: 2 });
                return;
            } else {
                setIsLoadingForm(false);
                message.error({ content: 'Помилка!', key, duration: 2 });
            }
        } catch (e) {
            setIsLoadingForm(false);
            message.error({ content: 'Помилка!', key, duration: 2 });
        } finally {
            setIsLoadingForm(false);
        }
    };

    return (
        <>
            <EditOutlined
                style={{
                    fontSize: 10,
                    marginLeft: 5,
                }}
                onClick={() => setIsModal(true)}
            />

            <Modal
                okText="Зберегти"
                cancelText="Відмінити"
                title={`Редагування блоку «${section?.title}»`}
                open={isModal}
                onOk={() => form.submit()}
                confirmLoading={isLoadingForm}
                onCancel={() => setIsModal(false)}>
                <Form
                    name="basic"
                    form={form}
                    initialValues={{ remember: true }}
                    onFinish={handleUpdateSection}
                    size="middle"
                    autoComplete="off">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                name="title"
                                initialValue={section?.title}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Будь ласка, вкажіть назву для блоку!',
                                    },
                                ]}>
                                <Input placeholder="Назва блоку" size="middle" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};
