import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';
import { IVisits } from '../../types/index';

export const getVisits = createAsyncThunk('visits/getVisits', async (_, { rejectWithValue }) => {
    try {
        const { data } = await axios.get('/api/visits');
        return data;
    } catch (e) {
        console.log('Error =>', e);
        return rejectWithValue(e);
    }
});

interface IInitialState {
    isLoading: boolean;
    visitsData: IVisits[];
}

const initialState: IInitialState = {
    isLoading: false,
    visitsData: [],
};

const users = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getVisits.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getVisits.fulfilled, (state, action) => {
                state.visitsData = action.payload;
                state.isLoading = false;
            })
            .addCase(getVisits.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export default users.reducer;
