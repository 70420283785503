import { useState } from 'react';
import axios from '../../../../axios';
import { useAppDispatch } from '../../../../store/hooks/useRedux';
import { message, Modal, Typography } from 'antd';
import * as Order from '../../../../store/orders/orders.slice';
import type { TableRowSelection } from 'antd/es/table/interface';
import { IOrdersPage } from '../../../../types';
import * as Icon from '@ant-design/icons';
import { IsAccess } from '../../../../components/IsAccess/IsAccess';

interface IGroupUpdateProps {
    action: string;
    orderId: React.Key[];
}

const errorMessage = (msg: string) => message.error(msg);
const key = 'updatable';

export const OrderGroupUpdate = () => {
    const dispatch = useAppDispatch();
    const isAccess = IsAccess();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const selectedQuantity = !!selectedRowKeys.length && selectedRowKeys.length;

    const groupUpdate = async (props: IGroupUpdateProps) => {
        message.loading({ content: 'Завантаження...', key });
        try {
            const { data } = await axios.patch('/api/order/group/update', props);

            if (data.success) {
                switch (props.action) {
                    case 'Duplicate':
                        dispatch(Order.duplicateGroup(data.data));
                        message.success({
                            content: `Дубльовано ${props.orderId.length} шт!`,
                            key,
                            duration: 2,
                        });
                        break;
                    case 'Delete':
                        dispatch(Order.removeGroup(props.orderId));
                        message.success({
                            content: `Видалено ${props.orderId.length} шт!`,
                            key,
                            duration: 2,
                        });
                        break;

                    default:
                        break;
                }
            } else {
                message.error({ content: 'Сталась помилка!', key, duration: 2 });
            }
        } catch (e) {
            message.error({ content: 'Сталась помилка!', key, duration: 2 });
        }
    };

    const showStatusConfirm = (props: IGroupUpdateProps) => {
        Modal.confirm({
            title: 'Увага!',
            content: 'Ви впевнен, що хочете дублювати вибрані заявки?',
            icon: <Icon.ExclamationCircleOutlined />,
            okText: 'Так',
            cancelText: 'Ні',
            onOk() {
                groupUpdate(props);
            },
        });
    };

    const showDeleteConfirm = (props: IGroupUpdateProps) => {
        Modal.confirm({
            title: 'Увага!',
            content: (
                <>
                    <div>Ви впевнені, що хочете видалити вибрані заявки?</div>
                    <div>
                        <Typography.Text type="danger">Ви більше не зможете їх відновити!</Typography.Text>
                    </div>
                </>
            ),
            icon: <Icon.ExclamationCircleOutlined />,
            okText: 'Так',
            cancelText: 'Ні',
            okType: 'danger',
            onOk() {
                groupUpdate(props);
            },
        });
    };

    const rowSelection: TableRowSelection<IOrdersPage> = {
        selectedRowKeys,
        onChange: (selected) => setSelectedRowKeys(selected),
        selections: isAccess?.orders.delete && [
            {
                key: 'duplicate',
                text: (
                    <>
                        <Icon.CopyOutlined style={{ marginRight: 5, color: '#F59337' }} />
                        Дублювати {selectedQuantity}
                    </>
                ),
                onSelect: () => {
                    selectedRowKeys.length
                        ? showStatusConfirm({
                              action: 'Duplicate',
                              orderId: selectedRowKeys,
                          })
                        : errorMessage('Помилка. Виберіть елемент!');
                },
            },
            {
                key: 'delete',
                text: (
                    <>
                        <Icon.DeleteOutlined style={{ marginRight: 5, color: '#fa541c' }} />
                        Видалити {selectedQuantity}
                    </>
                ),
                onSelect: () => {
                    selectedRowKeys.length
                        ? showDeleteConfirm({
                              action: 'Delete',
                              orderId: selectedRowKeys,
                          })
                        : errorMessage('Помилка. Виберіть елемент!');
                },
            },
        ],
    };

    return rowSelection;
};
