import { FC, useState } from 'react';
import { Button, Checkbox, Col, ColorPicker, Form, Input, Modal, Row, Select, Tabs, Typography, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { SERVER_URL } from '../../../../../config';
import { UploadFile } from 'antd/lib';
import * as Icon from '@ant-design/icons';

interface IProps {
    onChangeImage: any;
    fileImage: any;
    form: any;
}

const LOOK_ARR_UA = [
    { value: 'Рулонні штори', label: 'Рулонні штори' },
    { value: 'День-ніч', label: 'День-ніч' },
    { value: 'Римські штори', label: 'Римські штори' },
    {
        value: 'Горизонтальні жалюзі',
        label: 'Горизонтальні жалюзі',
    },
    {
        value: 'Вертикальні жалюзі',
        label: 'Вертикальні жалюзі',
    },
    { value: 'Пліссе', label: 'Пліссе' },
];

const LOOK_ARR_RU = [
    { value: 'Рулонные шторы', label: 'Рулонные шторы' },
    { value: 'День-ночь', label: 'День-ночь' },
    { value: 'Римские шторы', label: 'Римские шторы' },
    {
        value: 'Горизонтальные жалюзи',
        label: 'Горизонтальные жалюзи',
    },
    {
        value: 'Вертикальные жалюзи',
        label: 'Вертикальные жалюзи',
    },
    { value: 'Плиссе', label: 'Плиссе' },
];

export const ProductContent: FC<IProps> = ({ fileImage, onChangeImage, form }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    // ==== MODELS UA ====
    const [modelOptionsUA, setModelOptionsUA] = useState<string[]>([]);

    const handleCategoryChangeUA = (value: string) => {
        switch (value) {
            case 'Рулонні штори':
                setModelOptionsUA(['Однотонні', 'Фактурні', 'Принт', 'BleackOut', 'Джутові', 'Бамбукові']);
                form.resetFields([['UA', 'model']]);
                break;
            case 'День-ніч':
                setModelOptionsUA(['Однотонні', 'Фактурні', 'BleackOut']);
                form.resetFields([['UA', 'model']]);
                break;
            case 'Римські штори':
                setModelOptionsUA(['Однотонні', 'Фактурні', 'Принт', 'BleackOut']);
                form.resetFields([['UA', 'model']]);
                break;
            case 'Горизонтальні жалюзі':
                setModelOptionsUA(['Алюмінієві', "Дерев'яні", 'Бамбукові']);
                form.resetFields([['UA', 'model']]);
                break;
            case 'Вертикальні жалюзі':
                setModelOptionsUA(['Всі']);
                form.resetFields([['UA', 'model']]);
                break;
            case 'Пліссе':
                setModelOptionsUA(['Всі']);
                form.resetFields([['UA', 'model']]);
                break;
            default:
                setModelOptionsUA([]);
                break;
        }
    };

    // ==== ./MODELS UA ====

    // ==== MODELS RU ====
    const [modelOptionsRU, setModelOptionsRU] = useState<string[]>([]);
    const handleCategoryChangeRU = (value: string) => {
        switch (value) {
            case 'Рулонные шторы':
                setModelOptionsRU(['Однотонные', 'Фактурные', 'Принт', 'BleackOut', 'Джутовые', 'Бамбуковые']);
                form.resetFields([['RU', 'model']]);
                break;
            case 'День-ночь':
                setModelOptionsRU(['Однотонные', 'Фактурные', 'BleackOut']);
                form.resetFields([['RU', 'model']]);
                break;
            case 'Римские шторы':
                setModelOptionsRU(['Однотонные', 'Фактурные', 'Принт', 'BleackOut']);
                form.resetFields([['RU', 'model']]);
                break;
            case 'Горизонтальные жалюзи':
                setModelOptionsRU(['Алюминиевые', 'Деревянные', 'Бамбуковые']);
                form.resetFields([['RU', 'model']]);
                break;
            case 'Вертикальные жалюзи':
                setModelOptionsRU(['Все']);
                form.resetFields([['RU', 'model']]);
                break;
            case 'Плиссе':
                setModelOptionsRU(['Все']);
                form.resetFields([['RU', 'model']]);
                break;
            default:
                setModelOptionsRU([]);
                break;
        }
    };

    // ==== ./MODELS RU ====

    const handlePreview = async (file: UploadFile) => {
        setPreviewImage(SERVER_URL + file.response?.url);
        setPreviewOpen(true);
    };

    return (
        <>
            <Row gutter={[0, 10]} style={{ overflow: 'scroll', height: 'calc(100vh - 150px)' }}>
                <Tabs className="tab-card-content-admin" size="small" tabPosition="top" defaultActiveKey="UA" type="card">
                    <Tabs.TabPane tab={<span>UA</span>} key="UA">
                        <Row gutter={[0, 10]}>
                            <Col span={24}>
                                <Row gutter={[10, 10]} className="product_card">
                                    {/* Назва */}
                                    <Col className="gutter-row" span={24} sm={12}>
                                        <Form.Item
                                            name={['UA', 'name']}
                                            label="Назва"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                                {
                                                    type: 'string',
                                                    min: 2,
                                                    message: '',
                                                },
                                            ]}>
                                            <Input placeholder="Введіть назву" allowClear />
                                        </Form.Item>
                                    </Col>

                                    {/* Система */}
                                    <Col className="gutter-row" span={24} sm={12}>
                                        <Form.Item
                                            name={['UA', 'system']}
                                            label="Система"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                                {
                                                    type: 'string',
                                                    min: 2,
                                                    message: '',
                                                },
                                            ]}>
                                            <Input placeholder="Введіть текст" allowClear />
                                        </Form.Item>
                                    </Col>

                                    {/* Вид */}
                                    <Col className="gutter-row" span={24} sm={12}>
                                        <Form.Item
                                            name={['UA', 'look']}
                                            label="Вид"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}>
                                            <Select
                                                placeholder="Виберіть вид"
                                                onChange={(value) => {
                                                    handleCategoryChangeUA(value);
                                                }}
                                                options={LOOK_ARR_UA}
                                            />
                                        </Form.Item>
                                    </Col>

                                    {/* Модель */}
                                    <Col className="gutter-row" span={24} sm={12}>
                                        <Form.Item
                                            name={['UA', 'model']}
                                            label="Модель"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}>
                                            <Select
                                                placeholder="Виберіть модель"
                                                options={modelOptionsUA.map((model) => ({
                                                    value: model,
                                                    label: model,
                                                }))}
                                            />
                                        </Form.Item>
                                    </Col>

                                    {/* Тип тканини*/}
                                    <Col className="gutter-row" span={24} sm={12}>
                                        <Form.Item
                                            name={['UA', 'fabric_type']}
                                            label="Тип тканини"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}>
                                            <Input placeholder="Введіть текст" allowClear />
                                        </Form.Item>
                                    </Col>

                                    {/* Тип кріплення */}
                                    <Col className="gutter-row" span={24} sm={12}>
                                        <Form.Item
                                            name={['UA', 'mounting_type']}
                                            label="Тип кріплення"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}>
                                            <Input placeholder="Введіть текст" allowClear />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row gutter={[10, 10]} className="product_card">
                                    {/* Відтінок*/}
                                    <Col className="gutter-row" span={12}>
                                        <Typography.Text style={{ display: 'block', paddingBottom: 10 }}>Відтінок</Typography.Text>

                                        <Form.List name="colors">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    <Row gutter={10}>
                                                        {fields.map(({ key, name, ...restField }) => (
                                                            <Col
                                                                key={key}
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'center',
                                                                    marginBottom: 10,
                                                                }}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'color']}
                                                                    style={{
                                                                        marginBottom: 5,
                                                                    }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: '',
                                                                        },
                                                                    ]}>
                                                                    <ColorPicker format="hex" />
                                                                </Form.Item>
                                                                <Icon.CloseOutlined style={{ fontSize: 10 }} onClick={() => remove(name)} />
                                                            </Col>
                                                        ))}
                                                        <Col>
                                                            <Button type="dashed" onClick={() => add()} block>
                                                                <Icon.PlusOutlined />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>

                                    {/* marker */}
                                    <Col span={12}>
                                        <Typography.Text
                                            style={{
                                                display: 'block',
                                                paddingBottom: 10,
                                            }}>
                                            Маркер
                                        </Typography.Text>

                                        <Form.Item name={['UA', 'markers']} initialValue={[]}>
                                            <Checkbox.Group>
                                                <Checkbox value="Хіт">Хіт</Checkbox>
                                                <Checkbox value="Новинка">Новинка</Checkbox>
                                                <Checkbox value="Акція">Акція</Checkbox>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row gutter={[10, 10]} className="product_card">
                                    {/* Бренд */}
                                    <Col className="gutter-row" span={24} sm={12}>
                                        <Form.Item
                                            name={['UA', 'brand']}
                                            label="Бренд"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}>
                                            <Input placeholder="Введіть текст" allowClear />
                                        </Form.Item>
                                    </Col>

                                    {/* Країна виробник */}
                                    <Col className="gutter-row" span={24} sm={12}>
                                        <Form.Item
                                            name={['UA', 'madein']}
                                            label="Країна виробник"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}>
                                            <Input placeholder="Введіть текст" allowClear />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            {/* Розміри */}
                            <Col span={24}>
                                <Row gutter={[10, 10]} className="product_card">
                                    {/* Ширина тканини */}
                                    <Col span={24} sm={12}>
                                        <Form.Item
                                            name={['UA', 'sizes', 'width']}
                                            label="Ширина тканини"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}>
                                            <Input placeholder="Введіть текст" allowClear />
                                        </Form.Item>
                                    </Col>

                                    {/* Висота тканини */}
                                    <Col span={24} sm={12}>
                                        <Form.Item
                                            name={['UA', 'sizes', 'height']}
                                            label="Висота тканини"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}>
                                            <Input placeholder="Введіть текст" allowClear />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            {/* Прайс */}
                            <Col span={24}>
                                <Row gutter={[10, 10]} className="product_card">
                                    {/* Ціна */}
                                    <Col className="gutter-row" span={24} sm={12}>
                                        <Form.Item
                                            name={['UA', 'pricce']}
                                            label="Ціна"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}>
                                            <Input placeholder="Введіть текст" allowClear />
                                        </Form.Item>
                                    </Col>

                                    {/* Знижка */}
                                    <Col className="gutter-row" span={24} sm={12}>
                                        <Form.Item
                                            name={['UA', 'discount']}
                                            label="Знижка"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}>
                                            <Input placeholder="Введіть текст" allowClear />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            {/* Комплектація */}
                            <Col span={24}>
                                <Row gutter={[10, 10]} className="product_card">
                                    <Col className="gutter-row" span={24}>
                                        <Form.Item name={['UA', 'equipments']} label="Комплектація">
                                            <TextArea allowClear autoSize={{ minRows: 5 }} placeholder="Введіть текст" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            {/* Опис */}
                            <Col span={24}>
                                <Row gutter={[10, 10]} className="product_card">
                                    <Col className="gutter-row" span={24}>
                                        <Form.Item name={['UA', 'note']} label="Опис">
                                            <TextArea allowClear autoSize={{ minRows: 5 }} placeholder="Введіть текст" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<span>RU</span>} key="RU">
                        <Row gutter={[0, 10]}>
                            <Col span={24}>
                                <Row gutter={[10, 10]} className="product_card">
                                    {/* Назва */}
                                    <Col className="gutter-row" span={24} sm={12}>
                                        <Form.Item
                                            name={['RU', 'name']}
                                            label="Название"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                                {
                                                    type: 'string',
                                                    min: 2,
                                                    message: '',
                                                },
                                            ]}>
                                            <Input placeholder="Введите название" allowClear />
                                        </Form.Item>
                                    </Col>

                                    {/* Система */}
                                    <Col className="gutter-row" span={24} sm={12}>
                                        <Form.Item
                                            name={['RU', 'system']}
                                            label="Система"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                                {
                                                    type: 'string',
                                                    min: 2,
                                                    message: '',
                                                },
                                            ]}>
                                            <Input placeholder="Введите текст" allowClear />
                                        </Form.Item>
                                    </Col>

                                    {/* Вид */}
                                    <Col className="gutter-row" span={24} sm={12}>
                                        <Form.Item
                                            name={['RU', 'look']}
                                            label="Вид"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}>
                                            <Select
                                                placeholder="Выберите вид"
                                                onChange={(value) => {
                                                    handleCategoryChangeRU(value);
                                                }}
                                                options={LOOK_ARR_RU}
                                            />
                                        </Form.Item>
                                    </Col>

                                    {/* Модель */}
                                    <Col className="gutter-row" span={24} sm={12}>
                                        <Form.Item
                                            name={['RU', 'model']}
                                            label="Модель"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}>
                                            <Select
                                                placeholder="Виберите модель"
                                                options={modelOptionsRU.map((model) => ({
                                                    value: model,
                                                    label: model,
                                                }))}
                                            />
                                        </Form.Item>
                                    </Col>

                                    {/* Тип тканини*/}
                                    <Col className="gutter-row" span={24} sm={12}>
                                        <Form.Item
                                            name={['RU', 'fabric_type']}
                                            label="Тип ткани"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}>
                                            <Input placeholder="Введите текст" allowClear />
                                        </Form.Item>
                                    </Col>

                                    {/* Тип кріплення */}
                                    <Col className="gutter-row" span={24} sm={12}>
                                        <Form.Item
                                            name={['RU', 'mounting_type']}
                                            label="Тип крепления"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}>
                                            <Input placeholder="Введите текст" allowClear />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row gutter={[10, 10]} className="product_card">
                                    {/* Відтінок*/}
                                    <Col className="gutter-row" span={12}>
                                        <Typography.Text style={{ display: 'block', paddingBottom: 10 }}>Оттенок</Typography.Text>

                                        <Form.List name="colors">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    <Row gutter={10}>
                                                        {fields.map(({ key, name, ...restField }) => (
                                                            <Col
                                                                key={key}
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'center',
                                                                    marginBottom: 10,
                                                                }}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'color']}
                                                                    style={{
                                                                        marginBottom: 5,
                                                                    }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: '',
                                                                        },
                                                                    ]}>
                                                                    <ColorPicker format="hex" />
                                                                </Form.Item>
                                                                <Icon.CloseOutlined style={{ fontSize: 10 }} onClick={() => remove(name)} />
                                                            </Col>
                                                        ))}
                                                        <Col>
                                                            <Button type="dashed" onClick={() => add()} block>
                                                                <Icon.PlusOutlined />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>

                                    {/* marker */}
                                    <Col span={12}>
                                        <Typography.Text
                                            style={{
                                                display: 'block',
                                                paddingBottom: 10,
                                            }}>
                                            Маркер
                                        </Typography.Text>

                                        <Form.Item name={['RU', 'markers']} initialValue={[]}>
                                            <Checkbox.Group>
                                                <Checkbox value="Хит">Хит</Checkbox>
                                                <Checkbox value="Новинка">Новинка</Checkbox>
                                                <Checkbox value="Акция">Акция</Checkbox>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row gutter={[10, 10]} className="product_card">
                                    {/* Бренд */}
                                    <Col className="gutter-row" span={24} sm={12}>
                                        <Form.Item
                                            name={['RU', 'brand']}
                                            label="Бренд"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}>
                                            <Input placeholder="Введите текст" allowClear />
                                        </Form.Item>
                                    </Col>

                                    {/* Країна виробник */}
                                    <Col className="gutter-row" span={24} sm={12}>
                                        <Form.Item
                                            name={['RU', 'madein']}
                                            label="Страна производитель"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}>
                                            <Input placeholder="Введите текст" allowClear />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            {/* Розміри */}
                            <Col span={24}>
                                <Row gutter={[10, 10]} className="product_card">
                                    {/* Ширина тканини */}
                                    <Col span={24} sm={12}>
                                        <Form.Item
                                            name={['RU', 'sizes', 'width']}
                                            label="Ширина ткани"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}>
                                            <Input placeholder="Введите текст" allowClear />
                                        </Form.Item>
                                    </Col>

                                    {/* Висота тканини */}
                                    <Col span={24} sm={12}>
                                        <Form.Item
                                            name={['RU', 'sizes', 'height']}
                                            label="Висота ткани"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}>
                                            <Input placeholder="Введите текст" allowClear />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            {/* Прайс */}
                            <Col span={24}>
                                <Row gutter={[10, 10]} className="product_card">
                                    {/* Ціна */}
                                    <Col className="gutter-row" span={24} sm={12}>
                                        <Form.Item
                                            name={['RU', 'pricce']}
                                            label="Цена"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}>
                                            <Input placeholder="Введите текст" allowClear />
                                        </Form.Item>
                                    </Col>

                                    {/* Знижка */}
                                    <Col className="gutter-row" span={24} sm={12}>
                                        <Form.Item
                                            name={['RU', 'discount']}
                                            label="Скидка"
                                            rules={[
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}>
                                            <Input placeholder="Введите текст" allowClear />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            {/* Комплектація */}
                            <Col span={24}>
                                <Row gutter={[10, 10]} className="product_card">
                                    <Col className="gutter-row" span={24}>
                                        <Form.Item name={['RU', 'equipments']} label="Комплектаация">
                                            <TextArea allowClear autoSize={{ minRows: 5 }} placeholder="Введите текст" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            {/* Опис */}
                            <Col span={24}>
                                <Row gutter={[10, 10]} className="product_card">
                                    <Col className="gutter-row" span={24}>
                                        <Form.Item name={['RU', 'note']} label="Описание">
                                            <TextArea allowClear autoSize={{ minRows: 5 }} placeholder="Введите текст" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                </Tabs>

                {/* Зображення */}
                <Col span={24}>
                    <Row gutter={[10, 10]} className="product_card">
                        <Col className="gutter-row" span={24}>
                            <Typography.Text style={{ display: 'block', paddingBottom: 10 }}>Зображення</Typography.Text>
                            <Upload
                                accept="jpeg jpg png"
                                action={`${SERVER_URL}/upload`}
                                listType="picture-card"
                                maxCount={1}
                                multiple
                                fileList={fileImage}
                                onChange={onChangeImage}
                                onPreview={handlePreview}>
                                <Icon.UploadOutlined />
                            </Upload>
                            <Modal open={previewOpen} footer={null} onCancel={() => setPreviewOpen(false)}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
