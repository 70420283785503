import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks/useRedux';
import { Content } from 'antd/lib/layout/layout';
import { UserSearch } from '../UsersForm/Users.search/Users.search';
import { UserDelete } from '../UsersForm/User.delete/User.delete';
import { UsersFilter } from '../UsersForm/Users.filter/Users.filter';
import { Avatar, Button, Col, Layout, List, Row, Space, Tag, Typography } from 'antd';
import { EmptyCustome } from '../../../../components/EmptyCustome/EmptyCustome';
import Sider from 'antd/es/layout/Sider';
import * as Icon from '@ant-design/icons';
import './UsersPage.scss';
import { IsAccess } from '../../../../components/IsAccess/IsAccess';

export const UsersPage: FC = () => {
    const [usePageSize, setPageSize] = useState(15);
    const isAccess = IsAccess();
    const navigate = useNavigate();
    const { users, isLoading } = useAppSelector((state) => state.users);
    const { roles } = useAppSelector((state) => state.usersRole);
    const [searchName, setSearchName] = useState(users);
    const [searchRole, setSearchRole] = useState(searchName);

    useEffect(() => {
        setSearchName(users);
    }, [users]);

    useEffect(() => {
        setSearchRole(searchName);
    }, [users, searchName]);

    return (
        <Layout className="content-layout">
            <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
                <Col>
                    <Space>
                        <Typography.Title level={4} style={{ margin: 0 }}>
                            Користувачі
                        </Typography.Title>
                    </Space>
                </Col>
            </Row>
            <Content className="site-layout-background">
                <Layout>
                    <Sider style={{ backgroundColor: '#fff', padding: '10px 10px 38px 10px' }}>
                        <Button
                            type="primary"
                            onClick={() => navigate('/users/create')}
                            icon={<Icon.PlusOutlined />}
                            disabled={!isAccess?.users.create}>
                            Новий користувач
                        </Button>
                        <UserSearch setSearch={setSearchName} searchName={searchName} />
                        <UsersFilter setSearch={setSearchRole} searchRole={searchRole} searchName={searchName} />
                    </Sider>
                    <Layout>
                        <Content style={{ margin: '0 0 0 20px' }}>
                            <EmptyCustome>
                                <List
                                    pagination={
                                        !searchRole.length
                                            ? false
                                            : {
                                                  defaultPageSize: 15,
                                                  showSizeChanger: true,
                                                  disabled: !users.length ? true : false,
                                                  pageSize: usePageSize,
                                                  pageSizeOptions: [15, 20, 30, 40, 50, 100],
                                                  onShowSizeChange: (_, size) => setPageSize(size),
                                              }
                                    }
                                    dataSource={searchRole}
                                    loading={isLoading}
                                    renderItem={(user) => {
                                        const fullName = user.firstName + ' ' + user.lastName;
                                        const role = roles.find((role) => role._id === user.roleId && role);

                                        return (
                                            <Row
                                                justify="space-between"
                                                style={{
                                                    padding: '10px 20px',
                                                    width: '100%',
                                                    backgroundColor: '#fff',
                                                    marginBottom: '5px',
                                                    alignItems: 'center',
                                                }}>
                                                {/* Avatar */}
                                                <Col span={6}>
                                                    <Row align="middle" gutter={10}>
                                                        <Col>
                                                            <Avatar
                                                                src={user.avatarUrl}
                                                                shape="circle"
                                                                size={{ xs: 24, sm: 28, xxl: 38 }}
                                                                icon={<Icon.UserOutlined />}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <div>{fullName}</div>
                                                            <div style={{ color: 'grey' }}>{user.email}</div>
                                                        </Col>
                                                        {/* Role */}
                                                        <Col
                                                            span={5}
                                                            style={{
                                                                borderLeft: '1px solid rgba(128, 128, 128, 0.14)',
                                                                paddingLeft: 20,
                                                                marginLeft: 20,
                                                            }}>
                                                            <Tag color={role?.color}>{role?.title}</Tag>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                {/* Actions */}
                                                <Col>
                                                    <Space size="small">
                                                        <Button
                                                            size="small"
                                                            onClick={() => navigate(`/users/edit/${user._id}`)}
                                                            disabled={!isAccess?.users.edit}>
                                                            <Icon.EditOutlined key="edit" />
                                                        </Button>
                                                        <UserDelete userId={user._id} />
                                                    </Space>
                                                </Col>
                                            </Row>
                                        );
                                    }}
                                />
                            </EmptyCustome>
                        </Content>
                    </Layout>
                </Layout>
            </Content>
        </Layout>
    );
};
