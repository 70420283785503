import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../axios';
import { ITemplates } from '../../types';

interface IInitialState {
    TemplatesData: ITemplates[];
    isLoading: boolean;
    // isLoadingSection: boolean;
}

export const getTemplates = createAsyncThunk(
    'templates/getTemplates',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await axios.get('/api/templates');
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

const initialState: IInitialState = {
    isLoading: false,
    // isLoadingSection: false,
    TemplatesData: [],
};

const Templates = createSlice({
    name: 'templates',
    initialState,
    reducers: {
        create: (state, action) => {
            state.TemplatesData.unshift(action.payload);
        },
        update: (state, action) => {
            state.TemplatesData = state.TemplatesData.map((el) =>
                el._id === action.payload._id ? { ...el, ...action.payload } : el,
            );
        },
        updateOne: (state, action) => {
            state.TemplatesData = state.TemplatesData.map((el) =>
                el._id === action.payload.id ? { ...el, enabled: action.payload.enabled } : el,
            );
        },
        remove: (state, action) => {
            state.TemplatesData = state.TemplatesData.filter((el) => el._id !== action.payload);
        },

        duplicateGroup: (state, action) => {
            state.TemplatesData = action.payload;
        },
        removeGroup: (state, action) => {
            action.payload.map(
                (siteID: string) =>
                    (state.TemplatesData = state.TemplatesData.filter(
                        (legal) => legal._id !== siteID,
                    )),
            );
        },

        // ==== SECTIONS ====
        sectionCreate: (state, action) => {
            state.TemplatesData.find(
                (template) =>
                    template._id === action.payload.templateId &&
                    template.sections.push(action.payload.section),
            );
        },
        sectionsUpdate: (state, action) => {
            state.TemplatesData.find(
                (template) =>
                    template._id === action.payload.templateId &&
                    template.sections.find(
                        (section) =>
                            section._id === action.payload.sectionId &&
                            (section.title = action.payload.title),
                    ),
            );
        },
        sectionsDelete: (state, action) => {
            state.TemplatesData.find(
                (template) =>
                    template._id === action.payload.templateId &&
                    (template.sections = template.sections.filter(
                        (section) => section._id !== action.payload.sectionId,
                    )),
            );
        },

        sectionStatus: (state, action) => {
            const { template_id, section_id, status } = action.payload;

            state.TemplatesData.find(
                (template) =>
                    template._id === template_id &&
                    template.sections.find(
                        (section) => section._id === section_id && (section.status = status),
                    ),
            );
        },

        // DRAG & DROP
        dragAndDropSection: (state, action) => {
            state.TemplatesData.find(
                (template) =>
                    template._id === action.payload.template_id &&
                    (template.sections = action.payload.sections),
            );
        },
        // ./DRAG & DROP

        // ==== ./SECTIONS ====

        // FIELD
        fieldCreate: (state, action) => {
            const { main_id, section_id, field } = action.payload;
            console.log('action.payload', action.payload);

            state.TemplatesData.find(
                (template) =>
                    template._id === main_id &&
                    template.sections.find(
                        (section) => section._id === section_id && section.fields.push(field),
                    ),
            );
        },

        fieldUpdate: (state, action) => {
            const { main_id, section_id, fieldId, information, content } = action.payload;

            const templateToUpdate = state.TemplatesData.find(
                (template) => template._id === main_id,
            );
            if (!templateToUpdate) return;

            const sectionToUpdate = templateToUpdate.sections.find(
                (section) => section._id === section_id,
            );
            if (!sectionToUpdate) return;

            const fieldToUpdate = sectionToUpdate.fields.find((field) => field._id === fieldId);
            if (!fieldToUpdate) return;

            fieldToUpdate.field_type = information.field_type;
            fieldToUpdate.field_name = information.field_name;
            fieldToUpdate.content = content;
        },

        fieldDelete: (state, action) => {
            const { mainId, sectionId, fieldId } = action.payload;
            state.TemplatesData.find(
                (template) =>
                    template._id === mainId &&
                    template.sections.find(
                        (section) =>
                            section._id === sectionId &&
                            (section.fields = section.fields.filter(
                                (field) => field._id !== fieldId,
                            )),
                    ),
            );
        },
        // ./FIELD

        // DRAG & DROP
        dragAndDrop: (state, action) => {
            state.TemplatesData.find(
                (template) =>
                    template._id === action.payload.template_id &&
                    template.sections.find(
                        (section) =>
                            section._id === action.payload.section_id &&
                            (section.fields = action.payload.fields),
                    ),
            );
        },
        // ./DRAG & DROP
    },
    extraReducers: (build) => {
        build
            .addCase(getTemplates.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTemplates.fulfilled, (state, action) => {
                state.TemplatesData = action.payload;
                state.isLoading = false;
            })
            .addCase(getTemplates.rejected, (state, action) => {
                state.isLoading = false;
            });
    },
});

export default Templates.reducer;

export const {
    create,
    remove,
    update,
    updateOne,
    duplicateGroup,
    removeGroup,

    sectionCreate,
    sectionsUpdate,
    sectionsDelete,
    sectionStatus,
    dragAndDropSection,

    fieldCreate,
    fieldUpdate,
    fieldDelete,
    dragAndDrop,
} = Templates.actions;
