import { FC, ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';
import * as Page from '../views/Authorized';

interface IRoutes {
    path: string;
    element: ReactElement;
}

export const ROUT_PATH = {
    HOME: '/',

    ORDERS: '/orders',
    ORDER_CONTENT: '/order/:id',
    ORDER_FIELDS: '/order/:id/:id',

    PRODUCTS: '/products',
    PRODUCTS_CREATE: '/products/create',
    PRODUCTS_EDIT: '/product/:id',

    TEMPLATES: '/templates',
    TEMPLATES_CREATE: '/template/create',
    TEMPLATES_SECTION: '/template/:id',
    TEMPLATES_FIELDS: '/template/:id/:id',

    TELEGRAM_BOT: '/telegram-bot',
    VIBER_BOT: '/viber-bot',

    USERS: '/users',
    USERS_EDIT_ID: '/users/edit/:userId',
    USERS_CREATE: '/users/create',

    MY_PROFILE: '/my-profile',

    SETTINGS: '/settings',

    ALL: '*',
};

const ROUTES: IRoutes[] = [
    {
        path: ROUT_PATH.HOME,
        element: <Page.HomePage />,
    },
    {
        path: ROUT_PATH.ORDERS,
        element: <Page.OrdersPage />,
    },
    {
        path: ROUT_PATH.ORDER_CONTENT,
        element: <Page.LandingContent />,
    },
    {
        path: ROUT_PATH.PRODUCTS,
        element: <Page.ProductsPage />,
    },
    {
        path: ROUT_PATH.PRODUCTS_CREATE,
        element: <Page.ProductCreate />,
    },
    {
        path: ROUT_PATH.PRODUCTS_EDIT,
        element: <Page.ProductEdit />,
    },
    {
        path: ROUT_PATH.TEMPLATES,
        element: <Page.TemplatesPage />,
    },
    {
        path: ROUT_PATH.TEMPLATES_CREATE,
        element: <Page.TemplatesCreate />,
    },
    {
        path: ROUT_PATH.TEMPLATES_SECTION,
        element: <Page.TemplatesContent />,
    },
    {
        path: ROUT_PATH.TEMPLATES_FIELDS,
        element: <Page.FieldsContent />,
    },

    {
        path: ROUT_PATH.VIBER_BOT,
        element: <Page.ViberBotPage />,
    },
    {
        path: ROUT_PATH.TELEGRAM_BOT,
        element: <h1>Телеграм</h1>,
    },
    {
        path: ROUT_PATH.USERS,
        element: <Page.UsersPage />,
    },
    {
        path: ROUT_PATH.USERS_EDIT_ID,
        element: <Page.UsersEdit />,
    },
    {
        path: ROUT_PATH.USERS_CREATE,
        element: <Page.UserCreate />,
    },
    {
        path: ROUT_PATH.MY_PROFILE,
        element: <Page.MyProfilePage />,
    },
    {
        path: ROUT_PATH.SETTINGS,
        element: <Page.SettingsPage />,
    },
    {
        path: ROUT_PATH.ALL,
        element: <Page.NotFoundPage />,
    },
];

export const Authorized: FC = () => (
    <Routes>
        {ROUTES.map((route, index) => (
            <Route key={index} {...route} />
        ))}
    </Routes>
);
