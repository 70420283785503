import { configureStore, combineReducers } from '@reduxjs/toolkit';
import orders from './orders/orders.slice';
import templates from './templates/templates.slice';
import offers from './offers/offers.slice';
import usersRole from './settings/usersRole.slice';
import language from './settings/language.slice';
import users from './users/users.slice';
import auth from './auth/auth.slice';
import visits from './visits/visits.slice';
import ordersSettings from './orders/orders.settings.slice';
import products from './products/products.slice';

const reducers = combineReducers({
    orders,
    templates,
    offers,
    usersRole,
    language,
    users,
    auth,
    visits,
    ordersSettings,
    products,
});

export const store = configureStore({
    reducer: reducers,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
