import { FC, useRef, useState } from 'react';
import { Button, Col, Input, InputRef, Layout, Row, Space, Table, Typography } from 'antd';
import Highlighter from 'react-highlight-words';
import { Content } from 'antd/lib/layout/layout';
import { EmptyCustome } from '../../../../components/EmptyCustome/EmptyCustome';
import { useAppSelector } from '../../../../store/hooks/useRedux';
import { Link, useNavigate } from 'react-router-dom';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import { IProductsPage } from '../../../../types';
import { ProductDelete } from '../ProductsFrom/Product.delete';
import { ProductGroupUpdate } from '../ProductsFrom/Product.group.update';
import { FilterConfirmProps } from 'antd/es/table/interface';
import * as Icon from '@ant-design/icons';
import { IsAccess } from '../../../../components/IsAccess/IsAccess';

const LOOKS_UA = [
    { value: 'Рулонні штори', text: 'Рулонні штори' },
    { value: 'День-ніч', text: 'День-ніч' },
    { value: 'Римські штори', text: 'Римські штори' },
    {
        value: 'Горизонтальні жалюзі',
        text: 'Горизонтальні жалюзі',
    },
    {
        value: 'Вертикальні жалюзі',
        text: 'Вертикальні жалюзі',
    },
    { value: 'Пліссе', text: 'Пліссе' },
];

const MODELS_UA = [
    {
        value: 'Однотонні',
        text: 'Однотонні',
    },
    {
        value: 'Фактурні',
        text: 'Фактурні',
    },
    {
        value: 'BleackOut',
        text: 'BleackOut',
    },
    {
        value: 'Принт',
        text: 'Принт',
    },
    {
        value: 'Джутові',
        text: 'Джутові',
    },
    {
        value: 'Бамбукові',
        text: 'Бамбукові',
    },
    {
        value: 'Алюмінієві',
        text: 'Алюмінієві',
    },
    {
        value: "Дерев'яні",
        text: "Дерев'яні",
    },
    {
        value: 'Всі',
        text: 'Всі',
    },
];

const MARKERS = [
    {
        value: 'Хіт',
        text: 'Хіт',
    },
    {
        value: 'Акція',
        text: 'Акція',
    },
    {
        value: 'Новинка',
        text: 'Новинка',
    },
];

interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
}

type DataIndex = keyof DataType;

export const ProductsPage: FC = () => {
    const [usePageSize, setPageSize] = useState(30);
    const navigate = useNavigate();
    const { ProductsData, isLoading } = useAppSelector((state) => state.products);
    const isAccess = IsAccess();

    // Search Name
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 10 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input.Search
                    ref={searchInput}
                    placeholder="Пошук по назві"
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    onSearch={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ width: 300 }}
                />
            </div>
        ),
        filterIcon: (filtered: boolean) => <Icon.SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
        // @ts-ignore
        onFilter: (value, record) =>
            // @ts-ignore
            record?.name?.props?.children
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.props.children ? text.props.children.toString() : ''}
                />
            ) : (
                text.props.children
            ),
    });

    // ./Search Name

    const Data = ProductsData.map((product) => ({
        key: product._id,
        name: <Link to={`/product/${product._id}`}>{product?.UA.name}</Link>,
        model: product.UA.model,
        look: product.UA.look,
        markers: product.UA.markers.join(', '),
        pricce: product.UA.pricce + 'грн',
    }));

    const columns: ColumnsType<IProductsPage> = [
        {
            // @ts-ignore
            title: 'Назва',
            dataIndex: 'name',
            ...getColumnSearchProps('name'),
        },

        {
            title: 'Вид',
            dataIndex: 'look',
            filters: LOOKS_UA.map((product) => product),
            width: '12rem',
            filterSearch: true,
            ellipsis: true,
            onFilter: (look: string | any, products: any) => products.look.startsWith(look),
        },
        {
            title: 'Модель',
            dataIndex: 'model',
            filters: MODELS_UA.map((model) => model),
            width: '10rem',
            filterSearch: true,
            ellipsis: true,
            onFilter: (model: string | any, products: any) => products.model.startsWith(model),
        },
        {
            title: 'Маркер',
            dataIndex: 'markers',
            filters: MARKERS.map((mark) => mark),
            width: '12rem',
            filterMultiple: true,
            onFilter: (value, record) => record.markers.includes(value),
        },
        {
            title: 'Ціна',
            dataIndex: 'pricce',
            width: '6rem',
        },

        {
            title: 'Дія',
            key: 'action',
            width: '100px',
            fixed: 'right',
            className: 'offer__action',
            render: (_, product) => {
                return (
                    <Space size="small">
                        <Button
                            type="text"
                            icon={<Icon.EditOutlined />}
                            onClick={() => navigate(`/product/${product.key}`)}
                            disabled={!isAccess?.products.edit}
                        />
                        <ProductDelete ProductId={product.key} />
                    </Space>
                );
            },
        },
    ];

    return (
        <Layout>
            <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
                <Col>
                    <Space>
                        <Typography.Title level={4} style={{ margin: 0 }}>
                            Тканини
                        </Typography.Title>
                    </Space>
                </Col>
                <Col>
                    <Space>
                        <Button
                            icon={<Icon.PlusOutlined />}
                            loading={isLoading}
                            type="primary"
                            onClick={() => navigate('create')}
                            disabled={!isAccess?.products.create}
                        />
                    </Space>
                </Col>
            </Row>
            <Content className="site-layout-background main_content main_section">
                <EmptyCustome style={{ height: 'calc(100vh - 330px)' }}>
                    <Table
                        scroll={{ y: 'calc(100vh - 16rem)', x: 1200 }}
                        rowSelection={ProductGroupUpdate()}
                        loading={isLoading}
                        // @ts-ignore
                        columns={columns}
                        dataSource={Data}
                        pagination={{
                            defaultPageSize: 15,
                            showSizeChanger: true,
                            className: 'table_pagination',
                            pageSize: usePageSize,
                            pageSizeOptions: [15, 20, 30, 40, 50, 100],
                            onShowSizeChange: (_, size) => setPageSize(size),
                        }}
                    />
                </EmptyCustome>
            </Content>
        </Layout>
    );
};
