import { FC, useMemo } from 'react';
import * as SVG from '../../assets/images/svg/svg';

interface IProps {
    fieldType: string;
}

interface IIcon {
    field: string;
    icon: React.ReactNode;
}

const IconSize = { x: 40, y: 40, style: { marginRight: 5 } };

export const ICONS: IIcon[] = [
    { field: 'Text', icon: <SVG.IconText {...IconSize} /> },
    { field: 'RichText', icon: <SVG.IconRichText {...IconSize} /> },
    { field: 'Image', icon: <SVG.IconImage {...IconSize} /> },
    { field: 'Links', icon: <SVG.IconLink {...IconSize} /> },
    { field: 'Video', icon: <SVG.IconVideo {...IconSize} /> },
    { field: 'List', icon: <SVG.IconList {...IconSize} /> },
    { field: 'Question', icon: <SVG.IconQuestion {...IconSize} /> },
    { field: 'Testimonial', icon: <SVG.IconComment {...IconSize} /> },
    { field: 'Code', icon: <SVG.IconCode {...IconSize} /> },
    { field: 'Button', icon: <SVG.IconButton {...IconSize} /> },
    { field: 'Features', icon: <SVG.IconFeatures {...IconSize} /> },
    { field: 'Step', icon: <SVG.IconStep {...IconSize} /> },
    { field: 'FullWidth', icon: <SVG.IconFullWidth {...IconSize} /> },
    { field: 'Slider', icon: <SVG.IconImage {...IconSize} /> },
];

export const FieldIcon: FC<IProps> = ({ fieldType }) => {
    const icon = useMemo(() => ICONS.find((icon) => icon.field === fieldType)?.icon, [fieldType]);
    return <span style={{ display: 'flex', marginRight: 10 }}>{icon}</span>;
};
