import { FC } from 'react';
import { Input } from 'antd';
import { useAppSelector } from '../../../../../store/hooks/useRedux';
import { SearchOutlined } from '@ant-design/icons';

import { IUsers } from '../../../../../types';

import './Users.search.scss';

interface IUserSearch {
    setSearch: (obj: IUsers[]) => void;
    searchName: IUsers[];
}

export const UserSearch: FC<IUserSearch> = ({ setSearch, searchName }) => {
    const { users } = useAppSelector((state) => state.users);

    const handleChange = (value: string) => {
        const filterUsers = searchName.filter((user) =>
            (user.firstName + user.lastName).toLowerCase().includes(value),
        );

        setSearch(!!value.length ? filterUsers : users);
    };

    return (
        <div className="user_page__search">
            <Input
                placeholder="Пошук"
                suffix={<SearchOutlined style={{ color: '#71727b' }} />}
                style={{ marginTop: '20px' }}
                onChange={(input) => handleChange(input.target.value)}
            />
        </div>
    );
};
