import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../../axios';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/useRedux';
import {
    Button,
    Col,
    Form,
    Input,
    message,
    Modal,
    Row,
    Upload,
    UploadFile,
    UploadProps,
} from 'antd';
import * as Template from '../../../../store/templates/templates.slice';
import TextArea from 'antd/lib/input/TextArea';

import * as Icon from '@ant-design/icons';
import { SERVER_URL } from '../../../../config';

interface IValue {
    name: String;
    template_pack: String;
    language: String;
    description: String;
}

const key = 'updatable';

export const TemplatesSettings: FC = () => {
    const dispatch = useAppDispatch();
    const { id: TEMPLATE_PAGE_ID } = useParams();
    const TEMPLATE_PAGE = useAppSelector((state) => state.templates.TemplatesData).find(
        (template) => template._id === TEMPLATE_PAGE_ID,
    );
    const [isLoading, setLoading] = useState(false);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [fileAvatar, setAvatarList] = useState<UploadFile[]>(
        TEMPLATE_PAGE?.screenshot
            ? [
                  {
                      uid: '1',
                      name: 'Image',
                      status: 'done',
                      url: TEMPLATE_PAGE?.screenshot,
                  },
              ]
            : [],
    );

    const avatarUrl = fileAvatar
        ? fileAvatar[0]?.url
            ? TEMPLATE_PAGE?.screenshot
            : fileAvatar[0]?.response?.url
            ? SERVER_URL + fileAvatar[0]?.response?.url
            : ''
        : '';

    const onChangeAvatar: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
        try {
            setAvatarList(newFileList);
        } catch (e) {
            console.log('Сталась помилка при завантажені зображення!', e);
        }
    };

    const fetchUpdate = async (values: IValue) => {
        message.loading({ content: 'Завантаження...', key });
        setLoading(true);
        try {
            const { data } = await axios.patch(`/api/template/${TEMPLATE_PAGE_ID}`, {
                ...values,
                screenshot: avatarUrl,
            });

            if (data.success) {
                dispatch(Template.update(data.template));
                message.success({
                    content: 'Збережено!',
                    key,
                    duration: 2,
                });
                setLoading(false);
                return;
            } else {
                message.error({ content: 'Помилка!', key, duration: 2 });
                setLoading(false);
                return;
            }
        } catch (e) {
            message.error({ content: 'Помилка!', key, duration: 2 });
            setLoading(false);
            return;
        }
    };

    if (!TEMPLATE_PAGE) {
        return <h1>Завантаження...</h1>;
    }

    return (
        <Row gutter={[10, 10]}>
            <Col span={24} style={{ height: 'calc(100vh - 240px)', overflow: 'scroll' }}>
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={fetchUpdate}
                    layout="vertical"
                    size="middle"
                    autoComplete="off">
                    <Row gutter={[10, 10]}>
                        {/* Name */}
                        <Col className="gutter-row" span={24}>
                            <Form.Item
                                name="name"
                                label="Назва"
                                hasFeedback
                                initialValue={TEMPLATE_PAGE?.name}
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message: '',
                                    },
                                    {
                                        type: 'string',
                                        min: 1,
                                        message: '',
                                    },
                                ]}>
                                <Input placeholder="Введіть назву" allowClear />
                            </Form.Item>
                        </Col>

                        {/* Description */}
                        <Col className="gutter-row" span={24} md={{ span: 24 }}>
                            <Form.Item
                                label="Опис"
                                name="description"
                                initialValue={TEMPLATE_PAGE?.description}
                                hasFeedback>
                                <TextArea
                                    allowClear
                                    placeholder="Введіть текст"
                                    autoSize={{ minRows: 3, maxRows: 20 }}
                                />
                            </Form.Item>
                        </Col>

                        {/* Image */}
                        <Col className="gutter-row">
                            <Form.Item hasFeedback>
                                <Upload
                                    accept="jpeg jpg png"
                                    action={`${SERVER_URL}/upload`}
                                    listType="picture"
                                    maxCount={1}
                                    fileList={fileAvatar}
                                    onChange={onChangeAvatar}
                                    onPreview={() => setPreviewOpen(true)}>
                                    {fileAvatar.length < 1 && (
                                        <Button icon={<Icon.UploadOutlined />}>
                                            Завантажити зображення
                                        </Button>
                                    )}
                                </Upload>
                                <Modal
                                    open={previewOpen}
                                    title={'Image'}
                                    footer={null}
                                    onCancel={() => setPreviewOpen(false)}>
                                    <img
                                        alt="example"
                                        style={{ width: '100%' }}
                                        src={
                                            fileAvatar[0]?.response?.url
                                                ? fileAvatar[0]?.response?.url
                                                : TEMPLATE_PAGE?.screenshot
                                        }
                                    />
                                </Modal>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24}>
                            <Button
                                htmlType="submit"
                                icon={<Icon.SaveOutlined />}
                                loading={isLoading}
                                type="primary">
                                Зберегти
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};
