import { FC, useState } from 'react';
import { Button, message, Modal, Typography } from 'antd';
import { useAppDispatch } from '../../../../store/hooks/useRedux';
import axios from '../../../../axios';
import * as Template from '../../../../store/templates/templates.slice';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { IsAccess } from '../../../../components/IsAccess/IsAccess';

interface IProps {
    templateId: React.Key;
}

const key = 'delete';

export const TemplatesDelete: FC<IProps> = ({ templateId }) => {
    const [isLoading, setLoading] = useState(false);
    const isAccess = IsAccess();
    const dispatch = useAppDispatch();

    const handleDelete = async () => {
        message.loading({ content: 'Завантаження...', key });
        if (!isAccess?.templates.delete) {
            return message.error({ content: 'Видалення заборонено!', key });
        }
        setLoading(true);
        try {
            // Видалення Template
            const { data } = await axios.delete(`/api/template/${templateId}`);
            if (data.success) {
                dispatch(Template.remove(templateId));
                message.success({
                    content: 'Шаблон успішно видаленний!',
                    key,
                    duration: 2,
                });
                setLoading(false);
            } else {
                message.error({ content: 'Сталась помилка!', key, duration: 2 });
                setLoading(false);
            }
        } catch (e) {
            message.error({ content: 'Сталась помилка!', key, duration: 2 });
            setLoading(false);
        }
    };

    const showModalDelete = () => {
        Modal.confirm({
            title: 'Видалення!',
            content: (
                <>
                    <div>Ви впевнені що хочете видалити шаблон? </div>
                    <div>
                        <Typography.Text type="danger">Ви не зможете його відновити!</Typography.Text>
                    </div>
                </>
            ),
            icon: <ExclamationCircleOutlined />,
            okText: 'Так',
            okType: 'danger',
            cancelText: 'Ні',
            onOk() {
                handleDelete();
            },
        });
    };

    return <Button type="text" loading={isLoading} icon={<DeleteOutlined />} disabled={!isAccess?.templates.delete} onClick={showModalDelete} />;
};
