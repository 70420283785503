import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../axios';
import { IProductsValueForm } from '../../types';

interface IInitialState {
    ProductsData: IProductsValueForm[];
    isLoading: boolean;
}

export const getProducts = createAsyncThunk('products/getProducts', async (_, { rejectWithValue }) => {
    try {
        const { data } = await axios.get('/api/products');
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

const initialState: IInitialState = {
    isLoading: false,
    ProductsData: [],
};

const Products = createSlice({
    name: 'products',
    initialState,
    reducers: {
        create: (state, action) => {
            state.ProductsData.unshift(action.payload);
        },

        update: (state, action) => {
            state.ProductsData = state.ProductsData.map((product) =>
                product._id === action.payload._id ? { ...product, ...action.payload } : product,
            );
        },

        remove: (state, action) => {
            state.ProductsData = state.ProductsData.filter((product) => product._id !== action.payload);
        },

        duplicateGroup: (state, action) => {
            state.ProductsData = action.payload;
        },

        removeGroup: (state, action) => {
            action.payload.map((id: string) => (state.ProductsData = state.ProductsData.filter((product) => product._id !== id)));
        },
    },
    extraReducers: (build) => {
        build
            .addCase(getProducts.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.ProductsData = action.payload;
                state.isLoading = false;
            })
            .addCase(getProducts.rejected, (state, action) => {
                state.isLoading = false;
            });
    },
});

export default Products.reducer;

export const {
    create,
    update,
    remove,

    duplicateGroup,
    removeGroup,
} = Products.actions;
