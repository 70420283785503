import { FC, useState } from 'react';
import axios from '../../../axios';
import { Button, Col, Form, Input, message, Row, Tabs } from 'antd';
import { useAppDispatch } from '../../../store/hooks/useRedux';

import { IFieldUpdateProps } from '../../../types';

import { DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';

interface IValue {
    name: string;
    list: string[];
}

const key = 'updatable';

export const FieldListUpdate: FC<IFieldUpdateProps> = ({
    field,
    main_id,
    section_id,
    request_url,
    fieldUpdate,
}) => {
    const [isLoadingForm, setIsLoadingForm] = useState(false);

    const dispatch = useAppDispatch();

    const [form] = Form.useForm();

    const handleUpdateField = async ({ name, ...other }: IValue) => {
        setIsLoadingForm(true);
        message.loading({ content: 'Завантаження...', key });
        try {
            const updateField = {
                main_id,
                section_id,
                fieldId: field._id,
                information: {
                    field_type: 'List',
                    field_name: name,
                },
                content: { ...field.content, ...other },
            };

            await axios.patch(request_url, updateField);

            dispatch(fieldUpdate(updateField));

            setIsLoadingForm(false);
            message.success({ content: 'Збережено!', key, duration: 2 });
        } catch (e) {
            setIsLoadingForm(false);
            message.error({ content: 'Помилка!', key, duration: 2 });
        }
    };

    return (
        <Form
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            onFinish={handleUpdateField}
            size="middle"
            layout="vertical"
            autoComplete="off">
            <div style={{ marginBottom: 5 }}>Назва поля</div>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item
                        name="name"
                        label="Назва поля"
                        initialValue={field.field_name}
                        rules={[{ required: true, message: '' }]}>
                        <Input placeholder="Введіть назву поля" size="middle" />
                    </Form.Item>
                </Col>
            </Row>
            <hr style={{ border: '0.1px solid #00000014' }} />

            <div style={{ marginBottom: 5, marginTop: 10 }}>Контент</div>
            <Tabs
                className="tab-card-content-admin"
                size="small"
                tabPosition="top"
                defaultActiveKey="UA"
                type="card">
                <Tabs.TabPane tab={<span>🇺🇦 UA</span>} key="UA">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.List name={['UA', 'list']} initialValue={field.content.UA.list}>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Row
                                                key={key}
                                                align="middle"
                                                justify="center"
                                                style={{ marginTop: 5 }}>
                                                <Col
                                                    span={24}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}>
                                                    🟧
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'item']}
                                                        style={{
                                                            width: '100%',
                                                            margin: '0 10px',
                                                        }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '',
                                                            },
                                                        ]}>
                                                        <Input placeholder="Введіть текст" />
                                                    </Form.Item>
                                                    <Button
                                                        icon={
                                                            <DeleteOutlined
                                                                style={{ fontSize: 14 }}
                                                            />
                                                        }
                                                        onClick={() => remove(name)}
                                                    />
                                                </Col>
                                            </Row>
                                        ))}
                                        <Form.Item style={{ marginTop: 10 }}>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}>
                                                Додати елемент
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span>🇷🇺 RU</span>} key="RU">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.List name={['RU', 'list']} initialValue={field.content.RU.list}>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Row
                                                key={key}
                                                align="middle"
                                                justify="center"
                                                style={{ marginTop: 5 }}>
                                                <Col
                                                    span={24}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}>
                                                    🟢
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'item']}
                                                        style={{
                                                            width: '100%',
                                                            margin: '0 10px',
                                                        }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '',
                                                            },
                                                        ]}>
                                                        <Input placeholder="Введите текст" />
                                                    </Form.Item>
                                                    <Button
                                                        icon={
                                                            <DeleteOutlined
                                                                style={{ fontSize: 14 }}
                                                            />
                                                        }
                                                        onClick={() => remove(name)}
                                                    />
                                                </Col>
                                            </Row>
                                        ))}
                                        <Form.Item style={{ marginTop: 10 }}>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}>
                                                Добавить елемент
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                </Tabs.TabPane>
            </Tabs>
            <Button loading={isLoadingForm} type="primary" onClick={() => form.submit()}>
                <SaveOutlined /> Зберегти
            </Button>
        </Form>
    );
};
