import { FC, useState } from 'react';
import { Button, message, Modal } from 'antd';
import axios from '../../../../../../axios';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks/useRedux';
import { deleteRole } from '../../../../../../store/settings/usersRole.slice';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

interface IProps {
    roleId: string;
}

const key = 'deleted';

export const RoleDelete: FC<IProps> = ({ roleId }) => {
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useAppDispatch();
    const { users } = useAppSelector((state) => state.users);
    const activeUserRole = users.filter((el) => el.roleId === roleId);

    const handleDeleteRole = async () => {
        setIsLoading(true);
        message.loading({ content: 'Завантаження...', key });
        try {
            const { data } = await axios.delete(`/api/roles/${roleId}`);
            if (data.success) {
                dispatch(deleteRole(roleId));
                setIsLoading(false);
                message.success({ content: 'Видалено!', key, duration: 2 });
            }
        } catch (error) {
            setIsLoading(false);
            message.error({ content: `Помилка! ${error}`, key, duration: 2 });
        }
    };

    const showMessage = () =>
        message.error({
            content: `Ви не можете видалити посаду поки в ній є користувачі: (${activeUserRole.map((el) => el.firstName).join(', ')}).`,
            key,
            duration: 4,
        });

    const showModalDelete = () => {
        Modal.confirm({
            title: 'Увага!',
            content: `Ви впевнені, що хочете видалити?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Так',
            okType: 'danger',
            cancelText: 'Ні',
            onOk() {
                handleDeleteRole();
            },
        });
    };

    return (
        <Button loading={isLoading} size="small" onClick={() => (activeUserRole.length ? showMessage() : showModalDelete())}>
            <DeleteOutlined />
        </Button>
    );
};
