import { FC, useState } from 'react';
import axios from '../../../axios';
import { Button, Col, Form, Input, message, Row, Tabs } from 'antd';
import { useAppDispatch } from '../../../store/hooks/useRedux';
import TextArea from 'antd/lib/input/TextArea';
import { IFieldUpdateProps } from '../../../types/index';
import { SaveOutlined } from '@ant-design/icons';

interface IValue {
    name: string;
    code: string;
}

const key = 'updatable';

export const FieldCodeUpdate: FC<IFieldUpdateProps> = ({
    field,
    main_id,
    section_id,
    request_url,
    fieldUpdate,
}) => {
    const [isLoadingForm, setIsLoadingForm] = useState(false);

    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    const handleUpdateField = async ({ name, ...other }: IValue) => {
        setIsLoadingForm(true);
        message.loading({ content: 'Завантаження...', key });
        try {
            const updateField = {
                main_id,
                section_id,
                fieldId: field._id,
                information: {
                    field_type: 'Code',
                    field_name: name,
                },
                content: { ...field.content, ...other },
            };

            await axios.patch(request_url, updateField);

            dispatch(fieldUpdate(updateField));

            setIsLoadingForm(false);
            message.success({ content: 'Збережено!', key, duration: 2 });
        } catch (e) {
            setIsLoadingForm(false);
            message.error({ content: 'Помилка!', key, duration: 2 });
        }
    };

    return (
        <Form
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            onFinish={handleUpdateField}
            size="middle"
            layout="vertical"
            autoComplete="off">
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Form.Item
                        label="Назва поля"
                        name="name"
                        initialValue={field.field_name}
                        rules={[{ required: true, message: '' }]}>
                        <Input placeholder="Введіть назву поля" size="middle" />
                    </Form.Item>
                </Col>
            </Row>

            <hr style={{ border: '0.1px solid #00000014' }} />

            <div style={{ marginBottom: 5, marginTop: 10 }}>Контент</div>
            <Tabs
                className="tab-card-content-admin"
                size="small"
                tabPosition="top"
                defaultActiveKey="UA"
                type="card">
                <Tabs.TabPane tab={<span>🇺🇦 UA</span>} key="UA">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item name={['UA', 'code']} initialValue={field.content.UA.code}>
                                <TextArea
                                    showCount
                                    style={{ height: 300 }}
                                    placeholder="Введіть <код>"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span>🇷🇺 RU</span>} key="RU">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item name={['RU', 'code']} initialValue={field.content.RU.code}>
                                <TextArea
                                    showCount
                                    style={{ height: 300 }}
                                    placeholder="Введите <код>"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Tabs.TabPane>
            </Tabs>
            <Button loading={isLoadingForm} type="primary" onClick={() => form.submit()}>
                <SaveOutlined /> Зберегти
            </Button>
        </Form>
    );
};
