import { Col, Modal, Row, Typography, Upload, UploadProps } from 'antd';
import { UploadFile } from 'antd/lib';
import { FC, useState } from 'react';
import { SERVER_URL } from '../../../../../config';
import { PlusOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface IProps {
    fileGallery: any;
    onChangeGallery: any;
    onChangeUpdateName: any;
    GALLERY: any;
    setGalleryList: any;
}

interface DraggableUploadListItemProps {
    originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    file: UploadFile<any>;
}

const DraggableUploadListItem = ({ originNode, file }: DraggableUploadListItemProps) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: file.uid,
    });

    const style: React.CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: 'move',
        height: '102px',
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            // prevent preview event when drag end
            className={isDragging ? 'is-dragging' : ''}
            {...attributes}
            {...listeners}>
            {/* hide error tooltip when dragging */}
            {file.status === 'error' && isDragging ? originNode.props.children : originNode}
        </div>
    );
};

export const ProductGallery: FC<IProps> = ({ fileGallery, setGalleryList, onChangeGallery, onChangeUpdateName, GALLERY }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [previewColor, setPreviewColor] = useState('');

    const sensor = useSensor(PointerSensor, {
        activationConstraint: { distance: 10 },
    });

    console.log('GALLERY', GALLERY);

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setGalleryList((prev: any) => {
                const activeIndex = prev.findIndex((i: any) => i.uid === active.id);
                const overIndex = prev.findIndex((i: any) => i.uid === over?.id);
                return arrayMove(prev, activeIndex, overIndex);
            });
        }
    };

    const handlePreview = async (file: UploadFile) => {
        setPreviewImage(file.url || SERVER_URL + file.response?.url);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
        const imageInfo = GALLERY.find((el: any) => el.originalname === file.name);

        setPreviewColor(imageInfo?.color);
    };

    const fetchUpdateName = (art: string, color: string) => {
        setPreviewTitle(art);
        setPreviewColor(color);
        onChangeUpdateName(previewTitle, art, color);
    };

    const modalClose = () => {
        setPreviewOpen(false);
        setPreviewImage('');
        setPreviewTitle('');
        setPreviewColor('');
    };

    return (
        <Row>
            <Col>
                <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                    <SortableContext items={fileGallery.map((i: any) => i.uid)} strategy={verticalListSortingStrategy}>
                        <Upload
                            accept="jpeg jpg png mp4 MOV"
                            action={`${SERVER_URL}/upload`}
                            listType="picture-card"
                            multiple
                            fileList={fileGallery}
                            onChange={onChangeGallery}
                            onPreview={handlePreview}
                            itemRender={(originNode, file) => <DraggableUploadListItem originNode={originNode} file={file} />}>
                            <PlusOutlined />
                        </Upload>
                    </SortableContext>
                </DndContext>
                <Modal open={previewOpen} footer={null} onCancel={modalClose}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    <Row gutter={[10, 10]}>
                        <Col span={12}>
                            Артикул:
                            <Typography.Paragraph
                                editable={{
                                    triggerType: ['text', 'icon'],
                                    onChange: (art) => fetchUpdateName(art, previewColor),
                                }}>
                                {previewTitle}
                            </Typography.Paragraph>
                        </Col>
                        <Col span={12}>
                            Колір: 🇺🇦/🇷🇺
                            <Typography.Paragraph
                                editable={{
                                    triggerType: ['text', 'icon'],
                                    onChange: (color) => fetchUpdateName(previewTitle, color),
                                }}>
                                {previewColor}
                            </Typography.Paragraph>
                        </Col>
                    </Row>
                </Modal>
            </Col>
        </Row>
    );
};
