import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';

import { ILanguage } from '../../types';

export const getCountry = createAsyncThunk('language/country', async (_, { rejectWithValue }) => {
    try {
        const { data } = await axios.get('/api/language');

        return data;
    } catch (err) {
        return rejectWithValue(err);
    }
});

interface IInitialState {
    isLoading: boolean;
    langData: ILanguage[];
}

const initialState: IInitialState = {
    isLoading: false,
    langData: [],
};

const language = createSlice({
    name: 'language',
    initialState,
    reducers: {
        update: (state, action) => {
            state.langData = state.langData.map((language) =>
                language._id === action.payload.id
                    ? { ...language, enabled: action.payload.status }
                    : language,
            );
        },
    },
    extraReducers: (build) => {
        build
            .addCase(getCountry.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCountry.fulfilled, (state, action) => {
                state.isLoading = false;
                state.langData = action.payload;
            })
            .addCase(getCountry.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export default language.reducer;
export const { update } = language.actions;
