import { FC, useState } from 'react';
import axios from '../../../axios';
import { Button, Col, Form, Input, message, Modal, Row, Tabs, Typography } from 'antd';
import { useAppDispatch } from '../../../store/hooks/useRedux';
import { ArrowLeftOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import * as SVG from '../../../assets/images/svg/svg';
import { IFieldCreateProps, ILink } from '../../../types/index';

interface IValue {
    name: string;
    links: ILink[];
}

const key = 'creatable';

export const FieldLinksCreate: FC<IFieldCreateProps> = ({
    main_id,
    section_id,
    request_url,
    fieldCreate,
    handleModal,
}) => {
    const [isModal, setIsModal] = useState(false);
    const [isLoadingForm, setIsLoadingForm] = useState(false);

    const dispatch = useAppDispatch();

    const [form] = Form.useForm();

    const handleCreateFields = async ({ name, ...other }: IValue) => {
        setIsLoadingForm(true);
        message.loading({ content: 'Завантаження...', key });
        try {
            const { data } = await axios.post(request_url, {
                main_id,
                section_id,
                information: {
                    field_type: 'Links',
                    field_name: name,
                },
                content: { UA: [], RU: [], ...other },
            });

            const fieldStoreData = {
                main_id,
                section_id,
                field: data.field,
            };

            dispatch(fieldCreate(fieldStoreData));

            form.resetFields();
            setIsModal(false);
            message.success({ content: 'Збережено!', key, duration: 2 });
        } catch (e) {
            setIsLoadingForm(false);
            message.error({ content: 'Помилка!', key, duration: 2 });
        } finally {
            setIsLoadingForm(false);
        }
    };

    const onOpen = () => {
        setIsModal(true);
        handleModal(false);
    };

    const onCancel = () => {
        form.resetFields();
        setIsModal(false);
    };

    const onBack = () => {
        setIsModal(false);
        handleModal(true);
    };

    return (
        <>
            <Col onClick={onOpen} sm={6} span={8}>
                <Row className="modal_fields_button">
                    <Col span={24}>
                        <SVG.IconLink x={40} y={40} />
                    </Col>
                    <Col span={24}>
                        <Typography.Text>LINKS</Typography.Text>
                    </Col>
                </Row>
            </Col>

            <Modal
                okText="Зберегти"
                cancelText="Відмінити"
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            type="primary"
                            size="small"
                            icon={<ArrowLeftOutlined />}
                            onClick={onBack}
                        />
                        <span style={{ marginLeft: 10 }}>Links</span>
                    </div>
                }
                open={isModal}
                onOk={() => form.submit()}
                confirmLoading={isLoadingForm}
                onCancel={onCancel}>
                <Form
                    name="basic"
                    form={form}
                    initialValues={{ remember: true }}
                    onFinish={handleCreateFields}
                    size="middle"
                    layout="vertical"
                    autoComplete="off">
                    <hr style={{ border: '0.1px solid #00000014' }} />
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Назва поля"
                                rules={[{ required: true, message: '' }]}>
                                <Input placeholder="Введіть назву поля" size="middle" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <hr style={{ border: '0.1px solid #00000014' }} />

                    <div style={{ marginBottom: 5 }}>Контент</div>
                    <Tabs
                        className="tab-card-content-admin"
                        size="small"
                        tabPosition="top"
                        defaultActiveKey="UA"
                        type="card">
                        <Tabs.TabPane tab={<span>🇺🇦 UA</span>} key="UA">
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Form.List name={['UA', 'links']}>
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <Row
                                                        key={key}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 5,
                                                            marginBottom: 10,
                                                        }}>
                                                        <Col span={11}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'title']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: '',
                                                                    },
                                                                ]}>
                                                                <Input placeholder="Текст" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={11}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'link']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: '',
                                                                    },
                                                                ]}>
                                                                <Input
                                                                    addonBefore="URL"
                                                                    placeholder="Link"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col
                                                            span={1}
                                                            style={{ textAlign: 'center' }}>
                                                            <MinusCircleOutlined
                                                                onClick={() => remove(name)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => add()}
                                                        block
                                                        icon={<PlusOutlined />}>
                                                        Додати посилання
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<span>🇷🇺 RU</span>} key="RU">
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Form.List name={['RU', 'links']}>
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <Row
                                                        key={key}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 5,
                                                            marginBottom: 10,
                                                        }}>
                                                        <Col span={11}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'title']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: '',
                                                                    },
                                                                ]}>
                                                                <Input placeholder="Текст" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={11}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'link']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: '',
                                                                    },
                                                                ]}>
                                                                <Input
                                                                    addonBefore="URL"
                                                                    placeholder="Link"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col
                                                            span={1}
                                                            style={{ textAlign: 'center' }}>
                                                            <MinusCircleOutlined
                                                                onClick={() => remove(name)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => add()}
                                                        block
                                                        icon={<PlusOutlined />}>
                                                        Добавить ссылку
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                    </Tabs>
                </Form>
            </Modal>
        </>
    );
};
