import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../axios';
import { useAppDispatch } from '../../../../store/hooks/useRedux';
import * as Template from '../../../../store/templates/templates.slice';
import { Breadcrumb, Button, Col, Form, Input, Layout, message, Modal, Row, Upload, UploadProps } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import TextArea from 'antd/lib/input/TextArea';
import type { UploadFile } from 'antd/es/upload/interface';
import * as Icon from '@ant-design/icons';
import { SERVER_URL } from '../../../../config';

interface IValue {
    name: String;
    description: String;
    image: String;
}

const key = 'creatable';

export const TemplatesCreate: FC = () => {
    const dispatch = useAppDispatch();
    const navigation = useNavigate();
    const [isLoading, setLoading] = useState(false);

    // AVATAR
    const [previewOpen, setPreviewOpen] = useState(false);
    const [fileAvatar, setAvatarList] = useState<UploadFile[]>([]);
    const loadedAvatarUrl = fileAvatar[0]?.response?.url;

    const onChangeAvatar: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
        try {
            setAvatarList(newFileList);
        } catch (e) {
            console.log('Помилка в завантажені зображення!', e);
        }
    };
    // ./AVATAR

    const fetchCreate = async (values: IValue) => {
        message.loading({ content: 'Завантаження...', key });
        setLoading(true);
        try {
            const { data } = await axios.post('/api/template', {
                ...values,
                screenshot: loadedAvatarUrl ? SERVER_URL + loadedAvatarUrl : '',
            });

            dispatch(Template.create(data.template));

            navigation(`/template/${data.template._id}`);
            message.success({
                content: 'Збережено!',
                key,
                duration: 2,
            });
            setLoading(false);
        } catch (e) {
            message.error({ content: 'Помилка!', key, duration: 2 });
            setLoading(false);
            return;
        }
    };

    return (
        <Layout className="layout">
            <Breadcrumb className="Breadcrumb-custome">
                <Breadcrumb.Item>
                    <Button icon={<Icon.LeftOutlined />} type={'primary'} style={{ marginRight: 10 }} onClick={() => navigation('/templates')} />
                    Шаблони
                </Breadcrumb.Item>
                <Breadcrumb.Item>Новий</Breadcrumb.Item>
            </Breadcrumb>
            <Content className="site-layout-background main_content offer__content">
                <Form name="basic" initialValues={{ remember: true }} onFinish={fetchCreate} size="middle" layout="vertical" autoComplete="off">
                    <Row gutter={[10, 10]}>
                        {/* Name */}
                        <Col className="gutter-row" span={24}>
                            <Form.Item
                                name="name"
                                label="Назва"
                                hasFeedback
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message: '',
                                    },
                                    {
                                        type: 'string',
                                        min: 1,
                                        message: '',
                                    },
                                ]}>
                                <Input placeholder="Введіть назву" allowClear />
                            </Form.Item>
                        </Col>

                        {/* Description */}
                        <Col className="gutter-row" span={24} md={{ span: 24 }}>
                            <Form.Item name="description" hasFeedback>
                                <TextArea allowClear placeholder="Опис" autoSize={{ minRows: 3, maxRows: 20 }} />
                            </Form.Item>
                        </Col>

                        {/* Image */}
                        <Col className="gutter-row">
                            <Form.Item hasFeedback>
                                <Upload
                                    accept="jpeg jpg png"
                                    action={`${SERVER_URL}/upload`}
                                    listType="picture"
                                    maxCount={1}
                                    fileList={fileAvatar}
                                    onChange={onChangeAvatar}
                                    onPreview={() => setPreviewOpen(true)}>
                                    {fileAvatar.length < 1 && <Button icon={<Icon.UploadOutlined />}>Завантажити зображення</Button>}
                                </Upload>
                                <Modal open={previewOpen} title={'Image'} footer={null} onCancel={() => setPreviewOpen(false)}>
                                    <img alt="example" style={{ width: '100%' }} src={SERVER_URL + loadedAvatarUrl} />
                                </Modal>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24} md={{ span: 24 }}>
                            <Button htmlType="submit" icon={<Icon.SaveOutlined />} loading={isLoading} type="primary">
                                Зберегти
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Content>
        </Layout>
    );
};
