import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Input, Typography } from 'antd';
import { IconPwReset } from '../../../assets/images/svg/svg';
import { ArrowLeftOutlined, UserOutlined } from '@ant-design/icons';

export const ResetPasswordPage: FC = () => {
    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <section>
            <div className="auth">
                <div className="auth__wrapper">
                    <div className="auth__logo">
                        <IconPwReset />
                    </div>
                    <div className="auth__form">
                        <Typography.Title level={4} className="auth__title">
                            Забули свій пароль?
                        </Typography.Title>
                        <Typography.Text>Введіть адрес електронної пошти, і вам буде відправлено новий пароль.</Typography.Text>
                        <Form
                            name="basic"
                            wrapperCol={{ span: 24 }}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            size="middle"
                            autoComplete="off">
                            <Form.Item
                                className="auth__form-item"
                                name="username"
                                rules={[
                                    { required: true, message: 'Будь ласка, введіть ваш E-mail!' },
                                    {
                                        type: 'email',
                                        message: 'Будь ласка, введіть коректний E-mail',
                                    },
                                ]}>
                                <Input prefix={<UserOutlined />} placeholder="Email" />
                            </Form.Item>

                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Button type="primary" htmlType="submit" size="middle" style={{ width: '100%' }}>
                                    Відправити новий пароль
                                </Button>
                            </Form.Item>
                            <Form.Item className="auth__button">
                                <Link to="/">
                                    <ArrowLeftOutlined /> Повернутись на сторінку входу
                                </Link>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </section>
    );
};
