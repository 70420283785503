import { useState } from 'react';
import axios from '../../../../axios';
import { useAppDispatch } from '../../../../store/hooks/useRedux';
import { message, Modal, Typography } from 'antd';
import * as Product from '../../../../store/products/products.slice';
import type { TableRowSelection } from 'antd/es/table/interface';
import { IProductsPage } from '../../../../types';
import * as Icon from '@ant-design/icons';
import { IsAccess } from '../../../../components/IsAccess/IsAccess';

interface IGroupUpdateProps {
    action: string;
    productId: React.Key[];
}

const errorMessage = (msg: string) => message.error(msg);

const key = 'updatable';

export const ProductGroupUpdate = () => {
    const dispatch = useAppDispatch();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const selectedQuantity = !!selectedRowKeys.length && selectedRowKeys.length;
    const isAccess = IsAccess();

    const groupUpdate = async (props: IGroupUpdateProps) => {
        message.loading({ content: 'Завантаження...', key });
        try {
            const { data } = await axios.patch('/api/product/group/update', props);

            if (data.success) {
                switch (props.action) {
                    case 'Duplicate':
                        console.log('data.data', data.data);
                        dispatch(Product.duplicateGroup(data.data));
                        message.success({
                            content: `Дубльовано ${props.productId.length} шт!`,
                            key,
                            duration: 2,
                        });
                        break;
                    case 'Delete':
                        dispatch(Product.removeGroup(props.productId));
                        message.success({
                            content: `Видалено ${props.productId.length} шт!`,
                            key,
                            duration: 2,
                        });
                        break;

                    default:
                        break;
                }
            } else {
                message.error({ content: 'Сталась помилка!', key, duration: 2 });
            }
        } catch (e) {
            message.error({ content: 'Сталась помилка!', key, duration: 2 });
        }
    };

    const showStatusConfirm = (props: IGroupUpdateProps) => {
        Modal.confirm({
            title: 'Увага!',
            content: 'Ви впевнен, що хочете дублювати вибрані заявки?',
            icon: <Icon.ExclamationCircleOutlined />,
            okText: 'Так',
            cancelText: 'Ні',
            onOk() {
                groupUpdate(props);
            },
        });
    };

    const showDeleteConfirm = (props: IGroupUpdateProps) => {
        Modal.confirm({
            title: 'Увага!',
            content: (
                <>
                    <div>Ви впевнені, що хочете видалити вибрані заявки?</div>
                    <div>
                        <Typography.Text type="danger">Ви більше не зможете їх відновити!</Typography.Text>
                    </div>
                </>
            ),
            icon: <Icon.ExclamationCircleOutlined />,
            okText: 'Так',
            cancelText: 'Ні',
            okType: 'danger',
            onOk() {
                groupUpdate(props);
            },
        });
    };

    const rowSelection: TableRowSelection<IProductsPage> = {
        selectedRowKeys,
        onChange: (selected) => setSelectedRowKeys(selected),
        selections: [
            {
                key: 'duplicate',
                text: (
                    <>
                        <Icon.CopyOutlined style={{ marginRight: 5, color: '#F59337' }} />
                        Дублювати {selectedQuantity}
                    </>
                ),
                onSelect: () => {
                    if (isAccess?.products.duplicate) {
                        selectedRowKeys.length
                            ? showStatusConfirm({
                                  action: 'Duplicate',
                                  productId: selectedRowKeys,
                              })
                            : errorMessage('Помилка. Виберіть елемент!');
                    } else {
                        errorMessage('Дублювання заборенено!');
                    }
                },
            },
            {
                key: 'delete',
                text: (
                    <>
                        <Icon.DeleteOutlined style={{ marginRight: 5, color: '#fa541c' }} />
                        Видалити {selectedQuantity}
                    </>
                ),
                onSelect: () => {
                    if (isAccess?.products.delete) {
                        selectedRowKeys.length
                            ? showDeleteConfirm({
                                  action: 'Delete',
                                  productId: selectedRowKeys,
                              })
                            : errorMessage('Помилка. Виберіть елемент!');
                    } else {
                        errorMessage('Видалення заборонено!');
                    }
                },
            },
        ],
    };

    return rowSelection;
};
