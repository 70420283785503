import { Checkbox } from 'antd';
import { FC } from 'react';

import { useAppSelector } from '../../../../../store/hooks/useRedux';
import { IUsers } from '../../../../../types';

import './Users.filter.scss';

interface ISearchUser {
    setSearch: (obj: any) => any;
    searchRole: IUsers[];
    searchName: IUsers[];
}

export const UsersFilter: FC<ISearchUser> = ({ setSearch, searchRole, searchName }) => {
    const { roles } = useAppSelector((state) => state.usersRole);

    const onChange = (activeRoles: any) => {
        const filter = activeRoles.length
            ? activeRoles
                  .map((roleId: any) => searchName.filter((user) => roleId === user.roleId))
                  .flat()
            : searchName;

        setSearch(filter);
    };

    return (
        <div className="user_page__filter-role">
            {/* <div className="user_page__filter-role_title">Роль:</div> */}
            <Checkbox.Group onChange={onChange}>
                {roles.map((role) => (
                    <div key={role._id} className="user_page__filter-role_container">
                        <Checkbox value={role._id} />
                        <span
                            className="user_page__filter-role_mark"
                            style={{ backgroundColor: role.color, marginLeft: '10px' }}></span>
                        <div className="user_page__filter-role_name">{role.title}</div>
                    </div>
                ))}
            </Checkbox.Group>
        </div>
    );
};
