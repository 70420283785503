import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Layout, Space, Button, Row, Col, Typography } from 'antd';
import { useAppSelector } from '../../../../store/hooks/useRedux';
import { Content } from 'antd/lib/layout/layout';
import { TemplatesGroupUpdate } from '../TemplatesFrom/Templates.group.update';
import { EmptyCustome } from '../../../../components/EmptyCustome/EmptyCustome';
import { ModalAPI } from '../../../../components/ModalAPI/ModalAPI';
import { ITemplatesPage } from '../../../../types';
import type { ColumnsType } from 'antd/es/table';
import * as Icon from '@ant-design/icons';
import { IsAccess } from '../../../../components/IsAccess/IsAccess';
import { TemplatesDelete } from '../TemplatesFrom/Templates.delete';

export const TemplatesPage: FC = () => {
    const [usePageSize, setPageSize] = useState(15);
    const navigate = useNavigate();
    const isAccess = IsAccess();
    const { TemplatesData, isLoading } = useAppSelector((state) => state.templates);

    useEffect(() => {
        document.querySelectorAll('.ant-table-row').forEach((expandableRow) => {
            const templateKey = expandableRow.getAttribute('data-row-key');
            const template = TemplatesData?.find((template) => template._id === templateKey);
            if (template && !template.description) {
                const expandButton = expandableRow.querySelector('.ant-table-row-expand-icon');
                if (expandButton instanceof HTMLButtonElement) {
                    expandButton.disabled = true;
                    expandButton.classList.add('button_disabled');
                }
            }
        });
    }, [TemplatesData]);

    const Data = TemplatesData.map((template) => ({
        key: template._id,
        name: isAccess?.templates.edit ? <Link to={`/template/${template._id}`}>{template.name}</Link> : template.name,
        description: template.description,
    }));

    const columns: ColumnsType<ITemplatesPage> = [
        {
            title: 'Назва',
            dataIndex: 'name',
            ellipsis: true,
        },

        {
            title: 'Дії',
            key: 'action',
            width: '140px',
            fixed: 'right',
            className: 'offer__action',
            render: (_, template) => {
                return (
                    <Space size="small">
                        <ModalAPI url={`/api/json/template/${template.key}`} type="text" />
                        <Button
                            type="text"
                            icon={<Icon.EditOutlined />}
                            onClick={() => navigate(`/template/${template.key}`)}
                            disabled={!isAccess?.templates.edit}
                        />
                        <TemplatesDelete templateId={template.key} />
                    </Space>
                );
            },
        },
    ];

    return (
        <Layout>
            <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
                <Col>
                    <Space>
                        <Typography.Title level={4} style={{ margin: 0 }}>
                            Шаблони
                        </Typography.Title>
                    </Space>
                </Col>
                <Col>
                    <Space>
                        <Button
                            type="primary"
                            icon={<Icon.PlusOutlined />}
                            onClick={() => navigate('/template/create')}
                            loading={isLoading}
                            disabled={!isAccess?.templates.create}
                        />
                    </Space>
                </Col>
            </Row>
            <Content className="site-layout-background main_content main_section">
                <EmptyCustome style={{ height: 'calc(100vh - 330px)' }}>
                    <Table
                        scroll={{ y: 'calc(100vh - 295px)', x: 1200 }}
                        className="table_content"
                        rowSelection={TemplatesGroupUpdate()}
                        loading={isLoading}
                        columns={columns}
                        dataSource={Data}
                        pagination={{
                            defaultPageSize: 15,
                            showSizeChanger: true,
                            pageSize: usePageSize,
                            pageSizeOptions: [15, 20, 30, 40, 50, 100],
                            onShowSizeChange: (_, size) => setPageSize(size),
                        }}
                    />
                </EmptyCustome>
            </Content>
        </Layout>
    );
};
