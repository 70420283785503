import { FC } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../../../../axios';
import { Button, message, Modal, Typography } from 'antd';
import { useAppDispatch } from '../../../../../../store/hooks/useRedux';
import * as Template from '../../../../../../store/templates/templates.slice';

import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { IsAccess } from '../../../../../../components/IsAccess/IsAccess';

interface IProps {
    sectionId: React.Key;
}

const key = 'deletable';

export const SectionDelete: FC<IProps> = ({ sectionId }) => {
    const { id: TEMPLATE_PAGE_ID } = useParams();
    const dispatch = useAppDispatch();
    const isAccess = IsAccess();

    const handleDelete = async () => {
        if (!isAccess?.templates.delete) {
            return message.error({ content: 'Забороено видалення!', key });
        }
        message.loading({ content: 'Завантаження...', key });
        try {
            await axios.patch('/api/template/section/delete', {
                templatePageId: TEMPLATE_PAGE_ID,
                sectionId,
            });
            dispatch(
                Template.sectionsDelete({
                    templateId: TEMPLATE_PAGE_ID,
                    sectionId,
                }),
            );

            message.success({
                content: 'Видалено!',
                key,
                duration: 2,
            });
        } catch (e) {
            message.error({ content: 'Помилка!', key, duration: 2 });
        }
    };

    const DeleteConfirm = () => {
        Modal.confirm({
            title: 'Увага!',
            content: (
                <>
                    <Typography.Text style={{ display: 'block' }}>Ви впевнені що хочете видалити?</Typography.Text>
                    <Typography.Text type="danger">Ви більше не зможете відновити!</Typography.Text>
                </>
            ),
            icon: <ExclamationCircleOutlined />,
            okText: 'Так',
            okType: 'danger',
            cancelText: 'Ні',

            onOk() {
                handleDelete();
            },
        });
    };

    return (
        <Button size="small" onClick={DeleteConfirm} disabled={!isAccess?.templates.delete}>
            <DeleteOutlined />
        </Button>
    );
};
