import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks/useRedux';
import { Breadcrumb, Button, Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { OrderEdit } from './Order.edit';
import { NotFoundPage } from '../../NotFound/NotFoundPage';

import { LeftOutlined } from '@ant-design/icons';

export const LandingContent: FC = () => {
    const navigation = useNavigate();
    const { id: ORDER_PAGE_ID } = useParams();
    const ORDER = useAppSelector((state) => state.orders.OrdersData.find((order) => order._id === ORDER_PAGE_ID));

    if (!ORDER) return <NotFoundPage />;

    return (
        <Layout>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Button icon={<LeftOutlined />} type={'primary'} style={{ marginRight: 10 }} onClick={() => navigation('/orders')} />
                    Заявки
                </Breadcrumb.Item>
                <Breadcrumb.Item>{ORDER?._id}</Breadcrumb.Item>
            </Breadcrumb>

            <Content className="site-layout-background main_content main_section">
                <OrderEdit />
            </Content>
        </Layout>
    );
};
