import { FC, useState } from 'react';
import { Button, Col, Form, Input, message, Modal, Row, Tabs, Typography } from 'antd';
import { useAppDispatch } from '../../../store/hooks/useRedux';
import axios from '../../../axios';
import TextArea from 'antd/lib/input/TextArea';
import { IFieldCreateProps } from '../../../types/index';
import { ArrowLeftOutlined } from '@ant-design/icons';
import * as SVG from '../../../assets/images/svg/svg';

interface IValue {
    name: string;
    code: string;
}

const key = 'creatable';

export const FieldCodeCreate: FC<IFieldCreateProps> = ({
    main_id,
    section_id,
    request_url,
    fieldCreate,
    handleModal,
}) => {
    const [isModal, setIsModal] = useState(false);
    const [isLoadingForm, setIsLoadingForm] = useState(false);

    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    const handleCreateFields = async ({ name, ...other }: IValue) => {
        setIsLoadingForm(true);
        message.loading({ content: 'Завантаження...', key });
        try {
            const { data } = await axios.post(request_url, {
                main_id,
                section_id,
                information: {
                    field_type: 'Code',
                    field_name: name,
                },
                content: { UA: [], RU: [], ...other },
            });

            const fieldStoreData = {
                main_id,
                section_id,
                field: data.field,
            };

            dispatch(fieldCreate(fieldStoreData));

            form.resetFields();
            setIsModal(false);
            message.success({ content: 'Збережено!', key, duration: 2 });
        } catch (e) {
            setIsLoadingForm(false);
            message.error({ content: 'Помилка!', key, duration: 2 });
        } finally {
            setIsLoadingForm(false);
        }
    };

    const onOpen = () => {
        setIsModal(true);
        handleModal(false);
    };

    const onCancel = () => {
        form.resetFields();
        setIsModal(false);
    };

    const onBack = () => {
        setIsModal(false);
        handleModal(true);
    };

    return (
        <>
            <Col onClick={onOpen} sm={6} span={8}>
                <Row className="modal_fields_button">
                    <Col span={24}>
                        <SVG.IconCode x={40} y={40} />
                    </Col>
                    <Col span={24}>
                        <Typography.Text>CODE</Typography.Text>
                    </Col>
                </Row>
            </Col>

            <Modal
                okText="Зберегти"
                cancelText="Відмінити"
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            type="primary"
                            size="small"
                            icon={<ArrowLeftOutlined />}
                            onClick={onBack}
                        />
                        <span style={{ marginLeft: 10 }}>Code</span>
                    </div>
                }
                open={isModal}
                onOk={() => form.submit()}
                confirmLoading={isLoadingForm}
                onCancel={onCancel}>
                <hr style={{ border: '0.1px solid #00000014' }} />
                <Form
                    name="basic"
                    form={form}
                    initialValues={{ remember: true }}
                    onFinish={handleCreateFields}
                    size="middle"
                    layout="vertical"
                    autoComplete="off">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                label="Назва поля"
                                name="name"
                                rules={[{ required: true, message: '' }]}>
                                <Input placeholder="Введіть назву поля" size="middle" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <hr style={{ border: '0.1px solid #00000014' }} />

                    <div style={{ marginBottom: 5 }}>Контент</div>
                    <Tabs
                        className="tab-card-content-admin"
                        size="small"
                        tabPosition="top"
                        defaultActiveKey="UA"
                        type="card">
                        <Tabs.TabPane tab={<span>🇺🇦 UA</span>} key="UA">
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Form.Item name={['UA', 'code']}>
                                        <TextArea
                                            showCount
                                            style={{ height: 300 }}
                                            placeholder="Введіть <код>"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<span>🇷🇺 RU</span>} key="RU">
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Form.Item name={['RU', 'code']}>
                                        <TextArea
                                            showCount
                                            style={{ height: 300 }}
                                            placeholder="Введите <код>"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                    </Tabs>
                </Form>
            </Modal>
        </>
    );
};
