import { Button, Col, Row, Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useAppSelector } from '../../../../store/hooks/useRedux';
import { IVisits } from '../../../../types';
import { ReloadOutlined } from '@ant-design/icons';

const convertDateToMilliseconds = (dateArr: IVisits[]) => {
    return dateArr.reduce((result, dateStr) => {
        const clearData = dateStr.createdAt?.split('T')[0];
        // @ts-ignore
        const timestamp = new Date(clearData).getTime();
        const existingEntry = result.find(([ts]) => ts === timestamp);

        if (existingEntry) {
            existingEntry[1]++;
        } else {
            // @ts-ignore
            result.push([timestamp, 1]);
        }

        return result;
    }, []);
};

export const HomeChartVisits: FC = () => {
    const { visitsData, isLoading } = useAppSelector((state) => state.visits);
    const visitsLanding = visitsData.filter((visit) => visit.whence === 'landing');
    const visitsTelegram = visitsData.filter((visit) => visit.whence === 'telegram');
    const visitsViber = visitsData.filter((visit) => visit.whence === 'viber');

    const [selection, setSelection] = useState('seven_days');

    const lineChart = {
        series: [
            {
                name: 'Landing',
                data: [...convertDateToMilliseconds(visitsLanding)],
                offsetY: 0,
                color: '#FF9800',
            },
            {
                name: 'Telegram',
                data: [...convertDateToMilliseconds(visitsTelegram)],
                offsetY: 0,
                color: '#03A9F4',
            },
            {
                name: 'Viber',
                data: [...convertDateToMilliseconds(visitsViber)],
                offsetY: 0,
                color: '#4642f2',
            },
        ],
    };

    const options = {
        chart: {
            id: 'area-datetime_visits',
            height: 350,
            toolbar: {
                show: true,
                tools: {
                    download: true,
                },
            },
            zoom: {
                enabled: false,
            },
            animations: {
                enabled: true,
                dynamicAnimation: {
                    speed: 500,
                },
            },
            dropShadow: {
                enabled: true,
                color: '#000',
                top: 10,
                left: 5,
                blur: 10,
                opacity: 0.2,
            },
        },

        legend: {
            horizontalAlign: 'left',
            labels: {
                colors: 'grey',
            },
        },

        dataLabels: {
            enabled: true,
        },
        markers: {
            size: 0,
            style: 'hollow',
        },

        yaxis: {
            labels: {
                decimalsInFloat: false,
                formatter: function (value: number & string) {
                    return parseInt(value);
                },
                style: {
                    fontSize: '10px',
                    fontWeight: 200,
                    colors: 'grey',
                },
            },
        },
        xaxis: {
            type: 'datetime',
            tickAmount: 6,
            labels: {
                style: {
                    fontSize: '10px',
                    fontWeight: 200,
                    colors: 'grey',
                },
            },
        },

        tooltip: {
            x: {
                format: 'dd MMM yyyy',
            },
        },
    };

    const customNames: any = {
        seven_days: '7 Днів',
        one_month: '1 Місяць',
        one_year: '1 рік',
    };

    const updateData = (timeline: any) => {
        const currentDate = new Date();
        const oneMonthAgo = new Date(currentDate);
        const oneWeekAgo = new Date(currentDate);

        oneMonthAgo.setMonth(currentDate.getMonth() - 1);
        oneWeekAgo.setDate(currentDate.getDate() - 7);

        const zoomRanges = {
            one_month: [oneMonthAgo.getTime(), currentDate.getTime()],
            seven_days: [oneWeekAgo.getTime(), currentDate.getTime()],
            one_year: [new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate()).getTime(), currentDate.getTime()],
        };

        // @ts-ignore
        if (zoomRanges[timeline]) {
            // @ts-ignore
            ApexCharts.exec('area-datetime_visits', 'zoomX', ...zoomRanges[timeline]);
            setSelection(timeline);
        }
    };

    useEffect(() => {
        if (!isLoading) {
            updateData('seven_days');
        }
    }, [isLoading]);

    return (
        <>
            <Space>
                {['seven_days', 'one_month', 'one_year'].map((timeline) => (
                    <Button
                        key={timeline}
                        id={timeline}
                        size="small"
                        onClick={() => updateData(timeline)}
                        className={selection === timeline ? 'ant-btn-primary' : ''}>
                        {customNames[timeline] || timeline}
                    </Button>
                ))}
            </Space>

            <ReactApexChart
                // @ts-ignore
                options={options}
                series={lineChart.series}
                type="area"
                height={350}
            />
        </>
    );
};
