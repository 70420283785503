// STYLES
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from 'antd';
import { Logo } from '../../../components/Logo/Logo';

import { IconPage404 } from '../../../assets/images/svg/svg';
import { ArrowLeftOutlined } from '@ant-design/icons';

// STYLE
import './NotFoundPage.scss';

export const NotFoundPage: FC = () => {
    const navigate = useNavigate();

    return (
        <section>
            <Logo boxClass={['logo__auth']} />
            <div className="auth">
                <div className="auth__wrapper page404">
                    <div className="auth__logo">
                        <IconPage404 />
                    </div>
                    <div className="auth__form page404__form">
                        <Typography.Title level={4} className="auth__title">
                            404 Error
                        </Typography.Title>
                        <Typography.Text className="auth__description">
                            Вибачте, але ми не можемо знайти сторінку яку ви шукаєте.
                        </Typography.Text>
                        <Button
                            type="primary"
                            onClick={() => navigate('/')}
                            icon={<ArrowLeftOutlined />}>
                            Повернутись на сторінку входу
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};
