import { FC } from 'react';
import { Typography, Card, Col, Row, Button } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/useRedux';
import { HomeChartVisits } from '../HomeFrom/HomeChartVisits';
import { HomeChartOrders } from '../HomeFrom/HomeChartOrders';
import { getOrders } from '../../../../store/orders/orders.slice';
import { getVisits } from '../../../../store/visits/visits.slice';
import { ReloadOutlined } from '@ant-design/icons';
import './HomePage.scss';

export const HomePage: FC = () => {
    const dispatch = useAppDispatch();
    const ORDERS = useAppSelector((state) => state.orders);
    const VISITS = useAppSelector((state) => state.visits);

    const toDayOrderDate = (title: string) => {
        const currentDate = new Date();
        const currentDateStr = currentDate.toISOString().split('T')[0];

        const result = ORDERS.OrdersData.filter((order) => {
            const orderDate = new Date(order.createdAt || '');
            const orderDateStr = orderDate.toISOString().split('T')[0];
            return title
                ? order.whence === title && currentDateStr === orderDateStr
                : currentDateStr === orderDateStr;
        });

        return result.length;
    };

    const updateOrdersAndVisits = () => {
        dispatch(getOrders());
        dispatch(getVisits());
    };

    const count = [
        {
            today: 'Всього заявок',
            title: toDayOrderDate(''),
            persent: '',
            bnb: 'bnb2',
            class: 'card_green card_icon card_icon_total ',
        },
        {
            today: 'Заявок з сайту',
            title: toDayOrderDate('landing'),
            persent: '',
            bnb: 'bnb2',
            class: 'card_orange card_icon card_icon_landing',
        },
        {
            today: 'Заявок з Telegram',
            title: toDayOrderDate('telegram'),
            persent: '',
            bnb: 'bnb2',
            class: 'card_blue card_icon card_icon_telegram',
        },
        {
            today: 'Заявок з Viber',
            title: toDayOrderDate('viber'),
            persent: '',
            bnb: 'redtext',
            class: 'card_purple card_icon card_icon_viber',
        },
    ];

    return (
        <>
            <Row className="rowgap-vbox" gutter={[20, 20]}>
                {count.map((c, index) => (
                    <Col key={index} xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
                        <Card bordered={false} className={`criclebox ${c.class}`}>
                            <Row align="middle" gutter={[10, 5]}>
                                <Col xs={18}>
                                    <Typography.Text
                                        style={{
                                            fontSize: 15,
                                            color: '#fff',
                                        }}>
                                        {c.today}
                                    </Typography.Text>
                                </Col>
                                <Col xs={18}>
                                    <Typography.Text
                                        style={{
                                            fontSize: 20,
                                            color: '#fff',
                                        }}>
                                        {c.title}
                                    </Typography.Text>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}
            </Row>

            <Row gutter={[24, 0]} style={{ marginTop: 24 }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                    <Card bordered={false} className="criclebox h-full">
                        <Row align="middle" justify="space-between">
                            <Col>
                                <Typography.Title level={5}>Статистика заявок</Typography.Title>
                            </Col>
                            <Col>
                                <Button
                                    size="small"
                                    loading={ORDERS.isLoading}
                                    icon={<ReloadOutlined />}
                                    onClick={() => updateOrdersAndVisits()}
                                />
                            </Col>
                        </Row>

                        <HomeChartOrders />
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                    <Card bordered={false} className="criclebox h-full">
                        <Row align="middle" justify="space-between">
                            <Col>
                                <Typography.Title level={5}>Статистика візитів</Typography.Title>
                            </Col>
                            <Col>
                                <Button
                                    size="small"
                                    loading={VISITS.isLoading}
                                    icon={<ReloadOutlined />}
                                    onClick={() => updateOrdersAndVisits()}
                                />
                            </Col>
                        </Row>

                        <HomeChartVisits />
                    </Card>
                </Col>
            </Row>
        </>
    );
};
