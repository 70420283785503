import { CSSProperties, FC, useState } from 'react';
import axios from '../../../../../../axios';
import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import { useAppDispatch } from '../../../../../../store/hooks/useRedux';
import * as Template from '../../../../../../store/templates/templates.slice';

import { PlusOutlined } from '@ant-design/icons';

interface ICreatedSectionProps {
    title: string;
}

interface IProps {
    buttonStyle?: CSSProperties;
    templateId: String | undefined;
}

const key = 'creatable';

export const SectionCreate: FC<IProps> = ({ buttonStyle, templateId }) => {
    const [isModal, setIsModal] = useState(false);
    const [isLoadingForm, setIsLoadingForm] = useState(false);

    const [form] = Form.useForm();
    const dispatch = useAppDispatch();

    const handleCreateSection = async (props: ICreatedSectionProps) => {
        setIsLoadingForm(true);
        message.loading({ content: 'Завантаження...', key });
        try {
            const { data } = await axios.post('/api/template/section/action', {
                templateId,
                ...props,
                status: true,
                fields: [],
            });

            if (data.success) {
                setIsLoadingForm(false);
                dispatch(
                    Template.sectionCreate({
                        templateId,
                        section: data.section,
                    }),
                );

                setIsModal(false);
                form.resetFields();
                message.success({ content: 'Створено!', key, duration: 2 });
                return;
            } else {
                setIsLoadingForm(false);
                message.error({ content: 'Помилка!', key, duration: 2 });
            }
        } catch (e) {
            setIsLoadingForm(false);
            message.error({ content: 'Помилка!', key, duration: 2 });
        } finally {
            setIsLoadingForm(false);
        }
    };

    return (
        <>
            <Button
                type="primary"
                onClick={() => setIsModal(true)}
                icon={<PlusOutlined />}
                style={buttonStyle}>
                Додати блок
            </Button>

            <Modal
                okText="Зберегти"
                title="Новий блок"
                open={isModal}
                onOk={() => form.submit()}
                confirmLoading={isLoadingForm}
                onCancel={() => setIsModal(false)}
                cancelText={'Відмінити'}>
                <Form
                    name="basic"
                    form={form}
                    initialValues={{ remember: true }}
                    onFinish={handleCreateSection}
                    size="middle"
                    autoComplete="off">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}>
                                <Input placeholder="Введіть назву блока" autoFocus size="middle" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};
